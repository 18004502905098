import React from "react";
import Skeleton from "react-loading-skeleton";
import "./StoriesSkeleton.scss";
import useMediaQueryHook from "@utils/hooks/useMediaQueryHook";

const MainFeedStoriesSkeleton = () => {
    const { isMobTab, isMobile, isMobileSmall, isTablet, isTabScr } =
        useMediaQueryHook();
    // const widthHeight = {
    //     container_Width: isMobileSmall? 160 :isMobile ? 190: isTablet ? 120 : 220,
    //     container_Height: isMobileSmall ? 220 :isTablet? 200: 250,
    //     wrp_footer: isMobileSmall ? 280 : 150,
    //     footer_heading: isMobileSmall ? 200 : 300,
    // };
    // const { container_Width, container_Height, wrp_footer, footer_heading } =
    //     widthHeight;
    return (
        <div className="container">
            <div className="row">
                <div className="d-flex justify-content-start" style={{width:"100%" ,gap:"10px",overflow:"hidden"}}>
                    <div className="story-skeleton ">
                        <Skeleton
                            borderRadius={20}
                            height={275} width={147} 
                            />
                        <div className="header-skeleton">

                        </div>

                    </div>
                    <div className="story-skeleton ">
                        <Skeleton
                            borderRadius={20}
                            height={275} width={147} 
                            />
                        <div className="header-skeleton">

                        </div>

                    </div>
                    <div className="story-skeleton ">
                        <Skeleton
                            borderRadius={20}
                            height={275} width={147} 
                            />
                        <div className="header-skeleton">

                        </div>

                    </div>
                    <div className="story-skeleton ">
                        <Skeleton
                            borderRadius={20}
                            height={275} width={147} 
                            />
                        <div className="header-skeleton">

                        </div>

                    </div>
                    <div className="story-skeleton ">
                        <Skeleton
                            borderRadius={20}
                            height={275} width={147} 
                            />
                        <div className="header-skeleton">

                        </div>

                    </div>
                </div>  
            </div>
        </div >
    );
};

export default MainFeedStoriesSkeleton;
