import React from "react";
import "@containers/searchcomponent/SearchPage.scss";
import { useExplorePostQuery } from "@redux/services/exploreService/exploreService";
import { Link } from "react-router-dom";
import Comments from "@components/Comments/Comments";
import { isValidUrl } from "@utils/validUrl";
import { DefaultImg } from "@utils/defaultImages";

const PostPage = () => {
  /**
   * API CALLS
   */
  const { data: { data: explorePost = [] } = {} } = useExplorePostQuery();
  return (
    <>
      <div className="view-all-heading">
        <h2>Posts </h2>
        {explorePost?.docs?.length > 6 && (
          <Link to={`/explore/categories/Posts`} state={{ title: "Posts" }}>
            See More
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.25 11.25L10 7.5L6.25 3.75"
                stroke="#387AFF"
                stroke-linecap="round"
              />
            </svg>
          </Link>
        )}
      </div>
      <div className="Trendingparts pb-0">
        <div className="row">
          {explorePost?.docs?.slice(0, 6)?.map((item, index) => (
            <div
              className="col-lg-4 col-md-6"
              key={index}
              style={{ paddingLeft: 0 }}
            >
              <div className="trending-part-block">
                <Link to={`/post/${item._id}`}>
                  <div className="vehicle-avtar">
                    <div className="img-holders">
                      <img
                        src={item?.user?.profileImage}
                        alt="profile-image"
                        style={{ marginLeft: "unset" }}
                      />
                      {item?.build && (
                        <img
                          src={item?.build?.bannerImage}
                          alt={`${item?.build?.make}-${item?.build?.model}-${item?.build?.year?.value}`}
                        />
                      )}
                    </div>
                    <div className="name-info">
                      <div className="name">
                        {item?.user?.firstName}
                        {item?.user?.lastName}
                      </div>
                      <p>{item?.user?.username}</p>
                    </div>
                  </div>
                  <img
                    src={
                      item?.media?.[0]?.type === "2"
                        ? item.media[0].thumbnail || DefaultImg
                        : isValidUrl(item?.media?.[0]?.path)
                        ? item.media[0].path
                        : DefaultImg
                    }
                    alt="Media Content"
                    className="main-img"
                  />
                </Link>
                {/* COMMENTS SHOW */}
                <Comments
                  post={item}
                  commentsCount={item?.commentsCount}
                  postId={item?._id}
                  // isTablet={isTablet}
                  isComponent={false}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PostPage;
