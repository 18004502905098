import React, { useState } from "react";
import ReadMore from "@components/ReadMore";
import ButtonLoader from "@components/Loaders/ButtonLoader";
import {
  BannerImg,
  UserImg,
  YellowLock,
  PrivateIcon,
  LocationIcon,
  DefaultImg,
} from "@utils/defaultImages";
import { isValidUrl } from "@utils/validUrl";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import ModeratorsMemberModal from "@components/Modal/ModeratorsMemberModal";
import {
  useDisJoinCrewMutation,
  useJoinCrewMutation,
} from "@redux/services/crewService/allCrewService";
import useMediaQueryHook from "@utils/hooks/useMediaQueryHook";
import ProfileHeader from "@containers/UserProfile/subComponents/ProfileHeader";

const CrewProfile = ({ crewData, user, isDisableJoin }) => {
  const { isMobile, isMobileSmall } = useMediaQueryHook();

  /**
   * USESTATES
   */
  const [openModeMemModal, setOpenModeMemModal] = useState(false);
  //
  /**
   * API CALLS
   */
  const [joinCrew, { isLoading }] = useJoinCrewMutation();
  const [disJoinCrew, { isLoading: loading }] = useDisJoinCrewMutation();
  //
  /**
   * FUNCTIONS
   */
  const joiningCrew = async (crewId) => {
    try {
      const value = {
        requestTo: user?._id,
      };
      const response = await joinCrew({ crewId, value }).unwrap();
    } catch (error) {}
  };
  const disJoiningCrew = async (crewId) => {
    try {
      const response = await disJoinCrew(crewId).unwrap();
    } catch (error) {}
  };
  //
  /**
   * HOC FOR BUTTON
   */
  const buttonForJoin = (
    <>
      {user?._id !== crewData?.user?._id && (
        <>
          {" "}
          <button
            className="default-btn"
            style={{
              flex: "0 0 auto",
              padding: "8px 40px",
              backgroundColor:
                crewData?.privacy === 1 && crewData?.userJoinStatus === 2
                  ? "transparent"
                  : "",
              borderColor:
                crewData?.privacy === 1 && crewData?.userJoinStatus === 2
                  ? "#ffffff"
                  : "",
            }}
            onClick={() => {
              if (crewData?.userJoinStatus === 1) {
                joiningCrew(crewData?._id);
              }
              if (
                crewData?.isJoin ||
                (crewData?.userJoinStatus === 2 && crewData?.isJoin === false)
              ) {
                disJoiningCrew(crewData?._id);
              }
            }}
            disabled={isDisableJoin}
          >
            {isLoading || loading ? (
              <ButtonLoader />
            ) : crewData?.isJoin ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                {/* <Chat /> */}
                Leave
              </div>
            ) : crewData?.privacy === 1 && crewData?.userJoinStatus === 2 ? (
              "Requested to join"
            ) : crewData?.userJoinStatus === 1 && crewData.privacy === 1 ? (
              "Ask to join"
            ) : (
              "Join"
            )}
          </button>
        </>
      )}
    </>
  );
  //
  /**
   * PRIVATE GROUP
   */
  const checkPrivacy = !!(
    crewData?.userId !== user?._id &&
    crewData?.privacy &&
    !crewData?.isJoin
  );
  //
  return (
    <div className="user-profile-crew">
      {(isMobile || isMobileSmall) && (
        <ProfileHeader
          firstName={crewData?.user?.firstName}
          lastName={crewData?.user?.lastName}
        />
      )}

      <img
        src={
          isValidUrl(crewData?.bannerImage) ? crewData?.bannerImage : BannerImg
        }
        className="banner-img"
        alt={crewData?.name}
      />
      <div className="container">
        <div className="user-data">
          <div className="avtar">
            <img
              src={
                isValidUrl(crewData?.profileImage)
                  ? crewData?.profileImage
                  : DefaultImg
              }
              className="avtar-img-crew"
            />
          </div>

          <div className="userInfo">
            {crewData?.name && (
              <div className="name d-flex justify-content-between">
                <div>
                  {crewData?.name} {crewData?.privacy !== 0 && <YellowLock />}
                </div>
                <div className="btn-join">{buttonForJoin}</div>
              </div>
            )}

            {crewData?.location?.name && (
              <div
                className="subtitle-crew"
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <div
                  className="userName"
                  style={{ display: "flex", alignItems: "center", gap: "2px" }}
                >
                  {" "}
                  <LocationIcon />
                  {sliceStringAtWord(
                    crewData?.location?.name,
                    isMobile || isMobileSmall ? 10 : 50
                  )}{" "}
                </div>
                {crewData?.privacy !== 0 && (
                  <>
                    <div
                      className="bullet-point"
                      style={{
                        width: "5px",
                        height: "5px",
                        backgroundColor: "#8E8E93",
                        borderRadius: "5px",
                      }}
                    ></div>
                    <div
                      className="userName"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <PrivateIcon />
                      Private
                    </div>
                  </>
                )}
              </div>
            )}
            <div className="followers">
              <div
                className="clm"
                onClick={() => setOpenModeMemModal(!openModeMemModal)}
              >
                <div className="number">
                  {crewData?.memberCount > 0 ? crewData?.memberCount : "0"}
                </div>
                <p>Members</p>
              </div>
              {
                <div className="clm">
                  <div className="number">
                    {crewData?.postCount > 0 ? crewData?.postCount : "0"}
                  </div>
                  <p>Posts</p>
                </div>
              }
              <div className="clm">
                <div className="number">{crewData?.event?.length || "0"}</div>
                <p className="chatting-crew" style={{ position: "relative" }}>
                  Events
                </p>
              </div>
            </div>
            {crewData?.description && (
              <ReadMore sliceText={200}>{crewData?.description}</ReadMore>
            )}
          </div>
          {/* {buttonForJoin} */}
          {/* <div className="threedots-crews" style={{ padding: "10px 0 0 10px" }}>
            <ThreeDots />
          </div> */}
        </div>
      </div>
      {/* {(crew?.userJoinStatus !== 1 || crew?.userJoinStatus !== 2) && ( */}
      {!checkPrivacy && (
        <ModeratorsMemberModal
          open={openModeMemModal}
          closeModal={() => {
            setOpenModeMemModal(false);
          }}
        />
      )}

      {/* )} */}
    </div>
  );
};

export default CrewProfile;
