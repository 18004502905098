import React from "react";
import { useNavigate } from "react-router-dom";
import Bolt from "@assets/Bolt.svg";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { DefaultImg } from "@utils/defaultImages";
import UserImg from "@assets/defaultUser.png";
import { isValidUrl } from "@utils/validUrl";
import { useMediaQuery } from "react-responsive";
const Spotlight = ({ spotlight, isUserProfile }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 599 });
  const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 991 });
  const evenArrays = () => {
    let arr = [];

    if (spotlight?.length % 2 !== 0) {
      arr = spotlight?.slice(1);
    }
    if (spotlight?.length % 2 === 0) {
      arr = spotlight;
    }
    arr = spotlight;
    return arr;
  };
  const handleNavigate = (spotlightData) => {
    navigate("/spotlight-list", { state: { spotlight: spotlightData } });
  };

  return (
    <>
      <div className="spotlight" style={{ padding: isUserProfile && "0" }}>
        <div className="spotlight-header" style={{ padding: isUserProfile && "0px 16px", marginTop: isUserProfile && "20px" }}>
          {!isUserProfile && <img src={Bolt} alt="image" />}
          <h1 style={{ fontSize: isUserProfile&& isMobile?"16px": "30px", fontWeight: isUserProfile && "300", lineHeight: isUserProfile && "35px" }}>Spotlights</h1>
        </div>
        <div className="spotlight-items">
          <div className="container-fluid p-0">

            <div className="row m-0">
              {evenArrays()?.map((spotlight) => {
                return (

                  <div
                    className={`spotlight-item  ${isUserProfile ? "col-lg-2 col-md-3 col-sm-3 col-3" : "col-lg-3 col-md-6 col-sm-6 col-6"}`}
                    style={{ marginTop: (isMobile || isTablet) ? "10px" : "0px" }}
                    onClick={() => handleNavigate(spotlight)}
                  >


                    <img
                      style={{ width: isUserProfile && !isMobile ? "160px" :isUserProfile && isMobile &&"100px" ,
                        height: isUserProfile && isMobile &&"150px"
                      }}
                      src={
                        isValidUrl(spotlight?.media?.thumbnail)
                          ? spotlight?.media?.thumbnail 
                          : DefaultImg
                      }
                      onError={(e) => (e.target.src = DefaultImg)}
                      className="thumbnail"
                    />
                    {!isUserProfile && <div className="user">
                      <img
                        src={spotlight.user.profileImage || UserImg}
                        alt="image"
                      />
                      <p>{spotlight.user.username || "username"}</p>
                    </div>}
                  </div>

                );
              })}

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Spotlight;
