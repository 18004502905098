import React, { useCallback, useEffect, useState } from "react";
import "./TabSwitch.scss";
import { Link, useParams } from "react-router-dom";
import { useLazyGetUserModeratorCrewsQuery } from "@redux/services/userProfile/UserProfileService";
import { isValidUrl } from "@utils/validUrl";
import FadingLoader from "@components/Skeleton/CrewSkeleton";
import InfiniteScroll from "react-infinite-scroller";
import { toast } from "react-toastify";
import { DefaultUserPng, YellowLockIcon } from "@utils/defaultImages";
import NotFound from "@components/NotFound";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";
import { useSelector } from "react-redux";

const ModeratorList = () => {
  const [page, setPage] = useState(1);
  const userData = useSelector((state) => state?.auth?.loginUserData);
  const { Id } = useParams();
 
  console.log('userData', Id)

  const [
    listAllModerator,
    { data: { data: crewmoderator } = {}, isLoading, isFetching },
  ] = useLazyGetUserModeratorCrewsQuery();

  const loadMore = useCallback(() => {
    if (!isFetching && crewmoderator?.nextPage) {
      setPage(crewmoderator?.nextPage);
    }
  }, [isFetching, crewmoderator?.nextPage]);

  const showModeratorList = async () => {
    const body = {
      page: page,
      userId: Id || userData?._id,
    };
    try {
      await listAllModerator(body).unwrap();
      setPage((prev) => prev + 1);
    } catch (error) {
      toast.error(error?.crewmoderator?.message);
    }
  };

  useEffect(() => {
    showModeratorList();
  }, []);

  return (
    <div className="crew-list-content">
      {isLoading ? (
        <div className="list-block position-relative">
          <div className="container-fluid p-0">
            <FadingLoader />
          </div>
        </div>
      ) : (
        <InfiniteScroll
          dataLength={crewmoderator?.[1]?.length}
          next={loadMore}
          hasMore={!!crewmoderator?.[1]?.hasNextPage}
          loader={<SpinnerLoader />}
          endMessage={<p style={{ textAlign: "center" }}>No more results</p>}
        >
          {crewmoderator?.[1]?.value?.length > 0 ? (
            crewmoderator?.[1]?.value?.map((item) => (
              <div className="list-block" key={item?.crew?._id}>
                <Link
                  to={`/profile/${item?.crew?._id}`}
                  className="profile-link"
                >
                  <img
                    src={
                      isValidUrl(item?.crew?.bannerImage)
                        ? item?.crew?.bannerImage
                        : DefaultUserPng
                    }
                    alt={item?.crew?.name}
                    className="main-img"
                  />
                </Link>
                <Link
                  to={
                    item?.crew?._id === item?.crew?._id
                      ? `/profile`
                      : `/profile/${item?.crew?._id}`
                  }
                  className="profile-link"
                >
                  <div className="list-info">
                    <h4 style={{ whiteSpace: "nowrap" }}>
                      {item?.crew?.name}
                      {item?.crew?.privacy === 1 && <YellowLockIcon />}
                    </h4>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <NotFound message="Moderator" />
          )}
        </InfiniteScroll>
      )}
    </div>
  );
};
export default ModeratorList;
