import StarRating from "@components/StarRating";
import { useLazySearchExploreBuildQuery } from "@redux/services/exploreService/exploreService";
import { DefaultImg } from "@utils/defaultImages";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { isValidUrl } from "@utils/validUrl";
import { AsyncImage } from "loadable-image";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Blur } from "transitions-kit";
import InfiniteScroll from "react-infinite-scroll-component";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";
import { Link } from "react-router-dom";
import { useDebounce } from "use-debounce";
import NotFound from "@components/NotFound";

const ExploreBuild = ({ categorySearch }) => {
  /**
   * USESTATES
   */
  const [allBuild, setAllBuild] = useState([]);
  const [page, setPage] = useState(1);
  /**
   * DEBOUNCE
   */
  const [debouncedSearch] = useDebounce(categorySearch, 1000);
  /**
   * API CALL
   */
  const [
    listAllBuild,
    { data: { data: allExploreBuild } = {}, isFetching, isLoading },
  ] = useLazySearchExploreBuildQuery();
  /**
   * FUNCTIONS
   */
  const fetchBuild = async () => {
    try {
      const value = {
        page: page,
        search: debouncedSearch,
      };
      const res = await listAllBuild(value).unwrap();
      setAllBuild((prevItem) =>
        page === 1 ? res?.data?.docs : [...prevItem, ...res?.data?.docs]
      );
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };
  const loadMore = useCallback(() => {
    if (!isFetching && allExploreBuild?.nextPage) {
      setPage(allExploreBuild?.nextPage);
    }
  }, [isFetching, allExploreBuild?.nextPage]);
  /**
   * USEEFFECT
   */
  useEffect(() => {
    fetchBuild();
  }, [page, debouncedSearch]);

  useEffect(() => {
    setPage(1);
    setAllBuild([]);
  }, [debouncedSearch]);
  return (
    <InfiniteScroll
      style={{ overflow: "hidden" }}
      dataLength={allBuild?.length}
      next={loadMore}
      hasMore={!!allExploreBuild?.nextPage}
      loader={page !== 1 && <SpinnerLoader />}
    >
      {allBuild?.length === 0 && isFetching ? (
        <SpinnerLoader />
      ) : allExploreBuild?.totalDocs === 0 ? (
        <NotFound message="build" />
      ) : (
        <div className="garage-row garage-row-two-clm" style={{ gap: "20px" }}>
          {allBuild?.map((item, index) => (
            <Link
              className="garage-block"
              key={index}
              to={`/${item?.username}/garage/${item?.year?.value}/${item?.make}/${item?.model}/${item?._id}`}
            >
              <AsyncImage
                key={index}
                src={
                  isValidUrl(item?.bannerImage) ? item?.bannerImage : DefaultImg
                }
                loading="lazy"
                Transition={Blur}
                style={{ height: "220px" }}
                loader={
                  <img
                    src={item?.bannerImage}
                    style={{ filter: "blur(25px)" }}
                  />
                }
              />
              <div className="info">
                <div className="stars">
                  <StarRating rating={4} isShow={false} isRedirect={false} />
                </div>

                <div className="model">
                  {" "}
                  {item?.year?.value} {item?.make}
                </div>
                <div className="name">
                  {sliceStringAtWord(item?.name, 5)}{" "}
                  <span
                    className="current"
                    style={{
                      backgroundColor: item?.type === 1 ? "#387aff" : "#e56424",
                      width: "fit-content",
                      padding: "4px 6px",
                      fontSize: "10px",
                    }}
                  >
                    {item?.type === 1 ? "Current" : "Legacy"}
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
    </InfiniteScroll>
  );
};

export default ExploreBuild;
