import React from "react";

import ContentLoader from "react-content-loader";

const PostsHeaderLoader = () => (
  <ContentLoader
    viewBox="0 0 400 80"
    backgroundColor="#ababab"
    foregroundColor="#fafafa"
    speed={2}
  >
    {/* Category Name Placeholder */}
    <rect x="0" y="10" rx="3" ry="3" width="75" height="10" />

    {/* Post Placeholder */}
    <rect x="0" y="30" rx="3" ry="3" width="370" height="40" />

    {/* Add more rectangles based on your design */}
  </ContentLoader>
);
export default PostsHeaderLoader;
