import React, { useEffect, useState } from "react";
import waitingListFrame from "@assets/waitingListFrame.svg";
import logo from "@assets/logo-header.svg";

import "./Signup.scss";

import { useNavigate, useParams } from "react-router-dom";
import { Close } from "@utils/defaultImages";

const WaitingScreen = () => {
    const queueNo = useParams()
    const navigate = useNavigate();
    function handleNavigate(){
        navigate(`/login`)
    }
    return (
        <div className="waitingListWrapper">
            {/* <div className="signup-container">
                <div className="signup-wrapper"> */}
            <div className="waiting-list">
                <div className="close-waiting-List"> 
                    <Close onClick={handleNavigate}/>
                </div>
                <img src={logo} alt="" className="header-image" />
                <div className="waiting-list-content">
                    <img src={waitingListFrame} alt="helmetImage" className="helmetImage" />
                    <div className="waitingListDescription">
                        <h4 className="waitingListHeading">You’re account has been created successfully!</h4>
                        <p className="waitingListPara">Thank you for signing up to be part of Carvonix community. Due to overwhelming demand, we need to place you on our waitlist.</p>
                    </div>
                    <div className="Queue">
                        <p className="queuePara">Queue position</p>
                        <h4 className="queue-no">#{queueNo.id}</h4>
                    </div>
                    <p className="footer-para">We’ll let you know as soon as your turn is up</p>
                </div>


            </div>

            {/* </div>
            </div> */}
        </div>
    );
};
export default WaitingScreen;
