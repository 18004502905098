import React, { Component } from "react";
import "./FeedBack.scss";
import logo from "@assets/logo.svg";

export default function FeedBack() {
  return (
    <div>
      <div className="feedback-container">
        <a href="/home" className="logo">
          <img src={logo} alt="Carvonix Logo" />
        </a>
        <h2>We appreciate your feedback!</h2>
        <h3>
          Thank you for participating in the CARVONIX Beta app testing. Your
          feedback is crucial towards helping to make this app become the
          automotive enthusiasts dream. Please submit and ideas, comments,
          issues that you have found while using the app, This feedback goes
          directly to our Developers so detail and screenshots adds tremendous
          value to our BETA process.
        </h3>
        <div className="form-wrapper">
          <div className="form-group">
            <label>What did you think of the CAR BUILDER?</label>
            <input
              type="text"
              className="form-control"
              placeholder="One line answer"
            />
          </div>
          <div className="form-group">
            <label>
              Did the CREW area make sense for you to find new users, events and
              groups?{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="One line answer"
            />
          </div>
          <div className="form-group">
            <label>Would you use the app on a weekly/daily basis?</label>
            <input
              type="text"
              className="form-control"
              placeholder="One line answer"
            />
          </div>
          <div className="form-group">
            <label>Would you share this with your friends?</label>
            <input
              type="text"
              className="form-control"
              placeholder="One line answer"
            />
          </div>
          <div className="form-group">
            <label>What ideas can make this app even better?</label>
            <input
              type="text"
              className="form-control"
              placeholder="One line answer"
            />
          </div>
          <div className="form-group">
            <label>
              Question on two lines lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Nunc vulputate libero et velit interdum aliquet?
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="One line answer"
            />
          </div>
          <div className="form-group">
            <label>Question</label>
            <textarea
              className="form-control"
              placeholder="Multiple line answer box"
            ></textarea>
          </div>
          <div className="form-group">
            <label>Question</label>
            <div className="multiple-checkbox">
              <label>
                <input type="checkbox" name="multiple" />
                <span>Answer option</span>
              </label>
              <label>
                <input type="checkbox" name="multiple" />
                <span>Another option</span>
              </label>
              <label>
                <input type="checkbox" name="multiple" />
                <span>Answer option</span>
              </label>
              <label>
                <input type="checkbox" name="multiple" />
                <span>Option</span>
              </label>
              <label>
                <input type="checkbox" name="multiple" />
                <span>Option</span>
              </label>
            </div>
          </div>
          <div className="form-group">
            <label>Question with radio button answer</label>
            <div className="multiple-radio">
              <label>
                <input type="radio" name="multiple_radio" />
                <span>Option</span>
              </label>
              <label>
                <input type="radio" name="multiple_radio" />
                <span>Option</span>
              </label>
              <label>
                <input type="radio" name="multiple_radio" />
                <span>Option</span>
              </label>
              <label>
                <input type="radio" name="multiple_radio" />
                <span>Option</span>
              </label>
              <label>
                <input type="radio" name="multiple_radio" />
                <span>Option</span>
              </label>
              <label>
                <input type="radio" name="multiple_radio" />
                <span>Option</span>
              </label>
            </div>
          </div>
          <div className="form-group">
            <label>Question with radio button answer</label>
            <select className="form-control">
              <option>Select</option>
              <option>Select</option>
              <option>Select</option>
            </select>
          </div>
          <div className="form-group">
            <label>Additional comments</label>
            <textarea
              className="form-control"
              placeholder="Multiple line answer box"
            ></textarea>
          </div>
          <div className="form-group">
            <label>Current BETA issues or concerns you found</label>
            <label className="upload-label">
              <input type="file" />
              <div className="click">Click or drag & drop files</div>
              <div className="up-button">
                Upload files{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                >
                  <path
                    d="M1.56836 7.28535L1.04805 6.76504C0.827734 6.54473 0.827734 6.18848 1.04805 5.97051L5.60195 1.41426C5.82227 1.19395 6.17852 1.19395 6.39649 1.41426L10.9504 5.96816C11.1707 6.18848 11.1707 6.54473 10.9504 6.7627L10.4301 7.28301C10.2074 7.50566 9.84414 7.50098 9.62617 7.27363L6.93789 4.45176V11.1877C6.93789 11.4994 6.68711 11.7502 6.37539 11.7502H5.62539C5.31367 11.7502 5.06289 11.4994 5.06289 11.1877V4.45176L2.37227 7.27598C2.1543 7.50566 1.79102 7.51035 1.56836 7.28535Z"
                    fill="white"
                  />
                </svg>
              </div>
            </label>
          </div>
          <button className="submit-btn">Send Feedback</button>
        </div>
      </div>
    </div>
  );
}
