import React from "react";
import WrapperModal from "./WrapperModal";
import LoginFormForModal from "@containers/Auth/LogIn/LoginFormForModal";

const SignInModal = ({
  open,
  closeModal,
}) => {
  return (
    <div id="modal">
      {open && (
        <WrapperModal
          open={open}
          closeModal={closeModal}
          style={{
            top: "50%",
            transform: "translateY(-50%)",
            left: 0,
            right: 0,
            margin: "0 auto",
            backgroundColor: "#121313",
          }}
        >
          <LoginFormForModal />
        </WrapperModal>
      )}
    </div>
  );
};

export default SignInModal;
