import React from "react";
import ContentLoader from "react-content-loader";

const EventsFeed = (props) => (
  <ContentLoader
    viewBox="0 0 700 500"
    height={500}
    width={700}
    {...props}
    speed={2}
    backgroundColor="#ababab"
    foregroundColor="#fafafa"
  >
    <rect x="16" y="107" rx="0" ry="0" width="700" height="600" />
    <circle cx="35" cy="48" r="20" />
    <rect x="69" y="29" rx="2" ry="2" width="275" height="15" />
    <rect x="69" y="53" rx="2" ry="2" width="140" height="15" />
  </ContentLoader>
);
export default EventsFeed;
