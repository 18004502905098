import moment from "moment";

const dateTimeUs = (incomingDate) => {
  return moment(incomingDate).format("MMM DD, YYYY hh:mm:ss A");
};

const dateSimplewithSlashes = (incomingDate) => {
  return moment(incomingDate).format("YYYY/MM/DD");
};

const dateSimplewithDashes = (incomingDate) => {
  return moment(incomingDate).format("YYYY-MM-DD");
};
const ReversedateSimplewithDashes = (incomingDate) => {
  return moment(incomingDate).format("DD-MM-YYYY");
};
const dateUSWithAlphaMonth = (incomingDate) => {
  return moment(incomingDate).format("DD MMM YYYY");
};
const getOnlyMonth = (incomingDate) => {
  return moment(incomingDate).format("MMMM");
};
const getOnlyDate = (incomingDate) => {
  return moment(incomingDate).format("DD");
};
const getOnlyTime = (incomingTime) => {
  return moment(incomingTime).format("hh:mm A");
};
const dateTimeFromNow = (incomingDate) => {
  // moment.updateLocale('en', {
  //   relativeTime: {
  //     future: 'in %s',
  //     past: '%s ago',
  //     s: 'a few seconds',
  //     ss: '%d seconds',
  //     m: 'm',
  //     mm: '%dm',
  //     h: 'an hour',
  //     hh: '%dh',
  //     d: 'a day',
  //     dd: '%d days',
  //     M: 'a month',
  //     MM: '%d months',
  //     y: 'a year',
  //     yy: '%d years',
  //   },
  // });
  return moment(incomingDate).fromNow();
};
const dateSimplewithComma = (incomingDate) => {
  return moment(incomingDate).format("MMM DD, YYYY");
};
const currentTime = (incomingTime) => {
  return moment(incomingTime).format("hh:mm A");
};
const MonthNdDate = (incomingTime) => {
  return moment(incomingTime).format("MMMM DD");
};
const StartAndEndDate = (startDateTime, EndDateTime) => {
  const stratDate = moment(startDateTime).format("DD");
  const endDate = moment(EndDateTime).format("DD");
  const startMonth = moment(startDateTime).format("MMM");
  const endMonth = moment(EndDateTime).format("MMM");
  const startYear = moment(startDateTime).format("YYYY");
  const endYear = moment(EndDateTime).format("YYYY");
  if (
    stratDate === endDate &&
    startMonth === endMonth &&
    startYear === endYear
  ) {
    return moment(startDateTime).format("MMM DD, YYYY");
  } else if (
    stratDate !== endDate &&
    startMonth === endMonth &&
    startYear === endYear
  ) {
    return `${startMonth} ${stratDate}-${endDate}, ${startYear}`;
  } else if (startMonth !== endMonth && startYear === endYear) {
    return `${startMonth} ${stratDate} - ${endMonth} ${endDate}, ${endYear}`;
  } else if (startYear !== endYear) {
    return `${startMonth} ${stratDate}, ${startYear} - ${endMonth} ${endDate}, ${endYear}`;
  }
};
const formatTimeRange = (startTime, endTime) => {
  const startMoment = moment(startTime, "HH:mm");
  const endMoment = moment(endTime, "HH:mm");

  if (!startMoment.isValid() || !endMoment.isValid()) {
    return "Invalid time format";
  }

  const startTimeString = startMoment.format("h A");
  const endTimeString = endMoment.format("h A");

  return `${startTimeString} - ${endTimeString}`;
};
export const formatedDatesAndTime = {
  MonthNdDate,
  dateTimeUs,
  dateUSWithAlphaMonth,
  dateSimplewithSlashes,
  dateSimplewithDashes,
  dateTimeFromNow,
  dateSimplewithComma,
  currentTime,
  getOnlyMonth,
  getOnlyDate,
  getOnlyTime,
  ReversedateSimplewithDashes,
  StartAndEndDate,
  formatTimeRange,
};
export {
  MonthNdDate,
  dateTimeUs,
  dateUSWithAlphaMonth,
  dateSimplewithSlashes,
  dateSimplewithDashes,
  dateTimeFromNow,
  dateSimplewithComma,
  getOnlyMonth,
  getOnlyDate,
  getOnlyTime,
  ReversedateSimplewithDashes,
  StartAndEndDate,
  formatTimeRange,
};
