import { isValidUrl } from "@utils/validUrl";
import React, { useState } from "react";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import Pin from "@assets/pin-2.svg";
import { ThreeDots, UserImg } from "@utils/defaultImages";
import MessageInboxThreeDotsModal from "@components/Modal/MessageInboxThreeDotsModal";
import { Link } from "react-router-dom";

const ViewInbox = ({ items }) => {
  
  return (
    <>
      {items?.map((item) => {
        return (
          <Link
            className="list-users"
            key={item?.roomId}
            to={`/view-chat/${item?.user?._id}`}
            state={{ userObj: item }}
          >
            <div className="list-item">
              <img
                src={
                  isValidUrl(item?.user?.profileImage)
                    ? item?.user?.profileImage
                    : UserImg
                }
                alt={item?.user?.username}
                className="main-img"
              />
              <div className="list-info">
                <h4>
                  {item?.user?.username}
                  {/* {item?.privacy !== 0 && (
                    <img className="status-image" src={Pin} alt="image" />
                  )} */}
                </h4>
                <p>
                  {sliceStringAtWord(item?.latestMessage?.messageContent, 30)}
                </p>
              </div>
            </div>

            <div className="ThreeDostInbox">
              {/* <div
                className={`messageThreeDots ${
                  openRoomId === item?.roomId ? "active" : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick(item?.roomId);
                }}
              >
                <ThreeDots />
                {openRoomId === item?.roomId && (
                  <MessageInboxThreeDotsModal
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  />
                )}
              </div> */}
              {item?.unseenMessageCount > 0 && (
                <div className="message-count">
                  <p>{item?.unseenMessageCount}</p>
                </div>
              )}
            </div>
          </Link>
        );
      })}
    </>
  );
};

export default ViewInbox;
