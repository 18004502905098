import React, { useState } from "react";
import "./UserProfile.scss";
import DefaultUserImg from "@assets/defaultUser.png";
import { Link } from "react-router-dom";
import { isValidUrl } from "@utils/validUrl";

export default function Dummy({
  userData = {},
  isFollow = false,
  isDisableFollowUserBtn = false,
  followUnfollowUser = () => {},
  checkType,
}) {
  const text = userData?.bio || "";
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  let user;

  return (
    <div className="sticky-clm-2">
      <div className="user-detail-wrapper">
        <Link
          to={
            user?.user?._id === userData?._id
              ? `/profile`
              : `/profile/${userData?._id}`
          }
          className="profile-link"
        >
          <div className="avtar-in">
            <img
              src={
                isValidUrl(userData?.profileImage)
                  ? userData?.profileImage
                  : DefaultUserImg
              }
              alt="img78"
              className="main-img"
            />
            <div>
              <div className="name">
                {userData &&
                  `${userData?.firstName || ""} ${userData?.lastName || ""}`}
              </div>
              <div className="d-flex align-items-center">
                {userData?.location?.name && (
                  <>
                    <i className="fa-sharp fa-solid fa-location-dot pe-2"></i>
                    {`${userData?.location?.name}`}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="follow-info">
            <div>
              <h6>{userData?.followerCount || "0"}</h6>
              <p>Followers</p>
            </div>
            <div>
              <h6>{userData?.postCount || "0"}</h6>
              <p>Posts</p>
            </div>
            <div>
              <h6>{userData?.buildsCount || "0"}</h6>
              <p>Vehicles</p>
            </div>
          </div>
          <div className="user-personal-info">
            {userData?.bio && (
              <p className="text mt-3" style={{ wordBreak: "break-word" }}>
                {isReadMore ? text.slice(0, 50) : text}
                {text?.length > 50 && (
                  <span onClick={toggleReadMore} className="read-or-hide">
                    {isReadMore ? "...more" : " less"}
                  </span>
                )}
              </p>
            )}
          </div>
        </Link>
        {userData?._id !== user?.user?._id && (
          <div className="follow-btn" onClick={followUnfollowUser}>
            {userData?.isFollowed ? "Following" : "Follow"}
          </div>
        )}

        {/* )} */}
      </div>
    </div>
  );
}
