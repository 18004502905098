import React from "react";
import "./UserProfile.scss";
import DefaultUserImg from "@assets/defaultUser.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { isValidUrl } from "@utils/validUrl";
import { useGetUserProfileQuery } from "@redux/services/userProfile/UserProfileService";
import ReadMore from "./ReadMore";
import { sliceStringAtWord } from "@utils/sliceStringWord";

export default function BuildProfileActivity({ buildData }) {
  /**
   * GET GLOBAL STATE
   */
  // console.log("data---->>>", buildData);

  const userData = useSelector((state) => state?.auth?.loginUserData);
  /**
   * API CALLS
   */
  const { data: { data: { user } = {} } = {}, isFetching } = useGetUserProfileQuery(
    buildData?.userId
  );
  return (
    <div className="sticky-clm-2">
      <div className="user-detail-wrapper">
        <Link
          // to={
          //   user?._id === userData?._id
          //     ? `/profile`
          //     : `/profile/${userData?._id}`
          // }
          className="profile-link"
        >
          <div className="avtar-in">
            <img
              src={
                isValidUrl(user?.profileImage)
                  ? user?.profileImage
                  : DefaultUserImg
              }
              alt={user?.firstName}
              className="main-img"
            />
            <div>
              <div className="name">
                {user && `${user?.firstName || ""} ${user?.lastName || ""}`}
              </div>
              <div className="d-flex align-items-center">
                {user?.location?.name && (
                  <>
                    <i className="fa-sharp fa-solid fa-location-dot pe-2"></i>
                    {`${sliceStringAtWord(user?.location?.name, 15)}`}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="follow-info">
            <div>
              <h6>{user?.followerCount || "0"}</h6>
              <p>Followers</p>
            </div>
            <div>
              <h6>{user?.postCount || "0"}</h6>
              <p>Posts</p>
            </div>
            <div>
              <h6>{user?.buildsCount || "0"}</h6>
              <p>Vehicles</p>
            </div>
          </div>
          <div className="user-personal-info">
            {user?.bio && <ReadMore sliceText={50}>{user?.bio}</ReadMore>}
          </div>
        </Link>

        {/* {isFollow ? (
          <div className="follow-btn">Edit Profile</div>
        ) : isDisableFollowUserBtn ? (
          <div className="follow-btn">
            <ButtonLoader />
          </div>
        ) : ( */}
        {/* {userData?._id !== user?._id && (
          <div
            className="follow-btn"
            // onClick={followUnfollowUser}
          >
            {userData?.isFollowed ? "Following" : "Follow"}
          </div>
        )} */}

        {/* )} */}
      </div>
      {/* 
      {/* <div className="activity">
        {activityBuild?.length > 0 ? (
          loadingBuild && activityBuild.length === 0 ? (
            Array.from({ length: 1 }).map((_, index) => (
              <PostsHeaderLoader key={index} />
            ))
          ) : (
            <>
              <h2>Activity</h2>
              <div className="main-content" id="scrollableDiv">
                <InfiniteScroll
                  dataLength={activityBuild?.length}
                  next={getActivityList}
                  hasMore={hasMore}
                  loader={
                    hasMore && loadingBuild
                      ? Array.from({ length: 1 }).map((_, index) => (
                          <PostsHeaderLoader key={index} />
                        ))
                      : null
                  }
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  scrollableTarget="scrollableDiv"
                >
                  {activityBuild?.map((activityItem, index) => {
                    console.log("activityItem", activityItem);
                    return (
                      <div className="wrap-content">
                        <div className="main">
                          <div className="wrap-main-user">
                            <div className="main-user">
                              {activityItem?.image?.type === "1" ? (
                                <img
                                  src={
                                    isValidUrl(activityItem?.image?.path)
                                      ? activityItem?.image?.path
                                      : BannerImg
                                  }
                                  alt="text-img"
                                />
                              ) : activityItem?.image?.type === "2" ? (
                                <img
                                  src={activityItem?.image?.thumbnail}
                                  style={{ opacity: "0.4" }}
                                />
                              ) : (
                                <img src={TextImg} alt="post-text" />
                              )}
                              {activityItem?.image?.type === "2" && (
                                <div className="play">
                                  <VideoPlay />
                                </div>
                              )}
                            </div>
                            <div className="title-text">
                              <h6>{activityItem?.title}</h6>
                              <p>
                                {activityItem?.path?.buildName}/
                                {activityItem?.path?.ActivityType}{" "}
                                <span className="time">
                                  {dateTimeFromNow(activityItem?.createdAt)}
                                </span>
                              </p>
                            </div>
                          </div>

                          <ArrowRight />
                        </div>
                      </div>
                    );
                  })}
                </InfiniteScroll>
              </div>
            </>
          )
        ) : (
          <NotFound message="activity" />
        )}
      </div> */}
    </div>
  );
}
