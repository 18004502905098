/* global google */

import React, { useState } from "react";
import { LoadScript, GoogleMap, Marker } from "@react-google-maps/api";
import { BannerImg, DefaultImg } from "@utils/defaultImages";

const google_maps_key = process.env.REACT_APP_YOUR_GOOGLE_MAPS_API_KEY || "";
window.google = window?.google ? window?.google : {};
const GoogleMapComponent = ({ detailEventData, eventMapHeightType }) => {
  const [mapLoad, setMapLoad] = useState(false);
  const mapContainerStyle = {
    width: eventMapHeightType !== 1 ? "auto" : "280px",
    height:
      eventMapHeightType === 1
        ? "200px"
        : eventMapHeightType
        ? "380px"
        : "450px",
  };
  // console.log(
  //   "buttonType-----???",

  //   detailEventData?.location
  // );
  return (
    <>
      {!mapLoad && (
        <div>
          <img src={DefaultImg} alt="mapLoad" />
        </div>
      )}
      <LoadScript googleMapsApiKey={google_maps_key}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={{
            lng:
              detailEventData?.location[0]?.coordinates[0] ||
              detailEventData?.location?.coordinates[0],
            lat:
              detailEventData?.location[0]?.coordinates[1] ||
              detailEventData?.location?.coordinates[1],
          }}
          zoom={10}
          options={{
            gestureHandling: "none",
            streetViewControl: false,
            zoomControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          onLoad={(map) => {
            setMapLoad(true);
            const bounds = new window.google.maps.LatLngBounds() || {};
            

            if (
              Array?.isArray(detailEventData?.location) &&
              detailEventData?.location?.length > 1
            ) {
              detailEventData?.location?.forEach((location) => {
                bounds?.extend({
                  lng: location?.coordinates[0],
                  lat: location?.coordinates[1],
                });
              });
              map?.fitBounds(bounds);

              // Draw routes
              const directionsService =
                new window.google.maps.DirectionsService() || {};
              const directionsRenderer =
                new window.google.maps.DirectionsRenderer() || {};
              const waypoints = detailEventData?.location?.map((location) => ({
                location: new window.google.maps.LatLng(
                  location?.coordinates[1],
                  location?.coordinates[0]
                ),
                stopover: true,
              }));

              const request = {
                origin: waypoints[0]?.location,
                destination: waypoints[waypoints?.length - 1]?.location,
                waypoints: waypoints?.slice(1, waypoints?.length - 1),
                travelMode: window?.google?.maps?.TravelMode.DRIVING,
              };

              directionsService.route(request, (result, status) => {
                if (status === window?.google?.maps?.DirectionsStatus?.OK) {
                  directionsRenderer?.setDirections(result);
                  directionsRenderer?.setMap(map);
                } else {
                  console.error("Error drawing routes:", status);
                }
              });
            }
          }}
        >
          {Array?.isArray(detailEventData?.location) ? (
            detailEventData?.location?.map((nestedField, index) => {
              const path = {
                lng: nestedField?.coordinates[0],
                lat: nestedField?.coordinates[1],
              };

              return (
                <React.Fragment key={index}>
                  <Marker key={index} position={path} />
                </React.Fragment>
              );
            })
          ) : (
            <Marker
              // key={index}
              position={{
                lng:
                  detailEventData?.location[0]?.coordinates[0] ||
                  detailEventData?.location?.coordinates[0],
                lat:
                  detailEventData?.location[0]?.coordinates[1] ||
                  detailEventData?.location?.coordinates[1],
              }}
            />
          )}
        </GoogleMap>
      </LoadScript>
    </>
  );
};

export default GoogleMapComponent;
