import React from "react";
import WrapperModal from "./WrapperModal";
import FollowersBuild from "@containers/BuildProfile/Model/FollowersBuild";

const BuildFollowersModel = React.memo(({ open, closeModal }) => {
  return (
    <div id="modal">
      <WrapperModal
        open={open}
        closeModal={closeModal}
        style={{
          top: "50%",
          transform: "translateY(-50%)",
          left: 0,
          right: 0,
          margin: "0 auto",
          backgroundColor: "#121313",
        }}
      >
        <p className="build-followers py-3">Followers</p>
        <FollowersBuild />
      </WrapperModal>
    </div>
  );
});

export default BuildFollowersModel;
