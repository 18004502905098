import React, { useState } from "react";
import FollowFollowingTab from "./FollowFollowingTab";
import "./Followerslist.scss";
import FollowingList from "./TabSwitch/FollowingList";
import FollowersList from "./TabSwitch/FollowersList";
import FollowedVehicles from "./TabSwitch/FollowedVehicles";
export default function FollowersFollowing({closeModal}) {
  const [tabOpen, setTabOpen] = useState(1);
  return (
    <div className="crew-post-Wrapper follow-followers">
      <div>
        <FollowFollowingTab tabOpen={tabOpen} setTabOpen={setTabOpen} />
        {tabOpen === 1 && <FollowersList closeModal={closeModal}/>}
        {tabOpen === 2 && <FollowingList closeModal={closeModal}/>}
        {tabOpen === 3 && <FollowedVehicles closeModal={closeModal}/>}
      </div>
    </div>
  );
}
