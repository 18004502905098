import React from "react";
import "./GaragePart.scss";
import { isValidUrl } from "@utils/validUrl";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { Link } from "react-router-dom";
import { useExploreBuildQuery } from "@redux/services/exploreService/exploreService";
import { DefaultImg } from "@utils/defaultImages";

const GaragePart = () => {
  /**
   * API CALLS
   */
  const { data: { data: exploreBuild = [] } = {} } = useExploreBuildQuery();

  return (
    <>
      <div className="view-all-heading-build">
        <h2>Builds </h2>
        {exploreBuild?.docs?.length > 6 && (
          <Link to={`/explore/categories/Builds`} 
          state={{ title: "Builds" }}
          >
            See More
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.25 11.25L10 7.5L6.25 3.75"
                stroke="#387AFF"
                stroke-linecap="round"
              />
            </svg>
          </Link>
        )}
      </div>{" "}
      <div className="garage-row-build">
        {exploreBuild?.docs?.slice(0, 5)?.map?.((item, index) => {
          return (
            <div className="garage-block" key={item?._id}>
              <img
                src={
                  isValidUrl(item?.bannerImage) ? item?.bannerImage : DefaultImg
                }
                alt={`${item?.make}-${item?.model}-${item?.year?.value}`}
              />

              <div className="info">
                {/* <StarRating
                  rating={item?.rating}
                  isShow={false}
                  isRedirect={false}
                /> */}
                <div>
                  <span className={item.type === 1 ? "current" : "previous"}>
                    {item?.type === 1 ? "Current" : "Legacy"}
                  </span>
                  <div className="model">
                    {item?.year?.value} {item?.make}
                  </div>
                  <div className="name">{sliceStringAtWord(item?.name, 5)}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default GaragePart;
