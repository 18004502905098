import { DefaultUserImg } from "@utils/defaultImages";
import { isValidUrl } from "@utils/validUrl";
import React from "react";

const SuggestedUser = ({ suggestion }) => {
  // console.log("loaderAdd", search);
  return (
    <>
      <div className="wrap-suggestion d-flex align-items-center gap-2">
        <div className="suggestion-img">
          <img
            src={
              isValidUrl(suggestion?.profile)
                ? suggestion?.profile
                : DefaultUserImg
            }
            alt="user"
            width={28}
            height={28}
            style={{ borderRadius: "25px" }}
          />
        </div>
        <div className="suggestion-title">
          <h6
            style={{
              fontSize: "12px",
              fontWeight: 400,
              fontFamily: "Inter",
              color: "#f5f5f5",
              paddingTop: "7px",
            }}
          >
            {suggestion?.display}
          </h6>
        </div>
      </div>
    </>
  );
};

export default SuggestedUser;
