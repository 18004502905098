import React from 'react'
import { ReactComponent as PrivateIcon } from "@assets/private-lock.svg";

const PrivacyComp = () => {
  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <PrivateIcon />
      <h6 style={{ color: "FFFFFF", padding: "5px 0", margin:"unset" }}>
        This crew is private
      </h6>
      <p style={{ color: "#8E8E93" }}>Join crew to see events & discussions.</p>
    </div>
  );
}

export default PrivacyComp