


import React from "react";
import Carvonix from "@assets/membershipDashboard/Carvonix-Color-Logo_Full.svg";
import Closes from "@assets/membershipDashboard/close.svg";
import Close2 from "@assets/membershipDashboard/close.png";
import SpeedSociety from "@assets/membershipDashboard/SpeedSociety.svg";
import Bolt from "@assets/membershipDashboard/bolt.svg";
import Cart from "@assets/membershipDashboard/cart.svg";
const memberShipFirstTimeView = ({onClose}) => {
    const handleCloseFirstTime = ()=>{
        onClose();
    }
    return (
        <div className="insideLinkToSpeedSociety">
            <div style={{ textAlign: "end" }}>
                <button style={{ backgroundColor: "#18191A", border: "none" }} onClick={handleCloseFirstTime}>
                    <img src={Close2} alt="close Icon" />
                </button>
            </div>
            <div className="logos">
                <img src={Carvonix} alt="Carvonix logo" />
                <img src={Closes} alt="Closes logo" />
                <img src={SpeedSociety} alt="Speed Society logo" />
            </div>
            <p
                style={{
                    color: "#8E8E93",
                    fontWeight: "400",
                    fontSize: "13px",
                    lineHeight: "16.9px",
                    textAlign: "center",
                    width: "80%",
                    margin: "0 auto",
                }}
            >
                Link your Speed Society account with Carvonix and get access to special offers
            </p>
            <div className="speed-store">
                <div className="speed">
                    <img src={Bolt} alt="Bolt icon" />
                    <div>
                        <p>View your current Speed Society Entries right here in Carvonix</p>
                    </div>
                </div>
                <div className="cart">
                    <img src={Cart} alt="Cart icon" />
                    <div>
                        <p>Quickly jump from Carvonix to Speed Society to shop and earn more entries</p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default memberShipFirstTimeView;
