import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { ArrowLeft } from "@utils/defaultImages";
import { Link } from "react-router-dom";
import { useLazyGetAllRelatedPostQuery } from "@redux/services/userProfile/BuildComponentService";
import FeedHeader from "../MainFeed/subComponents/feedHeader";
import MediaShow from "../MainFeed/subComponents/mediaShow";
import Comments from "@components/Comments/Comments";
import LikeModal from "@components/Modal/LikeModal";
import InstagramFeedMain from "@components/Skeleton/InstagramFeedMain";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";

const ComponentPosts = () => {
  const { state } = useLocation();
  /**
   * USESTATES
   */
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [relatedData, setRelatedData] = useState([])
  // media Query
  const isMobile = useMediaQuery({ maxWidth: 599 });
  const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 1023 });
  const Navigate = useNavigate();
  const { componentId } = useParams();

  /**
   * API CALLS
   */
  const [getAllRelatedPost, { isLoading }] =
    useLazyGetAllRelatedPostQuery();
  /**
   * FUNCTIONS
   */
  const relatedPost = async () => {
    const body = {
      componentId: componentId,
      page: page,
    };
    try {
      const res = await getAllRelatedPost(body).unwrap();
      setRelatedData(res?.data?.posts)
        // console.log("relatedData", res);

    } catch (error) {
      console.log("error", error);
    }
  };
  /**
   * USEEFFECT
   */
  useEffect(() => {
    relatedPost();
  }, []);
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-4 related-post">
          <Link onClick={() => Navigate(-1)}>
            <ArrowLeft />
          </Link>
        </div>
        <div className="col-lg-4 col-md-4 top-post">
          <h6>Top Related Posts</h6>
          <p>{state?.componentName}</p>{" "}
        </div>
      </div>
      <hr />
      {isLoading ? (
        <>
          {Array.from({ length: 2 }).map((_, index) => (
            <InstagramFeedMain key={index} />
          ))}
        </>
      ) : (
        <>
          {relatedData?.docs?.length > 0 && (
            <>
              <div>
                {relatedData?.docs?.map((post, index) => (
                  <div
                    className="feed-block"
                    key={post?._id}
                    style={{
                      borderBottom:
                        relatedData?.docs?.length - 1 !== index &&
                        "1px solid #323334",
                    }}
                  >
                    {/* HEADER SHOW */}
                    <FeedHeader
                      feedPost={post}
                      isMobile={isMobile}
                      isTablet={isTablet}
                      index={index}
                    />
                    {/* IMAGES AND CAROUSEL */}
                    <MediaShow feedPost={post} />
                    {/* TITLE AND DESCRIPTION */}
                    {/* {post?.title && (
                      <div className="show-more-title-post mt-2">
                        <ReadMore sliceText={70}>{post?.title}</ReadMore>
                      </div>
                    )}
                    {post?.description && (
                      <div className="show-more-title">
                        <ReadMore sliceText={140}>{post?.description}</ReadMore>
                      </div>
                    )} */}
                    {/* COMMENTS SHOW */}
                    <Comments
                      post={post}
                      commentsCount={post?.commentsCount}
                      postId={post?._id}
                      isTablet={isTablet}
                      isComponent={true}
                    />
                  </div>
                ))}
              </div>
              {isOpen && (
                <LikeModal open={isOpen} closeModal={() => setIsOpen(false)} />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ComponentPosts;
