import React from "react";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as ArrowRight } from "@assets/ArrowRight.svg";
import BannerImg from "@assets/userProfileImgs/default.png";
import { isValidUrl } from "@utils/validUrl";
import "@containers/UserProfile/UserProfile.scss";
import NotFound from "@components/NotFound";
import { useGetUserVehicleQuery } from "@redux/services/userProfile/UserProfileService";

const UserBuildById = () => {
  const { Id:userId } = useParams();

  const { data: { data } = {} } = useGetUserVehicleQuery(userId);
  let userInfo = data?.userInfo || {};
  // console.log("camType---??", data);

  return (
    <div className="garage-section">
      <div className="container">
        <h3>
          Vehicles <span>{data?.totalDocs}</span>
        </h3>
        {data?.docs?.length > 0 ? (
          <div className="garage-row">
            {data?.docs?.map((veh) => {
              const username = userInfo?.username ? userInfo.username : "user";
              const year = veh?.year?.value
                ? veh.year.value.toString().replaceAll("year", "")
                : "";
              const make = veh?.make
                ? veh.make.toString().replaceAll("make", "")
                : "";
              const model = veh?.model
                ? veh.model.toString().replaceAll("model", "")
                : "";
              const id = veh?._id ? veh?._id.toString() : "";

              const url = `/${username}/garage/${year}/${make}/${model}/${id}`;

              return (
                <Link to={url} key={veh?.id}>
                  <div className="garage-block">
                    <img
                      src={
                        isValidUrl(veh?.bannerImage)
                          ? veh?.bannerImage
                          : BannerImg
                      }
                      alt={`${veh?.make}/${veh?.year?.value}/${veh?.model}`}
                    />

                    <div className="info">
                      <div className="ownership">
                        {veh?.type === 1 ? (
                          <>
                            <div className="bluebullet"></div>
                            <span className="current">Current</span>
                          </>
                        ) : (
                          <>
                            <div className="orangebullet"></div>
                            <span className="current">Legacy</span>
                          </>
                        )}
                      </div>

                      <div className="model">
                        {veh?.year?.value} {veh?.model}
                      </div>
                      <div className="name">
                        {veh?.name?.length > 50
                          ? `${veh?.name?.slice(0, 50)}...`
                          : veh?.name}
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        ) : (
          <NotFound message="Vehicle" />
        )}
        {/* {data?.totalDocs > 4 && (
          <Link
            to={
              userInfo?._id &&
              `/profile/${userInfo?._id}/all/?builds=all-builds`
            }
          >
            <div className="load-more">
              See more
              <ArrowRight />
            </div>
          </Link>
        )} */}
      </div>
    </div>
  );
};

export default UserBuildById;
