import React, { useState, useMemo, Suspense } from "react";
import ReactInstaStories, { WithHeader } from "react-insta-stories";
import "./StoriesView.scss";
import {
  Main_story,
  CloseStories,
  DefaultImg,
  DefaultUserImg,
  StoriesUp,
  StoriesDown,
} from "@utils/defaultImages";
import { useLazyListStoriesQuery } from "@redux/services/Stories/storyServices";
import { isValidUrl } from "@utils/validUrl";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import StoriesSkeleton from "@components/Skeleton/StoriesSkeleton";
import { dateTimeFromNow } from "@utils/DateFormatter";
import useMediaQueryHook from "@utils/hooks/useMediaQueryHook";

const CustomFooter = React.lazy(() => import("./CustomFooter"));
const CustomHeader = React.lazy(() => import("./CustomHeader"));

const customCollapsedComponent = ({ story, storyItem }) => {
  return (
    <div className="custom-header">
      <div className="wrap-footer" style={{display:"flex",justifyContent:"flex-end"}}>
        <Suspense fallback={<div>Loading footer...</div>}>
          <CustomFooter storyList={story} />
        </Suspense>
      </div>
    </div>
  );
};

const storyInnerStyles = {
  justifyContent: "center",
};
const progressContainerStyles = {
  position: "absolute",
  bottom: "0",
  width: "94%",
  borderRadius: "22px",
  zIndex: 9999,
};
const storyContainerStyles = {
  maxWidth: "482px",
  maxHeight: "853px",
};
const StoriesView = () => {
  const { isMobileSmall, isMobile, isTablet, isTabScr } = useMediaQueryHook();

  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const stories = location?.state?.stories;
  const [storiesFilter, setStoriesFilter] = useState([]);
  const [ listStories, {isLoading, isFetching }] =
    useLazyListStoriesQuery();
  const [hasNextPage, setHasNextPage] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);

  // let storiesFilter = feedStories?.filter(
  //   (item) => item?.stories?.length > 0  && item?._id !== stories?._id
  // );
  // console.log(storiesFilter);
  // storiesFilter?.unshift(stories);
  // console.log(storiesFilter);

  // let storiesFilter = useMemo(() => {
  //   let filteredStories = data?.docs?.filter(
  //     (item) => item?.stories?.length > 0 && item?._id !== stories?._id
  //   ) || [];

  //   if (stories && page===1) {
  //     filteredStories.unshift(stories);
  //   }else if(page>2){
  //     let combinedStories = [storiesFilter,...filteredStories]
  //     return combinedStories
  //   }
  //   return filteredStories;
  // }, [data, stories]);

  const handleNextPage = () => {
    if (hasNextPage) {
      setPage(page + 1);
    }
  }
  useEffect(() => {
    fetchStories();
  }, [page]);
  const fetchStories = async () => {
    const response = await listStories(page).unwrap();
    setHasNextPage(response?.data?.hasNextPage)
    let filteredStories = response?.data?.docs?.filter(
      (item) => item?.stories?.length > 0 && item?._id !== stories?._id
    ) || [];
    if (stories && page === 1) {
      filteredStories.unshift(stories);
    }

    setStoriesFilter((prevStories) => {
      if (page > 1) {
        return [...prevStories, ...filteredStories];
      } else {
        return filteredStories;
      }
    });
  }
  const totalStories = storiesFilter?.length || 0;
  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (
      scrollTop + clientHeight >= scrollHeight &&
      currentIndex < totalStories - 1
    ) {
      setCurrentIndex(currentIndex + 1);
      if (currentIndex+1===totalStories-1) {
        handleNextPage();
      }
    } else if (scrollTop === 0 && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const handleNextStory = () => {
      if(currentIndex+1===totalStories) {
        setCurrentIndex(currentIndex + 1);
        handleNextPage();
      }
      else{
        setCurrentIndex(currentIndex + 1);
      }
   
  };

  const dynamicHeight =
    isMobileSmall || isMobile
      ? "83vh"
      : isTablet
        ? "88vh"
        : isTabScr
          ? "85vh"
          : "100vh";
  const storyComponents = useMemo(
    () =>
      storiesFilter?.map(
        (storyItem, storyIndex) =>
          storyItem?.stories?.length > 0 &&
          currentIndex === storyIndex && (
            <div
              className="col-lg-12 my-3 d-flex justify-content-end for-video"
              key={storyIndex}
            >
              <ReactInstaStories
                stories={storyItem?.stories?.map((storyData, index) => {
                  const isVideo = storyData?.media?.type === "2";
                  const intervalInMilliseconds =
                    (Math.round(storyData?.media?.duration) || 5) * 1000;
                  return {
                    header: {
                      heading: storyItem?.username,
                      subheading: dateTimeFromNow(storyData?.createdAt),
                      profileImage: isValidUrl(storyItem?.profileImage)
                        ? storyItem?.profileImage
                        : DefaultUserImg,
                    },
                    seeMore: ({ close }) => (
                      <div className="custom-header">
                        <div className="wrap-footer">
                          <Suspense fallback={<div>Loading footer...</div>}>
                            <CustomFooter storyList={storyData} />
                          </Suspense>
                        </div>
                      </div>
                    ),
                    seeMoreCollapsed: (props) =>
                      customCollapsedComponent({
                        ...props,
                        story: storyData,
                        storyItem,
                      }),
                    url: storyData?.media?.path,
                    type: isVideo ? "video" : "image",
                    duration: intervalInMilliseconds,
                    autoplay: true,
                    muted: false,
                  };
                })}
                storyInnerContainerStyles={storyInnerStyles}
                progressContainerStyles={progressContainerStyles}
                storyContainerStyles={storyContainerStyles}
                width={"100%"}
                height={dynamicHeight}
                currentIndex={currentIndex}
              // storyStyles={{
              //   // width: "auto",
              //   // maxWidth: "100%",
              //   // maxHeight: "100%",
              //   // margin: "auto",
              //   display: "none",
              // }}
              />
            </div>
          )
      ),
    [storiesFilter, currentIndex]
  );

  // const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/stories-list") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [location.pathname]);

  // useEffect(() => {
  //   if (location.pathname === "/stories-list") {
  //     const styleElement = document.createElement("style");
  //     styleElement.innerHTML = `
  //     body::-webkit-scrollbar {
  //       display: none;
  //     }
  //   `;
  //     document.head.appendChild(styleElement);

  //     return () => {
  //       document.body.style.overflow = "auto";
  //       document.head.removeChild(styleElement);
  //     };
  //   }
  // }, [location.pathname]);
  return (
    <>
      {isLoading ? (
        <StoriesSkeleton />
      ) : (
        <>
          <div className="container stories-container" onScroll={handleScroll}>
            {isFetching ? (
              <StoriesSkeleton />
            ) : (
              <div className="row">{storyComponents}</div>
            )}
          </div>
          {!(isMobile || isMobileSmall) && (
            <div>
              <CloseStories className="pointer" onClick={() => navigate(-1)} />
              <div className="d-flex flex-column gap-3 position-fixed top-50">
                {currentIndex > 0 && (
                  <StoriesUp
                    onClick={() => setCurrentIndex(currentIndex - 1)}
                    className="pointer"
                  />
                )}
                {(currentIndex <  totalStories - 1 || hasNextPage) && (
                  <StoriesDown
                    onClick={() => handleNextStory()}
                    className="pointer"
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>

  );
};

export default StoriesView;
