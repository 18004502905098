import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "../baseQuery";

export const allCrewApi = createApi({
  reducerPath: "allCrewApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["join"],
  endpoints: (builder) => ({
    getYourCrew: builder.query({
      query: (page) => ({
        url: `/crew/get-all`,
        method: "GET",
        params: {
          sortBy: "createdAt:asc",
          nearBy: true,
          limit: 10,
          page: page,
        },
      }),
    }),
    getNearCrew: builder.query({
      query: (page) => ({
        url: `/crew/get-all`,
        method: "GET",
        params: {
          sortBy: "createdAt:asc",
          nearBy: true,
          limit: 10,
          page: page,
        },
      }),
    }),
    upComingEvents: builder.query({
      query: () => ({
        url: `/crew/event/all`,
        method: "GET",
      }),
    }),
    getRecomendedCrews: builder.query({
      query: (page = 1) => ({
        url: `/crew/get-all`,
        method: "GET",
        params: {
          sortBy: "createdAt:desc",
          limit: 10,
          page: page,
        },
      }),
    }),
    searchAllCrews: builder.query({
      query: ({ value, page }) => ({
        url: `search/all`,
        method: "GET",
        params: {
          carvonixModules: 2,
          searchBy: value,
          sortBy: "createdAt:desc",
          limit: 10,
          page: page,
        },
      }),
    }),
    getCrewById: builder.query({
      query: (crewId) => ({
        url: `/crew/${crewId}`,
        method: "GET",
      }),
      providesTags: ["join"],
    }),
    getEventsById: builder.query({
      query: (crewId) => ({
        url: `/crew/${crewId}/event`,
        method: "GET",
        params: {
          sortBy: "createdAt:desc",
          page: 1,
          limit: 10,
        },
      }),
    }),
    getUpcomingEvents: builder.query({
      query: (crewId) => ({
        url: `/crew/${crewId}/event`,
        method: "GET",
        params: {
          event: "HAPPENINGSOON",
        },
      }),
    }),
    getCreatorAndModerators: builder.query({
      query: (crewId) => ({
        url: `/crew/${crewId}/moderators-with-owner`,
        method: "GET",
        params: {
          sortBy: "createdAt:desc",
        },
      }),
    }),
    getFilterCategory: builder.query({
      query: (crewId) => ({
        url: `/crew/${crewId}/discussion-category`,
        method: "GET",
      }),
    }),
    getAllFilterCategory: builder.query({
      query: (crewId) => ({
        url: `/posts/crew/get-all/${crewId}`,
        method: "GET",
        params: {
          sortBy: "createdAt:desc",
        },
      }),
    }),
    getSelectedFilterCategory: builder.query({
      query: ({ crewId, categoryId, page }) => ({
        url: `/posts/crew/get-all/${crewId}`,
        method: "GET",
        params: {
          categoryId: categoryId,
          sortBy: "createdAt:desc",
          limit: 10,
          page: page,
        },
      }),
    }),
    getModerator: builder.query({
      query: (id) => ({
        url: `/crew/${id}/members/list`,
        method: "GET",
        params: {
          memberType: 1,
          limit: 10,
          page: 1,
        },
      }),
    }),
    getMembers: builder.query({
      query: (id) => ({
        url: `/crew/${id}/members/list`,
        method: "GET",
        params: {
          memberType: 2,
          limit: 10,
          page: 1,
        },
      }),
    }),
    followMM: builder.mutation({
      query: (value) => ({
        url: `/users/follow/user`,
        method: "POST",
        body: value,
      }),
    }),
    removefollowMM: builder.mutation({
      query: (removeId) => ({
        url: `/users/remove-following/${removeId}`,
        method: "DELETE",
      }),
    }),
    joinCrew: builder.mutation({
      query: ({ crewId, value }) => ({
        url: `/crew/${crewId}/join`,
        method: "POST",
        body: value,
      }),
      invalidatesTags: ["join"],
    }),
    disJoinCrew: builder.mutation({
      query: (crewId) => ({
        url: `/crew/${crewId}/disjoin`,
        method: "DELETE",
      }),
      invalidatesTags: ["join"],
    }),
    getAllNearCrew: builder.query({
      query: ({ page = 1, searchBy }) => ({
        url: `/crew/get-all`,
        method: "GET",
        params: {
          sortBy: "createdAt:asc",
          nearBy: true,
          limit: 20,
          page: page,
          name: searchBy,
        },
      }),
    }),
    getAllRecomendedCrew: builder.query({
      query: ({ page = 1, searchBy }) => ({
        url: `/crew/get-all`,
        method: "GET",
        params: {
          sortBy: "createdAt:desc",
          nearBy: true,
          limit: 20,
          page: page,
          name: searchBy,
        },
      }),
    }),
    crewSingleEvent: builder.query({
      query: (eventId) => ({
        url: `/crew/event/${eventId}`,
        method: "GET",
      }),
    }),
    crewAttendees: builder.query({
      query: (eventId) => ({
        url: `/crew/event/${eventId}/attendees`,
        method: "GET",
      }),
    }),
    respondType: builder.query({
      query: ({ eventId, type }) => ({
        url: `/crew/event/${eventId}/respond/${type}`,
        method: "GET",
      }),
    }),
    getPostsData: builder.query({
      query: (eventId) => ({
        url: `/posts/${eventId}`,
        method: "GET",
      }),
    }),
    getCrewByIdGeneral: builder.query({
      query: (crewId) => ({
        url: `/crew/${crewId}`,
        method: "GET",
        params: {
          crewId: crewId,
        },
      }),
      providesTags: ["join"],
    }),
  }),
});
export const {
  useLazyGetYourCrewQuery,
  useGetNearCrewQuery,
  useUpComingEventsQuery,
  useGetRecomendedCrewsQuery,
  useLazySearchAllCrewsQuery,
  useGetCrewByIdQuery,
  useGetEventsByIdQuery,
  useGetUpcomingEventsQuery,
  useGetCreatorAndModeratorsQuery,
  useGetFilterCategoryQuery,
  useGetAllFilterCategoryQuery,
  useLazyGetSelectedFilterCategoryQuery,
  useLazyGetModeratorQuery,
  useLazyGetMembersQuery,
  useFollowMMMutation,
  useRemovefollowMMMutation,
  useJoinCrewMutation,
  useDisJoinCrewMutation,
  useLazyGetAllNearCrewQuery,
  useLazyGetAllRecomendedCrewQuery,
  useCrewSingleEventQuery,
  useCrewAttendeesQuery,
  useLazyRespondTypeQuery,
  useGetPostsDataQuery,
  useGetCrewByIdGeneralQuery
} = allCrewApi;
