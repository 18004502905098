import React from "react";
import ContentLoader from "react-content-loader";

const NearYouRecomendedSkeleton = (props) => (
  <ContentLoader
    width={200}
    height={350}
    viewBox="0 0 450 700"
    speed={2}
    backgroundColor="#ababab"
    foregroundColor="#fafafa"
    {...props}
  >
    <rect x="40" y="77" rx="10" ry="10" width="410" height="330" />
  </ContentLoader>
);

export default NearYouRecomendedSkeleton;
