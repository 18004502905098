import React from "react";
import "./MultipleUsers.scss";
import { isValidUrl } from "@utils/validUrl";
import { DefaultUserImg } from "@utils/defaultImages";

export const MultipleUsers = ({ listMultiple, property1 }) => {
  return (
    <>
      {listMultiple?.map((multiple, index) => {
        // console.log("index", index);
        return (
          <div
            className={`multiple-users ${property1}`}
            style={{
              overflow: "hidden",
              // marginLeft: crewMembers?.length > 1 ? "-17px" : "",
              marginLeft: "-8px",
            }}
          >
            <img
              className="avatar"
              alt="Avatar"
              src={
                isValidUrl(multiple?.user?.profileImage)
                  ? multiple?.user?.profileImage
                  : DefaultUserImg
              }
              style={{ borderRadius: "12px" }}
            />
          </div>
        );
      })}
    </>
  );
};
