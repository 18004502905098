import React, { Component } from "react";
import SiteLogo from "@assets/site-logo.svg";
import "./Signup.scss";
import { ReactComponent as Back } from "@assets/back.svg";
import { ReactComponent as Skip } from "@assets/Skip.svg";
export default class Categories extends Component {
  render() {
    return (
      <div>
        <div className="signup-container">
          <div className="signup-wrapper">
            <img src={SiteLogo} alt="" className="logo" />
            <div className="lets-go">What are your interests?</div>
            <div className="enter-text">Select at least 3.</div>
            <div className="form-group">
              <div className="interests-list">
                <label>
                  <input type="checkbox" name="car" />
                  <span>Ferrari</span>
                </label>
                <label>
                  <input type="checkbox" name="gender" />
                  <span>4x4</span>
                </label>
                <label>
                  <input type="checkbox" name="gender" />
                  <span>Classic cars</span>
                </label>
                <label>
                  <input type="checkbox" name="gender" />
                  <span>4x4</span>
                </label>
                <label>
                  <input type="checkbox" name="gender" />
                  <span>4x4</span>
                </label>
                <label>
                  <input type="checkbox" name="gender" />
                  <span>Classic cars</span>
                </label>
                <label>
                  <input type="checkbox" name="gender" />
                  <span>4x4</span>
                </label>
                <label>
                  <input type="checkbox" name="gender" />
                  <span>Classic cars</span>
                </label>
                <label>
                  <input type="checkbox" name="gender" />
                  <span>Interest</span>
                </label>
                <label>
                  <input type="checkbox" name="gender" />
                  <span>4x4</span>
                </label>
                <label>
                  <input type="checkbox" name="gender" />
                  <span>Classic cars</span>
                </label>
                <label>
                  <input type="checkbox" name="gender" />
                  <span>Classic cars</span>
                </label>
                <label>
                  <input type="checkbox" name="gender" />
                  <span>Interest</span>
                </label>
                <label>
                  <input type="checkbox" name="gender" />
                  <span>4x4</span>
                </label>
                <label>
                  <input type="checkbox" name="gender" />
                  <span>Classic cars</span>
                </label>{" "}
                <label>
                  <input type="checkbox" name="gender" />
                  <span>Classic cars</span>
                </label>
              </div>
            </div>

            <button className="next-btn">Next</button>
            <div className="ft-buttons">
              <div className="back-btn">
                <Back />
                Back
              </div>
              <div>
                <div className="back-btn">
                  Skip
                  <Skip />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
