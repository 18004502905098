export function isValidUrl(url) {
  // Check if the URL is empty or starts with "file://"
  if (url === "" || url?.startsWith("file://")) {
    return false;
  }

  // Check if the URL is valid using URL constructor
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}
