import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import car from "@assets/car.jpg";
import gps from "@assets/gps.png";
import userImage from "@assets/defaultUser.png";
import { useLazyChatListingQuery } from "@redux/services/messenger/messengerService";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { isValidUrl } from "@utils/validUrl";
import { useSelector } from "react-redux";
import SendMessage from "./SendMessage";
import InToChat from "./InToChat";
import InfiniteScroll from "react-infinite-scroll-component";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { LocationChat } from "@utils/defaultImages";
import { Link, useParams } from "react-router-dom";
import LottieTyping from "@components/Lottie/Typing.json";
import { Player } from "@lottiefiles/react-lottie-player";

const ViewChat = ({ messengerUser, socket }) => {
  const user = useSelector((state) => state?.auth?.loginUserData);
  const messageEndRef = useRef(null);
  const { id } = useParams();
  const profileData = {
    name: messengerUser?.user?.username || messengerUser?.username,
    followerCount: messengerUser?.user?.followerCount || messengerUser?.followerCount,
    postCount: messengerUser?.user?.postCount || messengerUser?.postCount,
    profileImage: messengerUser?.user?.profileImage || messengerUser?.profileImage,
    id :  messengerUser?.user?._id || messengerUser?._id,
    location: {
      name: messengerUser?.user?.location?.name || messengerUser?.location?.name,
    },
  };

  /**
   * USESTATES
   */
  const [messagesReceived, setMessagesReceived] = useState([]);
  const [isEditMessage, setisEditMessage] = useState({});
  const [page, setPage] = useState(1);
  const [typingUser, setTypingUser] = useState([]);
  /**
   * API CALL
   */
  const [getOneToOneChat, { data: { data: allMessageList } = {}, isFetching }] =
    useLazyChatListingQuery();
  // Helper function to generate roomId
  const generateRoomId = (userId1, userId2 = id) => {
    return userId1 < userId2
      ? `${userId1}_${userId2}`
      : `${userId2}_${userId1}`;
  };
  /**
   * Room Id
   */
  // Memoized roomId to avoid unnecessary recalculations
  const roomId = useMemo(() => {
    return generateRoomId(user?._id, messengerUser?._id);
  }, [user?._id, messengerUser?._id]);
  // let roomId;
  // if (user?._id < messengerUser?._id) {
  //   roomId = `${user?._id}_${messengerUser?._id}`;
  // } else {
  //   roomId = `${messengerUser?._id}_${user?._id}`;
  // }
  /**
   * Fetch chat messages for the current room.
   */
  // console.log('roomId', roomId)
  // console.log("first",roomId);

  const fetchChat = useCallback(async () => {
    // navigate(`/view-chat/${userInfoData?._id}`);

    // return roomId;
    const values = {
      room_Id: roomId,
      page: page,
    };
    try {
      const response = await getOneToOneChat(values).unwrap();
      setMessagesReceived((state) => [...state, ...response?.data?.docs]);
    } catch (error) {
      toast.error(error?.data?.message);
    }
  }, [getOneToOneChat, roomId, page]);

  /**
   * Join the chat room.
   */
  const joinRoom = useCallback(() => {
    if (socket && roomId) {
      socket.emit("join-room", roomId, user?._id);
    }
  }, [socket, roomId, user?._id]);

  /**
   * Leave the chat room.
   */
  const leaveRoom = useCallback(() => {
    if (socket && roomId) {
      socket.emit("leave-room", roomId, user?._id);
    }
  }, [socket, roomId, user?._id]);

  /**
   * Scroll to the bottom of the chat.
   */
  const scrollToBottom = useCallback(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollTop = messageEndRef.current.scrollHeight;
    }
  }, []);
  /**
   * Pagination onLoad.
   */
  const loadMore = useCallback(() => {
    if (!isFetching && allMessageList?.nextPage) {
      setPage(allMessageList?.nextPage);
    }
  }, [isFetching, allMessageList?.nextPage]);

  /**
   * Handle new incoming messages.
   */
  useEffect(() => {
    const handleNewMessage = (data) => {
      setMessagesReceived((state) => [...state, data]);
    };

    const deleteMessageListener = (message) => {
      setMessagesReceived((prevMessages) =>
        prevMessages?.filter((msg) => msg?._id !== message?.chatId)
      );
    };
    const updateMessageListener = (updatedMessage) => {
      setMessagesReceived((prevMessages) =>
        prevMessages?.map((msg) =>
          msg?._id == updatedMessage?.chatId
            ? {
                ...msg,
                messageContent: updatedMessage?.messageContent,
                isEdited: new Date(),
              }
            : msg
        )
      );
    };

    socket.on("new-message", handleNewMessage);
    socket.on("deleteMessage", deleteMessageListener);
    socket.on("updateMessage", updateMessageListener);

    return () => {
      socket.off("new-message", handleNewMessage);
      socket.off("deleteMessage", deleteMessageListener);
      socket.off("updateMessage", updateMessageListener);
    };
  }, [socket]);

  /**
   * Initial data fetching and setup.
   */
  useLayoutEffect(() => {
    joinRoom();

    return () => {
      leaveRoom();
    };
  }, [joinRoom, leaveRoom]);
  /**
   * Scroll to the bottom when messages are updated.
   */
  useEffect(() => {
    scrollToBottom();
  }, [page === 1 && messagesReceived]);
  /**
   * Fetch data to page upload.
   */
  useEffect(() => {
    fetchChat();
  }, [page, roomId]);

  useEffect(() => {
    setMessagesReceived([]);
    setPage(1);
  }, [roomId]);

  // console.log("typingUser", typingUser);
  return (
    <div className="user-chat">
      {/* Messages Section */}
      {}
      <div className="user-messages" ref={messageEndRef} id="inbox-list">
        <InfiniteScroll
          style={{ overflow: "hidden" }}
          dataLength={messagesReceived?.length}
          next={loadMore}
          hasMore={!!allMessageList?.nextPage}
          loader={isFetching && <SpinnerLoader />}
          inverse={true}
          scrollableTarget="inbox-list"
        >
          {/* No Messages Content */}
          {!allMessageList?.hasNextPage && (
            <div className="no-message-content">
              <img
                src={
                  isValidUrl(profileData?.profileImage)
                    ? profileData?.profileImage
                    : userImage
                }
                alt="Default User"
              />
              <div className="followers-post">
                <div className="follower">
                  <p>{profileData?.followerCount}</p>
                  <span>Followers</span>
                </div>
                <p>|</p>
                <div className="follower">
                  <p>{profileData?.postCount}</p>
                  <span>Posts</span>
                </div>
              </div>
              <h4 className="username">{profileData?.username}</h4>
              {profileData?.location?.name && (
                <div className="location">
                  <LocationChat />
                  <p>{sliceStringAtWord(profileData?.location?.name, 10)}</p>
                </div>
              )}

              <Link
                to={`/profile/${profileData?.id}`}
                className="viewProfileBtn"
              >
                View Profile
              </Link>
            </div>
          )}

          {/* Messages Exist Content */}
          {messagesReceived
            ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
            ?.map((itemMsg, index) => {
              const previousMessage = messagesReceived[index - 1];
              return (
                <InToChat
                  messages={previousMessage}
                  userLogin={user}
                  stateUpdateMsg={setisEditMessage}
                  msg={itemMsg}
                  socket={socket}
                />
              );
            })}
        </InfiniteScroll>
      </div>
      {typingUser?.map(
        (item) =>
          item?._id !== user?._id && (
            <div
              className="d-flex justify-content-start align-items-center mx-4 typing-user"
              style={{ height: "30px" }}
            >
              <div className="typing-name">
                <p>{item?.username} is typing...</p>
              </div>
              {/* <div>
              <Player
                src={LottieTyping}
                className="player"
                loop
                autoplay
                style={{ height: "100px" }}
              />
            </div> */}
            </div>
          )
      )}

      {/* Message Input Box */}
      <SendMessage
        socket={socket}
        loginUser={user}
        messengerUser={messengerUser}
        chatRoomId={roomId}
        editMsg={isEditMessage}
        resetEditMsg={setisEditMessage}
        setTypingUser={setTypingUser}
      />
    </div>
  );
};

export default ViewChat;
