export function sliceStringAtWord(text, maxLength) {
  // If the text is already shorter than the maxLength, return the original text
  if (text.length <= maxLength) {
    return text;
  }

  // Find the nearest space or punctuation within a range around maxLength
  let sliceIndex = maxLength;
  for (let i = maxLength; i < text.length; i++) {
    if (text[i] === " " || text[i] === "," || text[i] === ".") {
      sliceIndex = i;
      break;
    }
  }

  // Slice the text at the chosen index
  const slicedText = text.slice(0, sliceIndex);

  return slicedText;
}

export function sliceBothString(str, length) {
  if (str.length <= length) return str;
  const sliceIndex = str.lastIndexOf(" ", length);
  return str.slice(0, sliceIndex > 0 ? sliceIndex : length) + "...";
}
