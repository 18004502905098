import React, { useState, useEffect } from "react";
import { unstable_useBlocker as useBlocker } from "react-router-dom";


const useUnsavedChanges = ({
  message = "Are you sure you want to discard changes?",
}) => {
  const [isDirty, setDirty] = useState(false);
  // console.log(`isDirty on HOOK`, isDirty);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = message;
      }
    };

    // Adding event listener for beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Cleanup by removing event listener
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty, message]);

  function Prompt() {
    const block = isDirty;

    // useBlocker(() => {
    //   if (block) {
    //     return !window.confirm(message);
    //   }
    //   return false;
    // });

    return <div key={block} />;
  }

  // isDirty here just for testing purpose
  return {
    Prompt,
    setDirty: () => setDirty(true),
    setPristine: () => setDirty(false),
    isDirty,
  };
};

export default useUnsavedChanges;
