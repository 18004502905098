import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./CommentsWrap.scss";
import { Close } from "@utils/defaultImages";
import useMediaQueryHook from "@utils/hooks/useMediaQueryHook";

const CommentsWrap = React.memo(({ open, closeModal, children, style,isMobile }) => {
  const { isMobileSmall} = useMediaQueryHook();
  useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [open]);

  const dynamicClassName = "modal__close modal-login-profile";
  if (!open) return null;
  return ReactDOM.createPortal(
    <>
      <div className="comments-overlay" onClick={closeModal} />
      <div className="modal-comments container" style={style || {}}>
        <div className="row" style={{ height: "100%" , justifyContent: (isMobile || isMobileSmall) && "center" }}>
          <div className="col-lg-11 col-sm-11 col-11 child-sec d-flex" style={{ height: "100%" }}>{children}</div>
          {
            !isMobile &&
            <div className="col-lg-1 col-sm-1 col-1 close-btn" onClick={closeModal}>
              <Close style={{ cursor: "pointer" }} />
            </div>
          }
        </div>
      </div>
    </>,
    document.body
  );
});

export default CommentsWrap;
