import React from "react";
import ContentLoader from "react-content-loader";

const UpComingEventSkeleton = (props) => (
  <ContentLoader
    width={300}
    height={400}
    viewBox="0 0 450 400"
    speed={2}
    backgroundColor="#ababab"
    foregroundColor="#fafafa"
    {...props}
  >
    <rect x="42" y="77" rx="10" ry="10" width="388" height="217" />
    <rect x="43" y="14" rx="4" ry="4" width="271" height="9" />
    <rect x="44" y="44" rx="3" ry="3" width="119" height="6" />
  </ContentLoader>
);



export default UpComingEventSkeleton;
