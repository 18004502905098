import ImgNextGen from "@components/imageOptimisation/ImgNextGen";
import {
  Build_detail,
  BuildGroup,
  Garage,
  GroupCar,
  Performance,
  Performance_Mob,
  Upgrade,
  GroupCarWeb,
  BuildGroupWeb,
  PerformanceWeb,
  Performance_MobWeb
} from "@utils/defaultImages";
import useMediaQueryHook from "@utils/hooks/useMediaQueryHook";
import React from "react";
const GarageUpgrade = () => {
  const { isMobile, isTablet, isMobTab ,isMobileSmall} = useMediaQueryHook();

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 Virtual">
            <h1>Your Garage Just Got an Upgrade</h1>
            <p className="pt-3">
              Share mods and upgrades, and show off your ride in stunning detail
              with the Virtual Garage
            </p>
          </div>
        </div>
        <div className="row py-5">
          <div
            className={`col-lg-6 col-md-12 col-sm-12 col-12 Unparalleled d-flex flex-column justify-content-center ${
              (isMobile || isTablet || isMobTab) && "order-2"
            } ${!(isMobile || isTablet || isMobTab) && "align-items-center"}`}
          >
            <div className="detail">
              <h3>Unparalleled Detail</h3>
              <p>Document every aspect of your build in real time.</p>
            </div>
            <div className="detail pt-5">
              <h3>Unprecedented Collaboration</h3>
              <p>Share ideas and show off your dream build.</p>
            </div>
          </div>
          <div
            className={`col-lg-6 col-md-12 col-sm-12 col-12 Unprecedented ${
              (isMobile || isTablet || isMobTab) && "order-1"
            }`}
          >
            <div className="car-garage">
              {/* <img src={GroupCar} alt="garage" /> */}
              {/* <img src={Garage} alt="garage" />
              <div className="upgrade">
                <img src={Upgrade} alt="garage" />
              </div> */}
               <ImgNextGen
                srcWebp={GroupCarWeb}
                fallback={GroupCar}
                alt="garage"
              />
            </div>
          </div>
        </div>
        <div className="row py-5">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 build-detail">
            <div className="build">
              {/* <img src={BuildGroup} alt="details" /> */}
              <ImgNextGen
                srcWebp={BuildGroupWeb}
                fallback={BuildGroup}
                alt="details"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 performance d-flex flex-column justify-content-evenly ">
            <div className="py-3 px-3">
              <h3>Details Matter. Make them Count.</h3>
              <p>
                Leave no upgrade unaccounted for. From simple interior upgrades
                to major performance mods, your Virtual Garage provides an
                unprecedented level of customization.
              </p>
            </div>

            <div className={`perf ${(isMobile || isTablet || isMobTab || isMobileSmall) && `px-3 mt-2`}`}>
              {isMobile || isTablet || isMobTab ? (
                // <img
                //   src={Performance_Mob}
                //   alt="Performance_Mob"
                //   className="pt-4"
                // />
                <ImgNextGen
                srcWebp={PerformanceWeb}
                fallback={Performance}
                alt="Performance_Mob"
                className="pt-4"
              />
              ) : (
                // <img src={Performance} alt="performance" />
                <ImgNextGen
                srcWebp={PerformanceWeb}
                fallback={Performance}
                alt="performance" 
              />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GarageUpgrade;
