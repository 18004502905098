import { RemoveSpaces } from "./RemoveSpaces";

const emailValidation = (email) => {
  let reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  if (email === "") {
    return {
      valid: false,
      errors: email === "" ? "Please enter your email" : null,
    };
  } else if (email[0] === " " || email.charAt(email.length - 1) === " ") {
    return {
      valid: false,
      errors:
        email[0] === " " || email.charAt(email.length - 1) === " "
          ? "Space at beginning or end is not allowed."
          : null,
    };
  } else if (reg.test(email) === false) {
    return {
      valid: false,
      errors:
        reg.test(email) === false ? "Please enter a valid email address" : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};
const passwordValidation = (password, confirmPassword) => {
  let minNumAlp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\b)(?=.*[@#$%^&*`()-_+={}|~/<>,.;'?!])[A-Za-z\d@#$%^&*`()-_+={}|~/<>,.;'?!\b]{8,}$/;

  if (password === "") {
    return {
      valid: false,
      errors: password === "" ? "Enter a password." : null,
    };
  } else if (
    password[0] === " " ||
    password.charAt(password.length - 1) === " "
  ) {
    return {
      valid: false,
      errors:
        password[0] === " " || password.charAt(password.length - 1) === " "
          ? "Space at beginning or end is not allowed."
          : null,
    };
  } else if (minNumAlp.test(password) === false) {
    return {
      valid: false,
      errors:
        minNumAlp.test(password) === false
          ? "Password doesn't meet the password policy requirements."
          : null,
    };
  } else if (confirmPassword === "") {
    return {
      valid: false,
      errors: confirmPassword === "" ? "Confirm your password." : null,
    };
  } else if (confirmPassword !== password) {
    return {
      valid: false,
      errors:
        confirmPassword !== password
          ? "Those password didn't match.Try again."
          : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};
const loginValidation = (email, password) => {
  if (email === "") {
    return {
      valid: false,
      errors: email === "" ? "Please enter your email" : null,
    };
  } else if (password === "") {
    return {
      valid: false,
      errors: password === "" ? "Please enter your password" : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};
const userNameValid = (name) => {
  let regex = /\s/;
  if (name === "") {
    return {
      valid: false,
      errors: name === "" ? "Please Enter Your Name" : null,
    };
  } else if (regex.test(name) === true) {
    return {
      valid: false,
      errors:
        regex.test(name) === true ? "Please enter a valid username" : null,
    };
  } else if (name.length < 6 || name.length > 30) {
    return {
      valid: false,
      errors:
        name.length < 6 || name.length > 30
          ? "Username should contain 6 to 30 characters"
          : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};
const phoneNumberValid = (phone) => {
  let regex =
    /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/;
  if (phone === "") {
    return {
      valid: false,
      errors: phone === "" ? "Please enter your phone number" : null,
    };
  } else if (phone.length < 10) {
    return {
      valid: false,
      errors:
        phone.length < 10
          ? "Phone number length is too short. It should be atleast 10"
          : null,
    };
  } else if (regex.test(phone) === false) {
    return {
      valid: false,
      errors: regex.test(phone) === false ? "Invalid Input" : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};

const otpValid = (otp, userId) => {
  // if (userId === undefined) {
  //   return {
  //     valid: false,
  //     errors: "Please provide a email.",
  //   };
  // }
  if (otp === "") {
    return {
      valid: false,
      errors: otp === "" ? "Please enter OTP." : null,
    };
  } else if (otp.length < 6) {
    return {
      valid: false,
      errors: "OTP length should be six digits.",
    };
  } else {
    return { valid: true, errors: null };
  }
};

const SignUpNameValid = (name, links) => {
  if (name === "") {
    return {
      valid: false,
      errors: name === "" ? "Enter Name" : null,
    };
  } else if (links === "") {
    return {
      valid: false,
      errors: links === "" ? "Enter Link" : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};
const BioValid = (profileImage) => {
  if (profileImage === null || "") {
    return {
      valid: false,
      errors: profileImage === null ? "Please select your profile image" : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};
const GenderValid = (gender) => {
  if (gender === "") {
    return {
      valid: false,
      errors: gender === "" ? "Please Select Your Gender" : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};
const CountryValid = (country) => {
  if (country === "Country") {
    return {
      valid: false,
      errors: country === "Country" ? "Please Select Your Country" : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};
const forgotPassword = (email) => {
  if (email === "") {
    return {
      valid: false,
      errors: email === "" ? "Please enter your email" : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};
const nameVaid = (firstName, lastName) => {
  let reg = /^[A-Za-z\s]*$/;
  if (firstName === "") {
    return {
      valid: false,
      errors: firstName === "" ? "Please Enter Your First Name" : null,
    };
  } else if (firstName.trim().length == 0) {
    return {
      valid: false,
      errors:
        firstName.trim().length == 0 ? " Only spaces are not allowed." : null,
    };
  } else if (!reg.test(firstName)) {
    return {
      valid: false,
      errors: !reg.test(firstName)
        ? "First name should only contain alphabets."
        : null,
    };
  } else if (lastName === "") {
    return {
      valid: false,
      errors: lastName === "" ? "Please Enter Your Last Name" : null,
    };
  } else if (lastName.trim().length == 0) {
    return {
      valid: false,
      errors:
        lastName.trim().length == 0 ? "Only spaces are not allowed." : null,
    };
  } else if (!reg.test(lastName)) {
    return {
      valid: false,
      errors: !reg.test(lastName)
        ? "Last name should only contain alphabets."
        : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};
const codeValid = (code) => {
  if (code === "") {
    return {
      valid: false,
      errors: code === "" ? "Please enter a verification code" : null,
    };
  } else if (code.length < 4 && code.length > 4) {
    return {
      valid: false,
      errors:
        code.length < 4 && code.length > 4 ? "Code should be 4 digits" : null,
    };
  } else if (code !== "0000") {
    return {
      valid: false,
      errors: code !== "0000" ? "Please enter a valid verification code" : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};
const passwordValidation2 = (password) => {
  let minNumAlp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;
  if (password === "") {
    return {
      valid: false,
      errors: password === "" ? "Please enter your password" : null,
    };
  } else if (minNumAlp.test(password) === false) {
    return {
      valid: false,
      errors:
        minNumAlp.test(password) === false
          ? "Password must contain at least ten characters, alphabets, numbers and special character"
          : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};
const urlValidation = (urlInput) => {
  let reg =
    /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)/;
  if (reg.test(urlInput) === false) {
    return {
      valid: false,
      error: reg.test(urlInput) === false ? "Please enter a valid Url" : null,
    };
  } else if (urlInput.includes(" ")) {
    return {
      valid: false,
      error: urlInput.includes(" ") ? "Spaces are not allowed." : null,
    };
  } else {
    return { valid: true, error: null };
  }
};

const vinValidation = (vin) => {
  if (vin === "") {
    return {
      valid: false,
      errors: vin === "" ? "Please enter vin." : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};
const buildPostValidation = (nickName, vin, vinValidate) => {
  if (nickName === "") {
    return {
      valid: false,
      errors: nickName === "" ? "Please enter build nick name." : null,
    };
  } else if (!nickName.trim().length) {
    return {
      valid: false,
      errors: !nickName.trim().length ? "Only spaces are not allowed." : null,
    };
  } else if (vin !== "" && vinValidate == undefined) {
    return {
      valid: false,
      errors:
        vin !== "" && vinValidate == undefined
          ? "Please click plus button to add Vin on build."
          : null,
    };
  } else if (vin !== "" && vinValidate === false) {
    return {
      valid: false,
      errors:
        vin !== "" && vinValidate === false
          ? "Please enter a valid Vin or remove Vin."
          : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};
const buildNameValidation = (build) => {
  if (build === "" || build === undefined) {
    return {
      valid: false,
      errors:
        build === "" || build === undefined ? "Choose Your Vehicle." : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};

const componentNameValidation = (build) => {
  if (build === undefined) {
    return {
      valid: false,
      errors: build === undefined ? "Choose Your Vehicle." : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};

const postKindValidation = (kind) => {
  if (kind === "") {
    return {
      valid: false,
      errors: kind === "" ? "Choose kind of post." : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};

const ratingValidation = (rating) => {
  if (rating === "" || rating === undefined || rating === null) {
    return {
      valid: false,
      errors:
        rating === "" || rating === undefined || rating === null
          ? "Rate the post to submit your review."
          : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};

const TitleValidation = (title) => {
  if (title === "") {
    return {
      valid: false,
      errors: title === "" ? "Post title is required." : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};

const EventCreate = (title, description, crewId) => {
  let spacetitle = RemoveSpaces(title);
  let spaceDesc = RemoveSpaces(description);
  if (!crewId) {
    return {
      valid: false,
      errors: !crewId ? "Please select a crew" : null,
    };
  } else if (title === "") {
    return {
      valid: false,
      errors: title === "" ? "Title is required" : null,
    };
  } else if (spacetitle === "") {
    return {
      valid: false,
      errors:
        spacetitle === "" ? "Only spaces are not allowed in title." : null,
    };
  } else if (description === "") {
    return {
      valid: false,
      errors: description === "" ? "Description is required." : null,
    };
  } else if (spaceDesc === "") {
    return {
      valid: false,
      errors:
        spaceDesc === "" ? "Only spaces are not allowed in description." : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};

const ChangepasswordValidation = (
  currentPassword,
  password,
  confirmPassword
) => {
  let minNumAlp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\b)(?=.*[@#$%^&*`()-_+={}|~/<>,.;'?!])[A-Za-z\d@#$%^&*`()-_+={}|~/<>,.;'?!\b]{8,}$/;
  if (currentPassword === "") {
    return {
      valid: false,
      errors: password === "" ? "Enter current password." : null,
    };
  } else if (password === "") {
    return {
      valid: false,
      errors: password === "" ? "Enter a password." : null,
    };
  } else if (
    password[0] === " " ||
    password.charAt(password.length - 1) === " "
  ) {
    return {
      valid: false,
      errors:
        password[0] === " " || password.charAt(password.length - 1) === " "
          ? "Space at beginning or end is not allowed."
          : null,
    };
  } else if (minNumAlp.test(password) === false) {
    return {
      valid: false,
      errors:
        minNumAlp.test(password) === false
          ? "Password doesn't meet the password policy requirements."
          : null,
    };
  } else if (confirmPassword === "") {
    return {
      valid: false,
      errors: confirmPassword === "" ? "Confirm your password." : null,
    };
  } else if (confirmPassword !== password) {
    return {
      valid: false,
      errors:
        confirmPassword !== password
          ? "Those password didn't match.Try again."
          : null,
    };
  } else {
    return { valid: true, errors: null };
  }
};

export {
  emailValidation,
  passwordValidation,
  loginValidation,
  userNameValid,
  phoneNumberValid,
  otpValid,
  SignUpNameValid,
  BioValid,
  GenderValid,
  CountryValid,
  forgotPassword,
  nameVaid,
  codeValid,
  passwordValidation2,
  urlValidation,
  vinValidation,
  buildPostValidation,
  buildNameValidation,
  componentNameValidation,
  postKindValidation,
  ratingValidation,
  TitleValidation,
  EventCreate,
  ChangepasswordValidation,
};
