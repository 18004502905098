import React, { useRef } from "react";
import { useState } from "react";
import { ReactComponent as ThumbsUp } from "@assets/carDetailsImgs/thumb-up.svg";
import { ReactComponent as Comments } from "@assets/comments.svg";
import * as Yup from "yup";
import { useFormik } from "formik";
import CommentForm from "../CommentForm/CommentForm";
import ReadMore from "../../../ReadMore";
import { CommentReply } from "@utils/defaultImages";
import { useSelector } from "react-redux";
import SpinnerLoader from "components/Skeleton/SpinnerLoader";
import {
  useCommentOnReplyMutation,
  useLazyListAllRepliesQuery,
  useReactionOnCommentsMutation,
} from "@redux/services/commentsService/commentService";
import ShowReplies from "./subComponents/showReplies";

const Replies = ({ com = {}, isTablet, updateCommentCount }) => {
  const refForReply = useRef(null);
  const CommentsSchema = Yup.object().shape({
    commentText: Yup.string().max(800, "Limit Exceeded!"),
  });

  const formik = useFormik({
    initialValues: {
      commentText: "",
    },
    validationSchema: CommentsSchema,
  });
  const {
    errors,
    touched,
    values,
    handleChange,
    handleReset,
    handleBlur,
    isValid,
    resetForm,
  } = formik;

  // {GET GLOBAL STATE}
  const user = useSelector((state) => state?.auth?.userAuthInfo?.user);
  //

  // {USESTATES}
  const [showReplies, setShowReplies] = useState(false); // State to toggle replies visibility
  const [isLikedReply, setIsLikedReply] = useState(com?.isReacted || false); // Initial state for like status
  const [reactionCount, setReactionCount] = useState(com?.reactionCount || 0); // Initial state for reaction count
  const [replyCount, setReplyCount] = useState(com?.replyCount || 0); // Initial state for reply count
  const [page, setPage] = useState(1);
  //
  // {API CALLS}
  const [listAllReplies, { data: { data: repliesData = [] } = [], isLoading }] =
    useLazyListAllRepliesQuery();

  const [reactionOnComments, { data }] = useReactionOnCommentsMutation();
  const [commentOnReply, { isLoading: load }] = useCommentOnReplyMutation();

  //

  // {FUNCTION CALL}
  const listingReplies = async () => {
    const body = {
      commentId: com?.parentCommentId,
      page: page,
    };
    try {
      const response = await listAllReplies(body).unwrap();
    } catch (error) {}
  };

  const reactionComments = async () => {
    // Optimistically update the state
    const optimisticIsLiked = !isLikedReply;
    const optimisticReactionCount = isLikedReply
      ? reactionCount - 1
      : reactionCount + 1;
    setIsLikedReply(optimisticIsLiked);
    setReactionCount(optimisticReactionCount);

    const body = {
      reactionType: !isLikedReply ? 1 : 0, // 0 for unlike, 1 for like
      modelId: com?.modelId,
      model: com?.model,
      commentId: com?.parentCommentId,
    };
    try {
      const response = await reactionOnComments(body).unwrap();
      if (response?.code !== 200) {
        setIsLikedReply(!optimisticIsLiked);
        setReactionCount(reactionCount);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const addReplies = async (e) => {
    e.preventDefault();
    const body = {
      commentText: values?.commentText,
      modelId: com?.modelId,
      model: com?.model,
      parentCommentId: com?.parentCommentId,
    };
    try {
      const response = await commentOnReply(body).unwrap();
      if (response?.code === 200) {
        resetForm();
        updateCommentCount();
        setReplyCount((prevCount) => prevCount + 1);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  //

  // Toggle replies visibility
  const toggleRepliesVisibility = () => {
    setShowReplies(!showReplies);
    if (repliesData?.length === 0) {
      listingReplies(com._id);
    }
  };

  const handleClickScroll = () => {
    refForReply?.current?.focus();
  };

  return (
    <>
      <div className="description-buttons">
        <ReadMore sliceText={250} isShowMarginTop={true}>
          {com.commentText && com.commentText}
        </ReadMore>
        <div className="actions-btns">
          <div className="likeUnLike">
            <button
              style={{
                border: "none",
                background: "none",
                padding: 0,
                cursor: "pointer",
              }}
              onClick={reactionComments}
              // disabled={isDisable}
            >
              <ThumbsUp fill={isLikedReply ? "white" : "transparent"} />
            </button>
            {reactionCount > 0 && <span>{reactionCount}</span>}
            {/* <p>|</p>
            <button
              style={{
                border: "none",
                background: "none",
                padding: 0,
                cursor: "pointer",
              }}
              disabled={isDisable}
            >
              <ThumbsDown />
            </button> */}
          </div>
          <div className="likeUnLike" onClick={toggleRepliesVisibility}>
            <Comments />
            <span>{replyCount > 0 && replyCount}</span>
          </div>
          {/* <div className="likeUnLike">
            <Share />
            <span>Share</span>
          </div> */}
          {/* <span
          className="pointer"
         
        >
          Reply
        </span> */}
        </div>
      </div>
      {/* {REPLIES DATA SHOW} */}
      {replyCount > 0 && (
        <>
          {showReplies ? (
            <div className="hide-reply" onClick={toggleRepliesVisibility}>
              <p>Hide reply</p>
            </div>
          ) : (
            <div className="view-reply" onClick={toggleRepliesVisibility}>
              <CommentReply />
              {replyCount} {replyCount > 1 ? "replies" : "reply"}
            </div>
          )}
        </>
      )}
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        repliesData?.docs?.length > 0 &&
        showReplies &&
        repliesData?.docs?.map((rep, index) => (
          <ShowReplies item={com} isTablet={isTablet} rep={rep} />
        ))
      )}

      {showReplies && (
        <div className="reply mt-3">
          <div className="actions-btns-reply">
            <CommentForm
              autoFocus={refForReply}
              onSubmit={addReplies}
              formik={formik}
              submitText="Comment"
              disableSubmit={!isValid || values?.commentText === ""}
              isDisableBtn={load}
              onChange={handleChange}
              value={values?.commentText}
              onBlur={handleBlur}
              touched={touched}
              message={errors?.commentText}
              className={"btn-reply"}
            />
          </div>
        </div>
      )}
      {/* <div className="show-more-comments">Show more comments</div> */}
    </>
  );
};

export default Replies;
