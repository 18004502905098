import React, { useEffect, useState } from "react";
import completedFlag from "@assets/completedFlag.svg";
import logo from "@assets/logo-header.svg";

import "./Signup.scss";

import { useNavigate, useParams } from "react-router-dom";
import { Close } from "@utils/defaultImages";
import { useSelector } from "react-redux";

const WaitingComplete = () => {
    const queueNo = useParams()
    const navigate = useNavigate();
    function handleNavigate() {
        navigate(`/general-info`)
    }
    return (
        <div className="waitingListCompletedWrapper">
            {/* <div className="signup-container">
                <div className="signup-wrapper"> */}
            <div className="waiting-list">
                <img src={logo} alt="" className="header-image" />
                <div className="waiting-list-content">
                    <img src={completedFlag} alt="helmetImage" className="helmetImage" />
                    <div className="waitingListDescription">
                        <h2 className="waitingListHeading">Congratulations!</h2>
                        <p className="waitingListPara">You’ve made it through the waitlist.
                            Complete your profile to experience our features firsthand. Join the community and be part of Carvonix!</p>
                    </div>
                    <button className="CompleteButton" onClick={handleNavigate}>
                        Complete Profile
                    </button>
                </div>


            </div>

            {/* </div>
            </div> */}
        </div>
    );
};
export default WaitingComplete;
