import React from "react";
import ButtonLoader from "@components/Loaders/ButtonLoader";

const FollowButton = ({ userInfo, followSuggestAccount, isLoading }) => {
  return (
    <button
      style={{
        border: "none",
        background: "none",
        color: "#387AFF",
      }}
      onClick={() => followSuggestAccount(userInfo?._id)}
      disabled={isLoading}
    >
      {isLoading ? <ButtonLoader /> : "Follow"}
    </button>
  );
};

export default FollowButton;
