import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./wrapperMemberShipModal.scss";
import { useLocation } from "react-router-dom";

const wrapperWarningModal = React.memo(
  ({ open, closeModal, children, style }) => {
    const { pathname } = useLocation();

    // useEffect(() => {
    //   if (open) {
    //     document.body.classList.add("modal-open");
    //   } else {
    //     document.body.classList.remove("modal-open");
    //   }
    //   return () => {
    //     document.body.classList.remove("modal-open");
    //   };
    // }, [open]);

    if (!open) return null;

    return ReactDOM.createPortal(
      <div>
        <div className="overlay" onClick={closeModal} />
        <div className="wrapper-modal modal-warning" style={style || {}}>
          {children}
        </div>
      </div>,
      document.body
    );
  }
);

export default wrapperWarningModal;
