/* global google */
import { useParams } from "react-router";
import React, { useState } from "react";
import {
  StartAndEndDate,
  dateTimeFromNow,
  formatTimeRange,
} from "@utils/DateFormatter";
import { Link } from "react-router-dom";
import NotFound from "@components/NotFound";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import DropMenu from "@components/DropDownProfile/DropMenu";
import EventsFeed from "@components/Skeleton/EventsFeed";
import GoogleMapComponent from "@components/Googlemap/GoogleMapComponent";
import PrivacyComp from "@components/Privacy/PrivacyComp";
import {
  LocationIcon,
  MapEvent,
  CalanderIcon,
  ClockIcon,
  LocationIconLight,
  BitMap,
  UserProfileList,
  SaveIcon,
  ShareIcon,
  LinkIcon,
  ReportIcon,
} from "@utils/defaultImages";
import { isValidUrl } from "@utils/validUrl";
import { useGetEventsByIdQuery } from "@redux/services/crewService/allCrewService";
import UserImg from "@assets/defaultUser.png";
import ReadLikesComments from "./ReadLikesComments";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";
import LikedUsername from "@components/LikedBy/LikedUsername";
import Comments from "@components/Comments/Comments";

window.google = window?.google ? window?.google : {};
const Events = ({ crewData = {}, user = {}, isMobile, isTablet }) => {
  const { crewId } = useParams();
  /**
   * API CALLS
   */
  const { data: { data: { events } = {} } = [], isLoading } =
    useGetEventsByIdQuery(crewId);

  //
  const [openDropdowns, setOpenDropdowns] = useState([]);
  const [page, setPage] = useState(1);

  const toggleDropdown = (commentIndex) => {
    setOpenDropdowns((prevIndex) =>
      prevIndex === commentIndex ? -1 : commentIndex
    );
  };
  const listDropDown = [
    // {
    //   //  userData?.isFollowed ? "Unfollow" :
    //   title: "Follow",
    //   Image: FollowUnFllow,
    // },
    {
      title: "Profile",
      Image: UserProfileList,
      to: "/setting",
    },
    {
      title: "Save",
      Image: SaveIcon,
      to: "/setting",
    },
    {
      title: "Share",
      Image: ShareIcon,
      to: "/setting",
    },
    {
      title: "Link",
      Image: LinkIcon,
      to: "/setting",
    },
    {
      title: "Report",
      Image: ReportIcon,
      to: "/setting",
    },
  ];
  const dropDownTitle = ["Save", "Share", "Link", "Report"];
  const checkPrivacy = !!(
    crewData?.userId !== user?._id &&
    crewData?.privacy &&
    !crewData?.isJoin
  );
  const getMessageForRecurringType = (recurringType) => {
    switch (recurringType) {
      case 1:
        return "Daily";
      case 2:
        return "Weekly";
      case 3:
        return "Two Weeks";
      case 4:
        return "Monthly";
      case 5:
        return "Yearly";
    }
  };
  return (
    <>
      {checkPrivacy ? (
        <PrivacyComp />
      ) : (
        <div class="tab-pane" style={{ marginBottom: "20px" }}>
          {isLoading ? (
            isLoading && (isMobile || isTablet) ? (
              <SpinnerLoader />
            ) : (
              <EventsFeed />
            )
          ) : (
            <>
              {events?.docs?.length > 0 ? (
                <>
                  {events?.docs?.map((event, index) => {
                    // console.log("event-----???", event);
                    
                    return (
                      <>
                        <div
                          className="post-block"
                          key={event?._id}
                          style={{
                            position: "relative",
                            borderBottom:
                              index !== events?.docs?.length - 1
                                ? "1px solid #323334"
                                : "none",
                          }}
                        >
                          <div className="header-crew-post">
                            <div className="user-detail">
                              <Link
                                to={
                                  user?._id === event?.user?._id
                                    ? `/profile`
                                    : `/profile/${event?.user?._id}`
                                }
                              >
                                <img
                                  src={
                                    isValidUrl(event?.user?.profileImage)
                                      ? event?.user?.profileImage
                                      : UserImg
                                  }
                                  alt={event?.user?._id}
                                />
                              </Link>
                              <div>
                                <div className="name">
                                  <Link
                                    to={
                                      user?._id === event?.user?._id
                                        ? `/profile`
                                        : `/profile/${event?.user?._id}`
                                    }
                                    style={{ color: "white" }}
                                  >
                                    <h4>{event?.user?.username}</h4>
                                  </Link>
                                </div>
                                <div className="hour">
                                  <span>
                                    {dateTimeFromNow(event?.createdAt)}
                                  </span>
                                  {event?.postLocation?.name && (
                                    <span className="location">
                                      <LocationIcon />
                                      {event?.postLocation?.name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* <Link
                              role="button"
                              onClick={() => toggleDropdown(index)}
                            >
                              <ThreeDots />
                            </Link> */}
                          </div>

                          <Link
                            className="event-map"
                            to={`/crew-event/detail/${event?._id}`}
                          >
                            {event?.bannerImage ? (
                              <div>
                                <img
                                  src={
                                    isValidUrl(event?.bannerImage)
                                      ? event?.bannerImage
                                      : BitMap
                                  }
                                  className="post-img"
                                />
                              </div>
                            ) : (
                              <GoogleMapComponent
                                detailEventData={event}
                                eventMapHeightType={true}
                              />
                            )}
                            <div className="event-detail">
                              <div className="ev-info">
                                <div className="map-svg">
                                  <MapEvent />
                                </div>
                                <div className="e-title">
                                  {event?.title?.length >= 20
                                    ? `${
                                        sliceStringAtWord(event?.title, 20) +
                                        "..."
                                      }`
                                    : event?.title}
                                  {/* {event?.title} */}
                                  {event.isRecurring &&
                                    event?.recurringType !== null && (
                                      <div className="Recurring">
                                        Recurring{" "}
                                        {getMessageForRecurringType(
                                          event.recurringType
                                        )}
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div className="event-tab">
                                <div className="more-info">
                                  <div className="clm">
                                    <div className="icon">
                                      <CalanderIcon />
                                    </div>
                                    <span>
                                      {StartAndEndDate(
                                        event?.startDateTime,
                                        event?.endDateTime
                                      )}
                                    </span>
                                  </div>
                                  <div className="clm">
                                    <div className="icon">
                                      <ClockIcon />
                                    </div>
                                    <span>
                                      {formatTimeRange(
                                        event?.startDateTime,
                                        event?.endDateTime
                                      )}
                                    </span>
                                  </div>
                                  {!isMobile && (
                                    <div className="clm">
                                      <div className="icon">
                                        <LocationIconLight />
                                      </div>

                                      <span>
                                        {" "}
                                        {event?.location?.name?.length > 20
                                          ? `${
                                              sliceStringAtWord(
                                                event?.location?.name,
                                                20
                                              ) + "..."
                                            }`
                                          : event?.location[0]?.name?.length >
                                            20
                                          ? `${
                                              sliceStringAtWord(
                                                event?.location[0]?.name,
                                                20
                                              ) + "..."
                                            }`
                                          : event?.location?.name ||
                                            event?.location[0]?.name}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Link>
                          {/* {event?.description?.length > 0 && (
                            <>
                              <div className="post-p">
                              <ReadMore sliceText={200}>
                                {event?.description}
                              </ReadMore>
                              </div>
                            </>
                          )} */}

                          {/* COMMENTS SHOW && LIKES */}
                          {/* <ReadLikesComments eventPost={event} /> */}
                          {/* {event?.reactionsCount > 0 && (
                            <LikedUsername
                              reacted={event}
                              reactionCount={event?.reactionsCount}
                              // isLiked={isLiked}
                            />
                          )} */}
                          <Comments
                            post={event}
                            commentsCount={event?.commentsCount}
                            postId={event?._id}
                            // isTablet={isTablet}
                            isComponent={true}
                            isEvent={false}

                          />

                          {openDropdowns === index && (
                            <div
                              style={{
                                position: "absolute",
                                top: "13%",
                                right: "0",
                                backgroundColor: "#18191A",
                                width: "400px",
                                height: "auto",
                                borderRadius: "10px",
                                padding: "20px",
                              }}
                            >
                              <DropMenu
                                listDropDown={listDropDown}
                                post={event}
                                dropDownTitle={dropDownTitle}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    );
                  })}
                </>
              ) : (
                <NotFound message="events" />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Events;
