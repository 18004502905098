import {
  DefaultUserImg,
} from "@utils/defaultImages";
import { isValidUrl } from "@utils/validUrl";
import moment from "moment";
import React from "react";
import DropDown from "./DropDown";

const InToChat = ({ messages, userLogin, stateUpdateMsg, msg, socket }) => {
  const todayDate = new Date();
  const todayISO = moment(todayDate).startOf("day").toISOString();

  /**
   * Format a timestamp to a readable time string.
   */
  const formatDateFromTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  /**
   * Format a timestamp to a readable time string.
   */
  const isSameDay = (currentMessage, diffMessage) => {
    if (!diffMessage || !diffMessage.createdAt) {
      return false;
    }
    const currentCreatedAt = moment(currentMessage.createdAt);
    const diffCreatedAt = moment(diffMessage.createdAt);
    return currentCreatedAt.isSame(diffCreatedAt, "day");
  };
  const isToday = (currentMessage) => {
    if (!currentMessage || !currentMessage.createdAt) {
      return false;
    }
    const messageCreatedAt = moment(currentMessage.createdAt)
      .startOf("day")
      .toISOString();
    return messageCreatedAt === todayISO;
  };

  const showDateDivider = !isSameDay(msg, messages);
  return (
    <>
      {showDateDivider && (
        <div className="dateTime">
          <p style={{ color: isToday(msg) ? "#387AFF" : "#8E8E93" }}>
            {isToday(msg)
              ? "Today"
              : moment(msg?.createdAt).format("MMM D [at] hh:mm A")}
          </p>
        </div>
      )}
      <div className="message-content-exist" key={msg?._id}>
        {/* User Message */}
        <div
          className={`${
            msg?.senderId !== userLogin?._id ? "userMessage" : "selfMessage"
          } `}
        >
          <div className="userImage">
            <img
              src={
                isValidUrl(msg?.sender?.profileImage)
                  ? msg?.sender?.profileImage
                  : DefaultUserImg
              }
              alt="User"
            />
          </div>
          <div className="user-message-text">
            <p className="Chat-date">
              {formatDateFromTimestamp(msg?.createdAt)}
            </p>

            {/* REPLIED STORY DESIGN */}
            {msg?.senderId !== userLogin?._id && msg?.story && (
              <div className="replied-story">
                <span>Replied to your story</span>
                <div className="story-img py-2">
                  <img src={msg?.story?.media?.path} alt="story" />
                </div>
              </div>
            )}
            <div className="d-flex flex-row-reverse justify-content-end gap-1 for-three-dots">
              <div className="message-text mt-1">
                <p>
                  {msg?.messageContent} {msg?.isEdited && <span>(edited)</span>}
                </p>
                {/* {msg?.senderId !== userLogin?._id && (
                      <div className="like-button">
                        <ThumbsUp fill="transparent" />
                      </div>
                    )} */}
              </div>

              {msg?.senderId === userLogin?._id && (
                <div className="messageThreeDots">
                  <DropDown
                    itemMsg={msg}
                    stateUpdateMsg={stateUpdateMsg}
                    socket={socket}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InToChat;
