import { ChatIcon } from "@utils/defaultImages";
import React from "react";
import "./ChatButton.scss";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const ChatButton = ({ selfUserId, userInfoData }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    console.log("userInfoData", userInfoData);
    let roomId;
    if (selfUserId < userInfoData?._id) {
      roomId = `${selfUserId}_${userInfoData?._id}`;
    } else {
      roomId = `${userInfoData?._id}_${selfUserId}`;
    }
    // navigate(`/view-chat/${userInfoData?._id}`);

    return roomId;
  };
  return (
    <Link
      className="d-flex align-items-center justify-content-center mx-3"
      to={`/view-chat/${userInfoData?._id}`}
      state={{ userObj: userInfoData }}
    >
      <button
        className="default-btn-chat"
        // onClick={handleClick}
      >
        <ChatIcon/>
        Chat
      </button>
    </Link>
  );
};

export default ChatButton;
