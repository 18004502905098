import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userAuthInfo: null,
  loginUserData: {},
};

const loginSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoginData(state, action) {
      state.loginUserData = action.payload;
    },
    setAuth(state, action) {
      state.userAuthInfo = action.payload;
    },
    clearAuth(state) {
      state.userAuthInfo = null;
      // state.token = null;
    },
  },
});

export const { setAuth, clearAuth, setLoginData } = loginSlice.actions;
export default loginSlice;
