import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showModel: false,
};

const publicSlice = createSlice({
  name: "loginModel",
  initialState,
  reducers: {
    setLoginModel(state, action) {
      state.showModel = action.payload;
    },
  },
});

export const { setLoginModel } = publicSlice.actions;
export default publicSlice;
