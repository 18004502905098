import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Moderator from "@containers/Crew/CrewPostsPage/components/Moderator.js";
import Members from "@containers/Crew/CrewPostsPage/components/Members.js";

const ModeratorsMembers = () => {
  const [tabSwitch, setTabSwitch] = useState(1);
  const { crewId } = useParams();
  return (
    <>
      <div
        className="crew-list-content-search"
        style={{
          height: "400px",
          overflowY: "scroll",
          gridTemplateColumns: "1fr",
          display: "block",
          padding: "20px",
        }}
      >
        <div className="list-warpper" style={{ padding: "unset",backgroundColor:"transparent" }}>
          <div className="tabs-buttons" style={{ marginBottom: "unset" }}>
            <Link>
              <li
                className={`${tabSwitch == 1 && "active"}`}
                onClick={() => setTabSwitch(1)}
              >
                Moderators
              </li>
            </Link>
            <Link>
              <li
                className={`${tabSwitch == 2 && "active"}`}
                onClick={() => setTabSwitch(2)}
              >
                Members
              </li>
            </Link>
          </div>
          <>
            {tabSwitch == 1 && <Moderator crewId={crewId} />}
            {tabSwitch == 2 && <Members crewId={crewId} />}
          </>
        </div>
      </div>
    </>
  );
};

export default ModeratorsMembers;
