import React from 'react'

const FollowFollowingTab = ({ tabOpen, setTabOpen }) => {
    const tabs = [
      { id: 1, label: "Followers" },
      { id: 2, label: "Following" },
      { id: 3, label: "Followed Vehicles" },
    ]; 
  return (
    <ul class="nav nav-pills mb-3" >
      {tabs?.map((item) => (
        <li class="nav-item" key={item?.id}>
          <button
            class={`nav-link ${item?.id===tabOpen?"active":""}`}
                  onClick={()=>setTabOpen(item?.id)}
          >
            {item?.label}
          </button>
        </li>
      ))}
      
    </ul>
  );
}

export default FollowFollowingTab;