import React, { useEffect, useState } from "react";
import DefaultImgUser from "@assets/defaultUser.png";
import VideoImg from "@assets/video.jpg";

import "./Review.scss";
import { Link, useParams } from "react-router-dom";
import SideBarMain from "@components/SideBarMain";
import { showNotification } from "@utils/utilityFunctions";
import ReadMore from "@components/ReadMore";
import Comments from "@components/Comments/Comments";
import { dateTimeFromNow } from "@utils/DateFormatter";

import ContentLoader from "react-content-loader";
import StarRating from "@components/StarRating";
import Dummy from "@components/Dummy";
const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
};
export default function Review() {
  let user, followUser, getPostsData, getUserData, unFollowUser;
  const { reviewId } = useParams();
  const [post, setPost] = useState({});
  const [userData, setUserData] = useState({});
  const [isFollow, setIsFollow] = useState(false);
  const [isLoadData, setIsLoadData] = useState(true);
  const [isDisableFollowUserBtn, setIsDisableFollowUserBtn] = useState(false);

  const getSinglePost = async () => {
    try {
      const response = await getPostsData(reviewId);
      const checkUser = response?.data?.data?.userId === user?.user?.id;
      setIsFollow(checkUser);
      getUserDataForPost(response?.data?.data?.userId);
      setPost(response?.data?.data);
      if (response?.data?.data) {
        setTimeout(() => {
          setIsLoadData(false);
        }, 2000);
      }
    } catch (error) {
      showNotification(error.message, "error");
      setTimeout(() => {
        setIsLoadData(false);
      }, 2000);
    }
  };
  const getUserDataForPost = async (userId) => {
    try {
      const response = await getUserData(userId);
      setUserData(response?.data?.data?.user);
    } catch (error) {}
  };

  const followUnfollowUser = async () => {
    setIsDisableFollowUserBtn(true);
    try {
      if (userData?.isFollowed) {
        await unFollowUser(post?.userId)
          .then((res) => {
            setUserData((prev) => ({
              ...prev,
              followerCount: userData.followerCount - 1,
              isFollowed: false,
            }));
            setIsDisableFollowUserBtn(false);
          })
          .catch(() => {
            setIsDisableFollowUserBtn(false);
          });
        return;
      }
      if (post?.userId !== undefined) {
        const body = {
          followToUserId: post?.userId,
        };
        await followUser(body)
          .then(() => {
            setUserData((prev) => ({
              ...prev,
              followerCount: userData.followerCount + 1,
              isFollowed: true,
            }));
            setIsDisableFollowUserBtn(false);
          })
          .catch(() => {
            setIsDisableFollowUserBtn(false);
          });
      }
    } catch (error) {}
  };
  useEffect(() => {
    getSinglePost();
  }, []);
  return (
    <div>
      <div className="page-container">
        <SideBarMain />
        {isLoadData ? (
          <ContentLoader
            speed={2}
            width={700}
            height={300}
            viewBox="0 0 700 300"
            backgroundColor="#f3e7e7"
            foregroundColor="#18191A"
          >
            <circle cx="40" cy="60" r="28" height="40px" width="40px" />

            {/* <circle cx="234" cy="96" r="28" /> */}
            <rect x="80" y="50" rx="0" ry="0" width="124" height="10" />
            <rect x="80" y="70" rx="0" ry="0" width="125" height="8" />
          </ContentLoader>
        ) : (
          <div>
            <div className="post-title general-post-header">
              <div>
                <div className="avtar-in">
                  <Link
                    to={
                      user?.user?._id === post.userId
                        ? `/profile`
                        : `/profile/${post.userId}`
                    }
                    className="profile-link"
                  >
                    <img
                      src={
                        post?.user?.profileImage
                          ? post?.user?.profileImage
                          : DefaultImgUser
                      }
                      alt=""
                      className="main-img"
                    />
                  </Link>
                  <div>
                    <div className="name">
                      <Link
                        to={
                          user?.user?._id === post.userId
                            ? `/profile`
                            : `/profile/${post.userId}`
                        }
                        className="profile-link"
                      >
                        {(post?.user?.username && post?.user?.username) || ""}
                      </Link>
                    </div>
                    <div className="d-flex align-items-center">
                      {dateTimeFromNow(post?.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
              {isFollow ? (
                <div className="follow-btn">Edit Profile</div>
              ) : isDisableFollowUserBtn ? (
                <div className="follow-btn">...</div>
              ) : (
              <div className="follow-btn" onClick={followUnfollowUser}>
                  {userData?.isFollowed ? "Following" : "Follow"}
                </div>
              )}
            </div>

            <div className="slider-img">
              {post?.media?.length > 1 ? (
                <div className="borderforAll">
                  {/* <SliderComponent settings={settings}>
                    {post?.media?.map((mediaData, index) => (
                      <React.Fragment key={`${index}-of`}>
                        {mediaData?.type === "1" &&
                        mediaData?.path?.includes("https") ? (
                          <>
                            <img
                              src={mediaData.path}
                              alt=""
                              className="video-img-review"
                            />
                          </>
                        ) : mediaData?.type === "2" &&
                          mediaData?.path?.includes("https") ? (
                          <video
                            src={mediaData?.path}
                            autoPlay={true}
                            height="620"
                            disablePictureInPicture={true}
                            controlsList="nodownload noplaybackspeed noplaybackrate"
                            controls
                            muted={true}
                          />
                        ) : null}
                      </React.Fragment>
                    ))}
                  </SliderComponent> */}
                </div>
              ) : (
                <>
                  {post?.media?.map((mediaData, index) => (
                    <>
                      {mediaData?.type === "1" &&
                      mediaData?.path?.includes("https") ? (
                        <div className="borderforAll">
                          <img
                            src={mediaData?.path ? mediaData.path : VideoImg}
                            alt=""
                            className="video-img-review mt-3"
                          />
                        </div>
                      ) : mediaData?.type === "2" &&
                        mediaData?.path?.includes("https") ? (
                        <div className="borderforAll">
                          {/* <ReactPlayer
                                    playing={played}
                                    controls={true}
                                    url={mediaData.path}
                                    style={{ maxHeight: "600pc" }}
                                    width="45vwvw"
                                    height="60vh"
                                    ref={videoRef}
                                    playsinline={true}
                                  /> */}
                          <video
                            src={mediaData?.path}
                            autoPlay={true}
                            height="620"
                            disablePictureInPicture={true}
                            controlsList="nodownload noplaybackspeed noplaybackrate"
                            controls
                            muted={true}
                          />
                        </div>
                      ) : null}
                    </>
                  ))}
                </>
              )}
              {post?.rating && (
                <div className="mt-2">
                  <StarRating
                    rating={post?.rating}
                    ratingCount={post?.rating}
                    isShow={true}
                  />
                </div>
              )}
              {post?.title && (
                <div className="show-more-title-review">
                  <ReadMore sliceText={200}>{post?.title}</ReadMore>
                </div>
              )}
              {post?.description && (
                <div className="show-more-paragraph mb-3">
                  <ReadMore sliceText={200}>{post?.description}</ReadMore>
                </div>
              )}
            </div>
            <Comments
              isHideCommentIcon={true}
              commentId={post?._id ? post?._id : ""}
              commentsCount={post?.commentsCount}
              isShowForGeneral={post?._id && true}
              posts={post}
              setPosts={setPost}
              reactionDataForPost={{
                isReacted: post?.isReacted,
                id: post?._id,
                reactionsCount: post?.reactionsCount,
              }}
              commentsCountForPost={{
                id: post?._id,
                commentsCount: post?.commentsCount,
              }}
            />
          </div>
        )}
        <div>
          <Dummy
            userData={userData}
            isFollow={isFollow}
            isDisableFollowUserBtn={isDisableFollowUserBtn}
            followUnfollowUser={followUnfollowUser}
          />
        </div>
      </div>
    </div>
  );
}
