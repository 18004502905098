import React, { useState } from "react";
import BannerImg from "@assets/userProfileImgs/default.png";
import "@containers/UserProfile/UserProfile.scss";
import gps from "@assets/gps.png";
import { useParams } from "react-router-dom";
import ReadMore from "@components/ReadMore";
import USerProfileSkeleton from "@components/Skeleton/USerProfileSkeleton";
import { isValidUrl } from "@utils/validUrl";
import UserBuildById from "./subComponents/UserBuildById";
import UserCrewsById from "./subComponents/UserCrewsById";
import { toast } from "react-toastify";
import ButtonLoader from "@components/Loaders/ButtonLoader";
import { useSelector } from "react-redux";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import {
  useFollowProfileMutation,
  useGetUserProfileQuery,
  useRemovefollowProfileMutation,
} from "@redux/services/userProfile/UserProfileService";
import FollowersAndFollowingModal from "@components/Modal/FollowersAndFollowingModal";
import Posts from "@containers/BuildProfile/components/Posts";
import ProfileHeaderById from "./subComponents/ProfileHeaderById";
import { useMediaQuery } from "react-responsive";
import ChatButton from "@components/Button/Chatbtn/ChatButton";

function UserProfileId() {
  /**
   * GET USER ID FROM PARAMS
   */
  const { Id } = useParams();
  //
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const isTablet = useMediaQuery({ maxWidth: 991 });
  const { data: { data: { user: userInfo } = {} } = [], isLoading } =
    useGetUserProfileQuery(Id);

  const [followProfile, { isLoading: followLoad }] = useFollowProfileMutation();
  const [removefollowProfile, { isLoading: UnfollowLoad }] =
    useRemovefollowProfileMutation();

  const [isOpen, setIsOpen] = useState(false);

  const selfUser = useSelector((state) => state?.auth?.loginUserData?._id);

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const followUnFollowProfile = async (userInfo) => {
    const body = {
      followToUserId: userInfo?._id,
    };
    try {
      if (userInfo?.isFollowed) {
        await removefollowProfile(userInfo?._id).unwrap();
      } else {
        await followProfile(body).unwrap();
      }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };
  return (
    <>
      <div>
        {isLoading ? (
          <div className="user-profile-section">
            <img
              src={BannerImg}
              className="banner-img"
              alt={userInfo?.username}
            />
            <div className="container">
              <div className="user-data">
                <USerProfileSkeleton />
              </div>
            </div>
          </div>
        ) : (
          <div className="user-profile-section">
            {isMobile && (
              <ProfileHeaderById
                firstName={userInfo?.firstName}
                lastName={userInfo?.lastName}
              />
            )}
            <img
              src={
                isValidUrl(userInfo?.profileBanner)
                  ? userInfo?.profileBanner
                  : BannerImg
              }
              className="banner-img"
              alt={userInfo?.username}
            />
            <div className="container">
              <div className="user-data">
                <div className="avtar">
                  <img
                    src={
                      isValidUrl(userInfo?.profileImage)
                        ? userInfo?.profileImage
                        : BannerImg
                    }
                    className="avtar-img"
                    alt={userInfo?.username}
                  />
                </div>
                <div className="userInfo">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="name justify-content-between">
                        {userInfo?.firstName &&
                          (userInfo.firstName.length > 15
                            ? sliceStringAtWord(userInfo?.firstName, 15)
                            : userInfo?.firstName)}
                        &nbsp;
                        {userInfo?.lastName &&
                          (userInfo.lastName.length > 15
                            ? sliceStringAtWord(userInfo?.lastName, 15)
                            : userInfo?.lastName)}
                        <div className="d-flex align-items-center">
                          <ChatButton selfUserId={selfUser} userInfoData={userInfo}/>
                          {/* {selfUser !== userInfo?._id && ( */}
                            <button
                              className="follow-button"
                              onClick={() => followUnFollowProfile(userInfo)}
                              disabled={followLoad || UnfollowLoad}
                              style={{
                                backgroundColor:
                                  !(followLoad || UnfollowLoad) &&
                                  userInfo?.isFollowed
                                    ? "transparent" 
                                    : "",
                                border:
                                  !(followLoad || UnfollowLoad) &&
                                  userInfo?.isFollowed
                                    ? "2px solid white"
                                    : "",
                              }}
                            >
                              {followLoad || UnfollowLoad ? (
                                <ButtonLoader />
                              ) : userInfo?.isFollowed ? (
                                "Following"
                              ) : (
                                "Follow"
                              )}
                            </button>
                          {/* )} */}
                        </div>
                      </div>
                      <div className="UserName">
                        @
                        {userInfo?.username
                          ? userInfo?.username
                          : userInfo?.firstName}
                        {userInfo?.location?.name ? (
                          <div className="locationIcon">
                            <img src={gps} alt={userInfo?.location?.name} />
                            <span>
                              {userInfo?.location?.name.length > 18
                                ? `${sliceStringAtWord(
                                    userInfo?.location?.name,
                                    15
                                  )}...`
                                : userInfo?.location?.name}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* <div className="UserLocation">
                      
                          </div> */}
                      </div>
                    </div>

                    <div className="UserBio">
                      <ReadMore
                        sliceText={150}
                        isBreakWord={true}
                        isShowMarginTop={true}
                      >
                        {userInfo?.bio}
                        {/* Fuelled by adrenaline and a love for all things automotive! 🏎️ Join me on the road less traveled as I navigate life in the fast lane. Car enthusiast by day, dreamer by night. */}
                      </ReadMore>
                    </div>
                  </div>

                  <div className="followers">
                    <div className="clm">
                      <div
                        style={{ color: "white" }}
                        onClick={() => {
                          setIsOpen(!isOpen);
                        }}
                      >
                        <div className="number">{userInfo?.followerCount}</div>
                        <p>Followers</p>
                      </div>
                    </div>

                    <div
                      className="clm"
                      onClick={() => handleClickScroll("posts-scroll")}
                    >
                      <div type="button" className="number">
                        {userInfo?.postCount}
                      </div>
                      <p>Posts</p>
                    </div>
                    <div
                      className="clm"
                      onClick={() => handleClickScroll("garage")}
                    >
                      <div type="button" className="number">
                        {userInfo?.buildsCount}
                      </div>
                      <p>Vehicles</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="scrollingBar">
                <button
                  className="scrollingButton garage"
                  onClick={() => handleClickScroll("garage")}
                >
                  Garage
                </button>
                <button
                  className="scrollingButton"
                  onClick={() => handleClickScroll("crews")}
                >
                  Crews
                </button>
                <button
                  className="scrollingButton active"
                  onClick={() => handleClickScroll("posts")}
                >
                  Posts
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="wrap-all">
          <div id="garage">
            <UserBuildById />
          </div>
          <div id="crews">
            <UserCrewsById userDataInfo={userInfo} />
          </div>

          <div id="posts">
            <Posts />
          </div>
        </div>
      </div>

      {isOpen && (
        <FollowersAndFollowingModal
          open={isOpen}
          closeModal={() => setIsOpen(!isOpen)}
        />
      )}
    </>
  );
}

export default UserProfileId;
