import React, { useEffect, useState } from "react";
import { Verified, DefaultImg, SeeMore } from "@utils/defaultImages";
import "./Posts.scss";
import "./CarPorfileInfo.scss";
import ReadMore from "@components/ReadMore";
import { Link, useParams } from "react-router-dom";
import SpecsFeatures from "./SpecsFeatures";
import ModsBuild from "./ModsBuild";
import {
  useGetBuildDataByIdQuery,
  useGetFollowCountOfBuildQuery,
  useLazyGetAllPostsForBuildIdQuery,
  useFollowBuildVehicleMutation,
  useUnfollowBuildVehicleMutation,
} from "@redux/services/userProfile/UserProfileService";
import { isValidUrl } from "@utils/validUrl";
import FeaturedPost from "./FeaturedPost";
import BuildProfileActivity from "@components/BuildProfileActivity";
import SpecsSummary from "./SpecsSummary";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import ButtonLoader from "@components/Loaders/ButtonLoader";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";
import BuildFollowersModel from "@components/Modal/BuildFollowersModel";
import ProfileHeaderById from "@containers/UserProfileById/subComponents/ProfileHeaderById";
export default function CarPorfileInfo({ activeIndex, SetActiveIndex }) {
  // media Query
  const isMobile = useMediaQuery({ maxWidth: 599 });
  const isTablet = useMediaQuery({ minWidth: 767, maxWidth: 1023 });
  const { Id: vehicleId } = useParams();
  const [allPostsForBuild, setAllPostsForBuild] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [followersModel, setFollowersModel] = useState(false);

  /**
   * GET GLOBAL STATE
   */
  const userData = useSelector((state) => state?.auth?.loginUserData);
  /**
   * API CALLS FUNCTION
   */
  const { data: { data: { build: buildData } = {} } = [] } =
    useGetBuildDataByIdQuery(vehicleId);

  const { data: { data: counts } = {} } =
    useGetFollowCountOfBuildQuery(vehicleId);

  const [getAllPostsForBuildId, { data: { data } = {}, isFetching }] =
    useLazyGetAllPostsForBuildIdQuery();
  const [followBuildVehicle, { isLoading }] = useFollowBuildVehicleMutation();
  const [UnfollowBuildVehicle, { isLoading: loading }] =
    useUnfollowBuildVehicleMutation();

  //
  // console.log("buildData321", data);
  /**
   * FUNCTIONS
   */
  const fetchPosts = async (page) => {
    const body = {
      buildId: vehicleId,
      page: page,
    };
    try {
      const res = await getAllPostsForBuildId(body).unwrap();
      setAllPostsForBuild((prevPosts) => [...prevPosts, ...res?.data?.docs]);
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  const handleSeeMore = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    fetchPosts(nextPage);
  };

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const FollowUnfollowVehicle = async () => {
    const body = {
      buildId: vehicleId,
    };
    if (!buildData?.hasFollowedBuild) {
      try {
        await followBuildVehicle(body).unwrap();
      } catch (error) {
        console.log("error", error);
      }
    } else {
      try {
        await UnfollowBuildVehicle(vehicleId).unwrap();
      } catch (error) {
        console.log("error", error);
      }
    }
  };
  //

  /**
   * USEEFFECT
   */
  // useEffect(() => {
  //   allPostBuild();
  // }, [buildData]);

  useEffect(() => {
    fetchPosts(currentPage);
  }, []);

  return (
    <>
      <div className="container-fluid p-0" style={{ position: "relative" }}>
        <div className="row align-items-center" >
          <div className="col-lg-12" >

            <div className="banner-img" style={{ marginTop: "0px" }}>
              <img
                src={
                  isValidUrl(buildData?.bannerImage)
                    ? buildData?.bannerImage
                    : DefaultImg
                }
                alt="img78"
              />

              <div className="short-info">
                <div className="clm" onClick={() => setFollowersModel(true)}>
                  <h3>{counts?.followerCount || "0"}</h3>
                  <p>Followers</p>
                </div>
                <div className="clm">
                  <h3>{buildData?.postCount || "0"}</h3>
                  <p>Posts</p>
                </div>
                <div className="clm">
                  <h3>{buildData?.component || "0"}</h3>
                  <p>Mods</p>
                </div>
              </div>
            </div>

          </div>

        </div>
        {isMobile && (
          <ProfileHeaderById
            firstName={buildData?.user?.firstName}
            lastName={buildData?.user?.lastName}
          />
        )}
      </div>
      <div className="container wrap-container">
        <div className="row">
          <div className="col-lg-8">
            <div className="car-banner">
              <div className="rating-wrapper-btns">
                <div className="owner-ship">
                  <div
                    className="cuurently-legacy"
                    style={{
                      backgroundColor: buildData?.type ? "#387AFF" : "#E56424",
                    }}
                  ></div>
                  <div className="text">
                    <p>
                      {buildData?.type ? "Currently Own" : "Previously Owned"}
                    </p>
                  </div>
                </div>
                {buildData?.userId !== userData?._id && (
                  <div className="action-btns">
                    <button
                      className="btn btn-blue me-3"
                      onClick={FollowUnfollowVehicle}
                    >
                      {isLoading || loading ? (
                        <ButtonLoader />
                      ) : !buildData?.hasFollowedBuild ? (
                        "Follow Vehicle"
                      ) : (
                        "UnFollow Vehicle"
                      )}
                    </button>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="build-name">
                    <h1 className="name">
                      {buildData?.name}
                      <sup>{buildData?.isVarified && <Verified />}</sup>
                    </h1>
                  </div>
                </div>
              </div>

              <div className="tags">
                <span>{buildData?.year?.value || "-"}</span>
                <span>{buildData?.make || "-"}</span>
                <span>{buildData?.model || "-"}</span>
              </div>
              {buildData?.description && (
                <ReadMore sliceText={250}>{buildData?.description}</ReadMore>
              )}
              <div className="sub-section pt-5">
                <div
                  className="anchor-links"
                  onClick={() => handleClickScroll("Summary")}
                >
                  <h6>Summary</h6>
                </div>
                <div
                  className="anchor-links"
                  onClick={() => handleClickScroll("posts")}
                >
                  <h6>Posts</h6>
                </div>
                <div
                  className="anchor-links"
                  onClick={() => handleClickScroll("mods")}
                >
                  <h6>Mods</h6>
                </div>
              </div>
            </div>
            {/* Featured Gallery */}
            <div className={`${isMobile ? "px-2" : ""}`}>
              <FeaturedPost buildData={buildData} />

              {/* Summary */}
              <SpecsSummary buildData={buildData} />
              {/* {Overview} */}
              <div>
                <SpecsFeatures
                  buildData={buildData}
                  handleClickScroll={handleClickScroll}
                  Mobile={isMobile}
                />
              </div>

              {/* {Posts} */}
              {allPostsForBuild?.length > 0 && (
                <div className="posts" id="posts">
                  <h2>Posts</h2>
                  <div className="two-clm-posts mb-4">
                    {allPostsForBuild?.map((post) => {
                      const mediaPath = post?.media[0]?.path;
                      const isValidMediaPath = isValidUrl(mediaPath);
                      const isVideo = mediaPath?.includes("mp4");
                      const thumbnailPath = post?.media[0]?.thumbnail;
                      return (
                        <Link to={`/post/${post?._id}`}>
                          <div className="img-holder">
                            <img
                              src={
                                isValidMediaPath
                                  ? isVideo
                                    ? thumbnailPath
                                    : mediaPath
                                  : DefaultImg
                              }
                              alt={post?.title}
                            />
                            {post?.media?.length === 0 && (
                              <div
                                className="overlay-text"
                                style={{
                                  backgroundColor: buildData?.type
                                    ? "#387AFF"
                                    : "#E56424",
                                }}
                              >
                                {post?.title}
                              </div>
                            )}
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                  {isFetching ? (
                    <SpinnerLoader />
                  ) : (
                    data?.hasNextPage && (
                      <div className="load-more" onClick={handleSeeMore}>
                        See More Posts
                        <SeeMore />
                      </div>
                    )
                  )}
                </div>
              )}

              {/* {Mods} */}
              <div id="mods">
                <ModsBuild
                  activeIndex={activeIndex}
                  SetActiveIndex={SetActiveIndex}
                  type={buildData?.type}
                />
              </div>
            </div>
          </div>
          {!(isMobile || isTablet) && (
            <div className="col-lg-4 user-profile">
              <BuildProfileActivity
                vehicleId={vehicleId}
                buildData={buildData}
              />
            </div>
          )}
        </div>
      </div>
      <BuildFollowersModel
        open={followersModel}
        closeModal={() => setFollowersModel(false)}
      />
    </>
  );
}
