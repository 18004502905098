import React, { memo } from "react";
import { isEmpty } from "lodash";
import ButtonLoader from "@components/Loaders/ButtonLoader";
import Button from "@components/Button/Button";
function Form({
  formik,
  submitText,
  disableSubmit,
  onSubmit,
  children,
  className,
  isDisableBtn,
}) {
  const isDisabled =
    typeof disableSubmit === "undefined"
      ? formik?.isSubmitting || !isEmpty(formik?.errors)
      : disableSubmit;

  return (
    <form onSubmit={onSubmit || formik?.handleSubmit}>
      {children}
      <Button
        type="submit"
        disabled={isDisabled || isDisableBtn}
        className={className}
      >
        {isDisableBtn ? (
          <div
            className="login-btn"
            // style={{padding: "10px 30px", backgroundColor: "#8e8e93", }}
          >
            <ButtonLoader />
          </div>
        ) : (
          submitText
        )}
      </Button>
    </form>
  );
}

export default memo(Form);