import { FooterImg, LandingLogo, FooterImgWeb } from "@utils/defaultImages";
import React from "react";
import "./LandingLayout.scss";
import LandingForm from "@components/LandingForm";
import { useMediaQuery } from "react-responsive";
import ImgNextGen from "@components/imageOptimisation/ImgNextGen";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const LandingFooter = () => {
  const isMobile = useMediaQuery({ maxWidth: 599 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isMobTab = useMediaQuery({ minWidth: 600, maxWidth: 767 });
  const location = useLocation();
  return (
    <>
      {location.pathname !== "/terms-and-conditions" &&
        location.pathname !== "/privacy-policy" && location.pathname !== "/contact-us" && (
          <div className="wrap-all-footer pt-4">
            {(isMobile || isTablet || isMobTab) && <LandingLogo />}
            <div className="container-fluid m-0 p-0">
              <div className="row m-0 p-0">
                <div
                  className={`col-lg-6 col-md-12 col-sm-12 col-12 d-flex flex-column justify-content-center align-items-center ${
                    (isMobile || isTablet || isMobTab) && "order-2"
                  }`}
                >
                  <div className="main-logo text-center">
                    {!(isMobile || isTablet || isMobTab) && <LandingLogo />}

                    <div
                      className={`d-flex gap-3 mt-5 launches ${
                        (isMobile || isTablet || isMobTab) && "mb-4"
                      }`}
                    >
                      {/* <AppleStore />
           <Android /> */}
                      <LandingForm />
                    </div>
                  </div>
                </div>
                <div
                  className={`col-lg-6 col-md-12 col-sm-12 col-12 m-0 p-0 ${
                    (isMobile || isTablet || isMobTab) && "order-1"
                  }`}
                >
                  <div className="testing">
                    {/* <img src={FooterImg} alt="MutlipleView" /> */}
                    <ImgNextGen
                      srcWebp={FooterImgWeb}
                      fallback={FooterImg}
                      alt="MutlipleView"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      {/* TERMS & CONDITION */}
      <div
        className={`condition ${location.pathname === "/contact-us" && "contact-bottom"}`}
        style={{
          backgroundColor:
            location.pathname === "/terms-and-conditions" ||
            location.pathname === "/privacy-policy" || location.pathname === "/contact-us"
              ? "#18191A"
              : "transparent",
        }}
      >
        <div className="container">
          <div class="row">
            <div class="col-lg-6 col-sm-6 col-12">
              <div class={`copyright pt-3 ${isMobile && "text-center"}`}>
                <h4>© 2024 CARVONIX. All Rights Reserved.</h4>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-12">
              <div class={`anchor pt-2  ${isMobile && "text-center"}`}>
                <Link to="/terms-and-conditions">Terms of use</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
                {/* <Link to="/">Cookie Policy</Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingFooter;
