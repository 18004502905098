import React, {useEffect } from "react";
import UserImg from "@assets/defaultUser.png";
import { Link } from "react-router-dom";
import FadingLoader from "@components/Skeleton/CrewSkeleton";
import NotFound from "@components/NotFound";
import { useLazyGetMembersQuery } from "@redux/services/crewService/allCrewService";

const Members = ({ crewId }) => {
  /**
   * API CALL
   */
  const [getMembers, { data: { data } = {}, isLoading }] =
    useLazyGetMembersQuery();
  //
  /**
   * FUNCTIONS
   */
  const members = async () => {
    try {
      await getMembers(crewId).unwrap();
    } catch (error) {
      console.log("error", error);
    }
  };
  //
  /**
   * USEEFFECT
   */
  useEffect(() => {
    members();
  }, []);
  return (
    <div>
      {isLoading ? (
        <FadingLoader />
      ) : data?.docs?.length > 0 ? (
        data?.docs?.map((item) => (
          <div
            className="list-block user-list-block"
            key={item?._id}
            style={{
              marginTop: "22px",
            }}
          >
            <Link
              className="list-block"
              style={{
                borderBottom: "none",
                color: "#fff",
                padding: "unset",
                marginBottom: "unset",
              }}
            >
              <img
                src={item?.profileImage || UserImg}
                alt={item?.username}
                className="main-img"
              />
              <div className="list-info">
                <h4>{item?.username}</h4>
                <p>
                  {item?.firstName} {item?.lastName}
                </p>
              </div>
            </Link>
          </div>
        ))
      ) : (
        <div className="mt-5">
          <NotFound message="Member" />
        </div>
      )}
    </div>
  );
};

export default Members;
