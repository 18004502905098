import React from "react";
import { Link } from "react-router-dom";
import ReadMore from "@components/ReadMore";

const mentionRegex = /@\[(.+?)\]\(.+?\)/g;

const MentionsParser = React.memo(({ text, mentionIds }) => {
  const parseMentions = (text) => {
    return text?.split(mentionRegex).map((part, index) => {
      const userId = mentionIds[Math.floor(index / 3)];

      if (index % 2 === 1) {
        return (
          <Link key={index} to={`/profile/${userId}`}>
            @{part}
          </Link>
        );
      }
      return part;
    });
  };

  const parsedText = parseMentions(text);
  return (
    <>
      {parsedText && (
        <ReadMore
          sliceText={200}
          isBreakWord={true}
          isShowMarginTop={true}
        >
          {parsedText}
        </ReadMore>
      )}
    </>
  );
});

export default MentionsParser;
