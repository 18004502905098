import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
});

export const publicService = createApi({
  reducerPath: "publicService",
  baseQuery,
  endpoints: (builder) => ({
    getPublicComponent: builder.query({
      query: () => ({
        url: `/posts/public/component`,
        method: "GET",
        params: {
          sortBy: "createdAt:desc",
          limit: 4,
        },
      }),
    }),
    registerWaitingUser: builder.mutation({
      query: (value) => ({
        url: `/auth/web/register-visitor`,
        method: "POST",
        body: value,
        headers: {
          "Content-Type": "application/json",
          carvonixToken: process.env.REACT_APP_CARVONIX_TOKEN,
        },
      }),
    }),
    contactToTeam: builder.mutation({
      query: (value) => ({
        url: `/users/contact-us`,
        method: "POST",
        body: value,
        headers: {
          "Content-Type": "application/json",
          carvonixToken: process.env.REACT_APP_CARVONIX_TOKEN,
        },
      }),
    }),
  }),
});

export const {
  useGetPublicComponentQuery,
  useRegisterWaitingUserMutation,
  useContactToTeamMutation,
} = publicService;
