import React from "react";
import CommentsWrap from "./commentsWrapper/CommentsWrap";
import CommentsList from "@components/Comments/CommentsList";
import { useMediaQuery } from "react-responsive";

const CommentsPopUp = React.memo(
  ({
    openPopUp,
    closeModal,
    userData,
    commentList,
    showMore,
    setAllComments,
    commentsCount,
    setCommentsCount,
    postIsLiked,
    setPostIsLiked ,
    PostReactionCount ,
    setPostReactionCount,
    commentLoading,
    OnUpdate = () => {},
    loaderOnUpdate,
  }) => {
    const isTablet = useMediaQuery({minWidth:"700px",maxWidth:"1100px"})
    const isMobile = useMediaQuery({maxWidth:"700px"})
    return (
      <div id="modal">
        <CommentsWrap
          open={openPopUp}
          closeModal={closeModal}
          isMobile={isMobile}
          style={{
            top: "45%",
            transform: "translateY(-45%)",
            left: 0,
            right: 0,
            margin: "0 auto",
            height: !isMobile&&"100%",
            maxHeight:!isMobile &&(isTablet? "650px": "750px"),
            zIndex:"9999999",
          }}
        >
          <CommentsList
            userData={userData}
            commentList={commentList}
            showMore={showMore}
            OnUpdate={OnUpdate}
            loaderOnUpdate={loaderOnUpdate}
            setAllComments={setAllComments}
            commentsCount={commentsCount}
            setCommentsCount={setCommentsCount}
            postIsLiked={postIsLiked}
            setPostIsLiked={setPostIsLiked} 
            PostReactionCount={PostReactionCount} 
            setPostReactionCount={setPostReactionCount}
            commentLoading={commentLoading}
          />
        </CommentsWrap>
      </div>
    );
  }
);

export default CommentsPopUp;
