import logo from "@assets/logo-header.svg";
import "./LandingLayout.scss";
import { Link } from "react-router-dom";
import { Google, IOS } from "@utils/defaultImages";
function LandingHeader() {
  return (
    <>
      <header className="landing-header show" style={{padding:"20px 0"}}>
        <div className={`container headerContentLanding`}>
          <div className="row m-0 p-0 align-items-center">
            <div className="col-lg-3 col-md-4 col-sm-4 col-4 logo">
              <Link to="/" className="headerLogo">
                <img src={logo} alt="Carvonix Logo" />
              </Link>
            </div>

            {/* <div className="col-lg-9 col-md-8 col-sm-8 col-8">
              <div className="side-rightbar" style={{ textAlign: "right" }}>
                <h5>Download the CARVONIX App</h5>
                <div className="Social-platform">
                  <Google />
                  <IOS />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </header>
      
    </>
  );
}
export default LandingHeader;
