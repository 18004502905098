import React from "react";
import ViewMessages from "@containers/Messenger/components/ViewMessages";
import "./Messenger.scss";
import MessengerHeader from "./components/MessengerHeader";
const Messenger = () => {
  return (
    <>
      <div className="Messenger">
        <MessengerHeader />
        <ViewMessages />
      </div>
    </>
  );
};

export default Messenger;
