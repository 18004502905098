import React from "react";
import WrapperModal from "./WrapperModal";
import ModeratorsMembers from "./ModeratorsMembers";

const ModeratorsMemberModal = React.memo(({ open, closeModal }) => {
  return (
    <div id="modal">
      <WrapperModal
        open={open}
        closeModal={closeModal}
        style={{
          top: "50%",
          transform: "translateY(-50%)",
          left: 0,
          right: 0,
          margin: "0 auto",
          backgroundColor: "#121313",
        }}
      >
        <div>
          <ModeratorsMembers />
        </div>
      </WrapperModal>
    </div>
  );
});

export default ModeratorsMemberModal;
