import React from "react";
import { AnchorIcon } from "@utils/defaultImages";
import "./SpecsFeatures.scss";
import {
  generateSpecsFromKeys,
  transformKey,
} from "@utils/generateSpecsFromKeys";

const SpecsFeatures = ({ buildData, handleClickScroll = {}, Mobile }) => {
  const Specifications = generateSpecsFromKeys(buildData, true);

  return (
    <React.Fragment>
      <div className="Overview">
        <div className="features">
          <div className="specs">
            <h1>Overview</h1>
          </div>
          <div className="anchor" onClick={() => handleClickScroll("mods")}>
            <h6 style={{ color: buildData?.type ? "#387AFF" : "#E56424" }}>
              View All Mods
            </h6>
            <AnchorIcon stroke={buildData?.type ? "#387AFF" : "#E56424"} />
          </div>
        </div>
        <div className="row">
          {Specifications?.map((item, index) => (
            <div className="col-lg-6" key={index}>
              <div
                className={`msrp ${
                  Mobile && index % 2 !== 0
                    ? "highlight"
                    : !Mobile &&
                      index >= 2 &&
                      (index - 2) % 4 < 2 &&
                      "highlight"
                }`}
              >
                <div className="price">
                  {buildData?.type ? (
                    <img
                      src={require(`assets/buildOverview/` +
                        item?.key +
                        `.svg`)}
                      alt={`${item?.key} icon`}
                    />
                  ) : (
                    <img
                      src={require(`assets/buildOverview/legacy/` +
                        item?.key +
                        `.svg`)}
                      alt={`${item?.key} icon`}
                    />
                  )}

                  <div className="text">
                    <div className="moderator">
                      <span>{transformKey(item?.key)}</span>
                      {item?.greenDot_torque && <div className="online"></div>}
                    </div>
                    {item?.value ? (
                      <p>
                        {item?.value}{" "}
                        {item?.unit === "height" ? <sup>"</sup> : item?.unit}
                      </p>
                    ) : (
                      "---"
                    )}
                  </div>
                </div>
                {item?.stockValue && (
                  <div className="mods-msrp">
                    <span>Stock {item?.key}</span>
                    <p>
                      {item?.stockValue}{" "}
                      {item?.unit === "height" ? <sup>"</sup> : item?.unit}
                    </p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SpecsFeatures;
