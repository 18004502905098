import React from "react";
import TextImg from "@assets/text.png";
import "./Posts.scss";
import { ReactComponent as ArrowRight } from "@assets/arrow-down.svg";
import { useParams } from "react-router";
import { capitalizeAndAddSpace } from "@utils/capitalizeAddSpace";
import PostsHeaderLoader from "@components/Skeleton/PostsHeaderLoader";
import NotFound from "@components/NotFound";
import { Link } from "react-router-dom";
import { dateTimeFromNow } from "@utils/DateFormatter";
import { useGetAllFilterCategoryQuery } from "@redux/services/crewService/allCrewService";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";
import { sliceStringAtWord } from "@utils/sliceStringWord";

const AllPosts = ({ isMobile, isTablet }) => {
  const { crewId } = useParams();
  /**
   * API CALLS
   */
  const { data: { data: selectFilterResult } = [], isLoading } =
    useGetAllFilterCategoryQuery(crewId);
  //

  return (
    <React.Fragment>
      <div className="posts-header">
        {isLoading ? (
          isLoading && (isMobile || isTablet) ? (
            <SpinnerLoader />
          ) : (
            Array.from({ length: 2 }).map((_, index) => (
              <PostsHeaderLoader key={index} />
            ))
          )
        ) : selectFilterResult?.length > 0 ? (
          selectFilterResult?.map((filter, index) => {
            // console.log("filter", filter);
            return (
              <>
                <div className="all-post">
                  <h1>{capitalizeAndAddSpace(filter?.categoryName)}</h1>
                </div>
                {filter?.posts?.map((nestedFilter) => {
                  // console.log("nestedFilter===>>", nestedFilter);
                  return (
                    <Link
                      className="wrape-posts"
                      to={`/post/${nestedFilter?._id}`}
                    >
                      <div className="technical-posts">
                        <div className="main-data">
                          <div className="wrap-innerData">
                            <div
                              className="post-img"
                              style={{
                                opacity: nestedFilter?.type === 3 && "0.6",
                              }}
                            >
                              {nestedFilter?.type !== 3 ? (
                                <img
                                  src={
                                    nestedFilter?.media?.length > 0
                                      ? nestedFilter?.media[0]?.path
                                      : TextImg
                                  }
                                  alt={filter?.categoryName}
                                />
                              ) : (
                                <video
                                  src={nestedFilter?.media[0]?.path}
                                  alt={filter?.categoryName}
                                />
                              )}
                              {nestedFilter?.type === 3 && (
                                <div className="video-overlay">
                                  <span className="video-icon">▶️</span>
                                </div>
                              )}
                            </div>
                            <div className="wrap-innerposts">
                              <div className="username">
                                <div className="posted">
                                  <h6>
                                  {`${sliceStringAtWord(filter?.categoryName, 5)}`}
                                    {/* {
                                    capitalizeAndAddSpace(
                                      filter?.categoryName
                                    )
                                    } */}
                                  </h6>
                                </div>
                                <div className="width"></div>
                                {nestedFilter?.user?.username && (
                                  <div className="posted-by">
                                    <p>
                                      Posted by{" "}
                                      <strong>
                                        {nestedFilter?.user?.username}
                                      </strong>
                                    </p>
                                  </div>
                                )}

                                <div className="width"></div>
                                <div className="posted-by">
                                  <p>
                                    {dateTimeFromNow(nestedFilter?.createdAt)}
                                  </p>
                                </div>
                              </div>
                              <div className="title-inner">
                                <h3>
                                  {nestedFilter?.title
                                    ? nestedFilter?.title
                                    : nestedFilter?.description}
                                </h3>
                              </div>
                              {nestedFilter?.commentsCount > 0 && (
                                <div className="replies-count">
                                  <p>{nestedFilter?.commentsCount} comments</p>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="arrow">
                            <ArrowRight />
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </>
            );
          })
        ) : (
          <NotFound message="Post" />
        )}
      </div>
    </React.Fragment>
  );
};

export default AllPosts;
