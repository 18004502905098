import React, { useEffect, useState } from "react";
import ViewMessages from "@containers/Messenger/components/ViewMessages";
import { useMediaQuery } from "react-responsive";
import ViewChat from "@containers/Messenger/components/ViewChat";
import "./Messenger.scss";
import MessengerHeader from "./components/MessengerHeader";
import { useLocation, useNavigate, useParams } from "react-router";

const ChatBox = ({ socket }) => {
  // media Query
  const { id } = useParams();
  const location = useLocation();
  const user = location?.state?.userObj || {};
    console.log('location---->>>>>', location)

  const isMobile = useMediaQuery({ maxWidth: 599 });
  const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 1023 });
  // const [isViewChat, setViewChat] = useState(true)
  // const navigate= useNavigate();

  // const handleChat = (user) => {
  //   navigate(`/view-chat/${user?._id}`,{
  //     state:{user:user}
  //   });
  //   setViewChat(true);
  // }

  return (
    <>
      <div className="Messenger">
        <MessengerHeader messengerUser={user} />
        <ViewChat messengerUser={user} socket={socket} />
      </div>
      {!isTablet && !isMobile && (
        <div className="Messenger">
          <ViewMessages />
        </div>
      )}
    </>
  );
};

export default ChatBox;
