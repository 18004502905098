import React, { useCallback, useEffect, useState } from "react";
import "@containers/UserProfile/subComponents/TabSwitch/TabSwitch.scss";
import { Link, useParams } from "react-router-dom";
import { isValidUrl } from "@utils/validUrl";
import FadingLoader from "@components/Skeleton/CrewSkeleton";
import InfiniteScroll from "react-infinite-scroller";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";
import { toast } from "react-toastify";
import { YellowLockIcon, DefaultUserPng } from "@utils/defaultImages";
import NotFound from "@components/NotFound";
import { useLazyGetUserModeratorCrewsQuery } from "@redux/services/userProfile/UserProfileService";

const MemberOfList = () => {
 
  const [page, setPage] = useState(1);
  const { userId } = useParams();

  const [
    listAllModerator,
    { data: { data: crewmoderator } = {}, isLoading, isFetching },
  ] = useLazyGetUserModeratorCrewsQuery();

  const loadMore = useCallback(() => {
    if (!isFetching && crewmoderator?.nextPage) {
      setPage(crewmoderator?.nextPage);
    }
  }, [isFetching, crewmoderator?.nextPage]);

  const showModeratorList = async () => {
    const body = {
      page: page,
      userId: userId,
    };
    try {
      const response = await listAllModerator(body).unwrap();
      setPage((prev) => prev + 1);
    } catch (error) {
      toast.error(error?.crewmoderator?.message);
    }
  };

  useEffect(() => {
    showModeratorList();
  }, []);

  return (
    <div className="crew-list-content">
      {isLoading ? (
        <div className="list-block position-relative">
          <div className="container-fluid p-0">
            <FadingLoader />
          </div>
        </div>
      ) : (
        <InfiniteScroll
          dataLength={crewmoderator?.[0]?.length}
          next={loadMore}
          hasMore={!!crewmoderator?.[0]?.hasNextPage}
          loader={<SpinnerLoader />}
          endMessage={<p style={{ textAlign: "center" }}>No more results</p>}
        >
          {crewmoderator?.[0]?.value?.length > 0 ? (
            crewmoderator?.[0]?.value?.map((item) => (
              <div className="list-block" key={item?.crew?._id}>
                <Link
                  to={`/profile/${item?.crew?._id}`}
                  className="profile-link"
                >
                  <img
                    src={
                      isValidUrl(item?.crew?.bannerImage)
                        ? item?.crew?.bannerImage
                        : DefaultUserPng
                    }
                    alt={item?.crew?.name}
                    className="main-img"
                  />
                </Link>
                <Link
                  to={
                    item?.crew?._id === item?.crew?._id
                      ? `/profile`
                      : `/profile/${item?.crew?._id}`
                  }
                  className="profile-link"
                >
                  <div className="list-info">
                    <h4 style={{ whiteSpace: "nowrap" }}>
                      {item?.crew?.name?.length > 15
                        ? `${item?.crew?.name?.slice(0, 15)}...`
                        : item?.crew?.name}
                    </h4>
                    {item?.crew?.privacy === 1 && <YellowLockIcon />}
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <NotFound message="Member" />
          )}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default MemberOfList;
