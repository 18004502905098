import React from "react";
import WrapperModal from "./WrapperModal";
import Reset from "@containers/Auth/ForgotPassword/Reset";

const ResetModal = React.memo(({ open, closeModal, navigateVal }) => {
  return (
    <div id="modal">
      <WrapperModal
        open={open}
        closeModal={closeModal}
        style={{
          top: "50%",
          transform: "translateY(-50%)",
          left: 0,
          right: 0,
          margin: "0 auto",
          minHeight: "700px", 
          maxWidth:"100%" 
        }}
      >
        <Reset closeModal={closeModal} open={open} />
      </WrapperModal>
    </div>
  );
});

export default ResetModal;
