import React, { useEffect, useState } from "react";
import UserImg from "@assets/defaultUser.png";
import { Link } from "react-router-dom";
import ButtonLoader from "../Loaders/ButtonLoader";
import { isValidUrl } from "@utils/validUrl";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { useMediaQuery } from "react-responsive";
import {
  useAllReactionPostQuery,
  useFollowUserModalMutation,
  useRemovefollowUserMutation,
} from "@redux/services/reactionService/reactionService";
import { toast } from "react-toastify";
import FadingLoader from "../Skeleton/CrewSkeleton";
import { useSelector } from "react-redux";

const Followlist = ({ modelId }) => {
  /**
   * GET GLOBAL STATE
   */
  const user = useSelector((state) => state?.auth?.loginUserData);
  // media Query
  const isMobile = useMediaQuery({ maxWidth: 599 });
  const isTablet = useMediaQuery({ minWidth: 767, maxWidth: 1023 });
  const isTabletForSmallScreen = useMediaQuery({
    minWidth: 600,
    maxWidth: 766,
  });

  // useStates
  const [loadingState, setLoadingState] = useState({});
  const [followState, setFollowState] = useState({});

  //
  // {API CALLING}
  const {
    data: { data: { postReactionRecords: { docs } = [] } = [] } = [],
    isLoading,
  } = useAllReactionPostQuery(modelId);
  const [followUserModal] = useFollowUserModalMutation();
  const [removefollowUser] = useRemovefollowUserMutation();
  /**
   * USEEFFECT
   */
  useEffect(() => {
    if (docs) {
      const initialFollowState = {};
      docs?.forEach((item) => {
        initialFollowState[item?.userId] = item?.followingStatus === 2;
      });
      setFollowState(initialFollowState);
    }
  }, [docs]);
  // {FUNCTIONS}
  const followUnFollowAccount = async (item) => {
    const body = {
      followToUserId: item?.userId,
    };
    setLoadingState((prev) => ({ ...prev, [item?.userId]: true }));
    setFollowState((prev) => ({
      ...prev,
      [item?.userId]: !prev[item?.userId],
    }));
    try {
      if (followState[item?.userId]) {
        await removefollowUser(item?.userId).unwrap();
      } else {
         await followUserModal(body).unwrap();
      }
      setLoadingState((prev) => ({ ...prev, [item?.userId]: false }));
    } catch (error) {
      toast.error(error?.data?.message);
      setLoadingState((prev) => ({ ...prev, [item?.userId]: false }));
      setFollowState((prev) => ({
        ...prev,
        [item?.userId]: !prev[item?.userId],
      }));
    }
  };
  return (
    <>
      {isLoading ? (
        <FadingLoader />
      ) : (
        docs?.map((item) => (
          <div
            className="list-block user-list-block"
            key={item?.userId}
            style={{
              marginTop: "22px",
            }}
          >
            <Link
              className="list-block"
              to={`/profile/${item?.userId}`}
              style={{
                borderBottom: "none",
                color: "#fff",
                padding: "unset",
                marginBottom: "unset",
              }}
            >
              <img
                src={
                  isValidUrl(item?.user?.profileImage)
                    ? item?.user?.profileImage
                    : UserImg
                }
                alt={item?.user?.username}
                className="main-img"
              />
              <div className="list-info">
                <h4>
                  {item?.user?.username.length > 15
                    ? sliceStringAtWord(
                        item?.user?.username,
                        isTablet || isTabletForSmallScreen || isMobile ? 5 : 15
                      )
                    : item?.user?.username}
                </h4>
                <p>
                  {item?.user?.firstName.length > 15
                    ? sliceStringAtWord(
                        item?.user?.firstName,
                        isTablet || isTabletForSmallScreen || isMobile ? 5 : 15
                      )
                    : item?.user?.firstName}
                  {item?.user?.lastName.length > 15
                    ? sliceStringAtWord(
                        item?.user?.lastName,
                        isTablet || isTabletForSmallScreen || isMobile ? 5 : 15
                      )
                    : item?.user?.lastName}
                </p>
              </div>
            </Link>
            {item?.userId !== user?._id && (
              <button
                className="default-btn"
                style={{
                  backgroundColor: followState[item?.userId]
                    ? "#323334"
                    : "#387aff",
                  borderColor: followState[item?.userId]
                    ? "#8E8E93"
                    : "#387aff",
                }}
                onClick={() => followUnFollowAccount(item)}
                disabled={loadingState[item?.userId]}
              >
                {loadingState[item?.userId] ? (
                  <>
                    <ButtonLoader />
                  </>
                ) : followState[item?.userId] ? (
                  "Following"
                ) : (
                  "Follow"
                )}
              </button>
            )}
          </div>
        ))
      )}
    </>
  );
};

export default Followlist;
