import React from "react";

import ContentLoader from "react-content-loader";

const HeaderFilterLoader = () => (
  <ContentLoader
    viewBox="0 0 400 10"
    backgroundColor="#ababab"
    foregroundColor="#fafafa"
    speed={2}
  >
    {/* Rectangle for "All" filter */}
    <rect x="0" y="0" rx="3" ry="3" width="40" height="10" />

    {/* Rectangles for dynamic filters */}
    <rect x="60" y="0" rx="3" ry="3" width="40" height="10" />
    <rect x="120" y="0" rx="3" ry="3" width="40" height="10" />
    <rect x="180" y="0" rx="3" ry="3" width="40" height="10" />
    {/* Add more rectangles based on your design */}
  </ContentLoader>
);
export default HeaderFilterLoader;
