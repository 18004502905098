import React, { useState, useEffect } from "react";
import "./Posts.scss";
import BannerImg from "@assets/userProfileImgs/default.png";
import { Link, useParams } from "react-router-dom";
import { useLazyGetUserPostsQuery } from "@redux/services/userProfile/UserProfileService";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";
import NotFound from "@components/NotFound";
import { CarBgForVideo, MultiMedia, SeeMore } from "@utils/defaultImages";
import CustomNotFound from "@components/CustomNotFound";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";

export default function Posts() {
  const { Id: userId } = useParams();

  const [pageCountForPost, setPageCountForPost] = useState(1);
  const [allPosts, setAllPosts] = useState([]);

  const [getAllPost, { data: { data } = {}, isFetching }] =
    useLazyGetUserPostsQuery();

  // Total number of pages (placeholder if not provided by API)
  const totalPageForPosts = data?.totalPages || 1;

  // Effect to update the allPosts state when new data is fetched
  useEffect(() => {
    getPostsData();
  }, [pageCountForPost, userId]);

  // Function to fetch more posts
  const getPostsData = async () => {
    const body = {
      pageCountForPost: pageCountForPost,
      userId: userId,
    };
    try {
      const res = await getAllPost(body).unwrap();
      setAllPosts((prevPosts) =>
        pageCountForPost === 1
          ? res?.data?.docs
          : [...prevPosts, ...res?.data?.docs]
      );
    } catch (error) {
      console.log(error);
    }
  };
  // console.log("allPosts---??", allPosts);
  return (
    <div>
      <h2 id="posts-scroll" className="heading-posts-section px-3">
        Posts
      </h2>
      {allPosts?.length > 0 ? (
        <div className="container px-3">
          <div className="posts-data">
            {allPosts?.map((post) => (
              <div className="img-holder" key={post._id}>
                {post?.media?.length > 0 ? (
                  <>
                    {post.media[0].type === "2" && (
                      <i className="fa-solid fa-play play-btn"></i>
                    )}
                    {post.media[0].type === "1" && post.media.length > 1 && (
                      <MultiMedia className="play-btn" />
                    )}
                    <Link
                      to={
                        post.format === 2 && post.componentStatsId
                          ? `/how-to-install/${post.componentStatsId}`
                          : [1, 2, 3].includes(post.type)
                          ? `/post/${post._id}`
                          : "/post"
                      }
                    >
                      {/* <img
                        src={
                          post.media[0].type === "2"
                            ? CarBgForVideo
                            : post.media[0].type === "1"
                            ? post.media[0].path
                            : BannerImg
                        }
                        alt="posts"
                      /> */}
                      <AsyncImage
                        key={post._id}
                        src={
                          post.media[0].type === "2"
                            ? CarBgForVideo
                            : post.media[0].type === "1"
                            ? post.media[0].path
                            : BannerImg
                        }
                        loading="lazy"
                        Transition={Blur}
                        loader={
                          <img
                            src={
                              post.media[0].type === "2"
                                ? CarBgForVideo
                                : post.media[0].type === "1"
                                ? post.media[0].path
                                : BannerImg
                            }
                            style={{ filter: "blur(25px)" }}
                          />
                        }
                      />
                    </Link>
                  </>
                ) : (
                  <Link
                    to={
                      post.format === 2 && post.componentStatsId
                        ? `/how-to-install/${post.componentStatsId}`
                        : [1, 2, 3].includes(post.type)
                        ? `/post/${post._id}`
                        : "/post"
                    }
                  >
                    <div className="overlay-text">
                      <span>{post?.title}</span>
                    </div>
                  </Link>
                )}
              </div>
            ))}
          </div>

          {isFetching ? (
            <SpinnerLoader />
          ) : (
            totalPageForPosts > pageCountForPost && (
              <div
                className="load-more"
                onClick={() => setPageCountForPost(data?.nextPage)}
              >
                See More Posts
                <SeeMore />
              </div>
            )
          )}
        </div>
      ) : (
        <CustomNotFound message="There are no posts" />
      )}
    </div>
  );
}
