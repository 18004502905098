import React from "react";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import "./PartsSlider.scss";
// import SliderComponent from "@components/Slider/Slider";
import StarRating from "@components/StarRating";
import { Link, useNavigate } from "react-router-dom";
import ReadMore from "@components/ReadMore";
import { isValidUrl } from "@utils/validUrl";
import InstagramFeedMain from "@components/Skeleton/InstagramFeedMain";
import {
  BackArrow,
  DefaultUserPng,
  ThreeDots,
  VideoImg,
  LinkImg,
} from "@utils/defaultImages";
import Comments from "@components/Comments/Comments";
import MediaShow from "@containers/MainFeed/subComponents/mediaShow";

export default function PartsSlider({ reviewData, isLoading }) {
  const navigate = useNavigate();
  return (
    <div>
      {isLoading ? (
        <InstagramFeedMain />
      ) : (
        <>
          <div className="post-title">
            <div className="component-page">
              <div className="back-button" onClick={() => navigate(-1)}>
                <BackArrow />
              </div>
              <div className="username-component">
                <div>
                  <div className="avtar-in">
                    <div className="profile-link" style={{ flex: "0 0 60px" }}>
                      <div className="car-image">
                        <img
                          src={
                            isValidUrl(reviewData?.build?.bannerImage)
                              ? reviewData?.build?.bannerImage
                              : DefaultUserPng
                          }
                          alt={reviewData?.build?.year?.value}
                          className="main-img"
                          style={{ width: "40px", height: "40px" }}
                        />
                        <Link
                        // to={`/user/profile/${component?.userId}`}
                        >
                          <div className="user-image">
                            <img
                              src={
                                isValidUrl(reviewData?.user?.profileImage)
                                  ? reviewData?.user?.profileImage
                                  : DefaultUserPng
                              }
                              // alt={component?.user?.username}
                              className="main-img"
                              style={{ width: "40px", height: "40px" }}
                            />
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div className="name" style={{ wordBreak: "unset" }}>
                        <div className="profile-link">
                          {reviewData?.build?.name}
                          {/* {component?.user?.lastName} */}
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        {reviewData?.user?.username?.length >= 7
                          ? `${
                              sliceStringAtWord(reviewData?.user?.username, 7) +
                              "..."
                            }`
                          : reviewData?.user?.username}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="three-dots"
                // onClick={() => setOpenDropdowns(!openDropdowns)}
              >
                <ThreeDots />
              </div>
            </div>
            {/* {openDropdowns && (
              <div
                style={{
                  position: "absolute",
                  top: "90%",
                  right: "0",
                  backgroundColor: "#18191A",
                  width: "400px",
                  height: "auto",
                  borderRadius: "10px",
                  padding: "20px",
                  zIndex: "1",
                }}
              >
                <DropMenu
                  listDropDown={listDropDown}
                  dropDownTitle={dropDownTitle}
                  post={reviewData}
                />
              </div>
            )} */}
          </div>

          <div className="slider-img">
            <>
              {reviewData?.media?.length > 1 ? (
                // <div className="borderforAll">
                //   <SliderComponent
                //   settings={settings}
                //   >
                //     {reviewData?.media?.map((mediaData, index) => (
                //       <React.Fragment key={`${index}-of`}>
                //         {mediaData?.type === "1" &&
                //         mediaData?.path?.includes("https") ? (
                //           <>
                //             <img
                //               src={mediaData.path}
                //               alt=""
                //               className="video-img-review"
                //             />
                //           </>
                //         ) : mediaData?.type === "2" &&
                //           mediaData?.path?.includes("https") ? (
                //           <video
                //             src={mediaData?.path}
                //             autoPlay={true}
                //             height="620"
                //             disablePictureInPicture={true}
                //             controlsList="nodownload noplaybackspeed noplaybackrate"
                //             controls
                //             muted={true}
                //           />
                //         ) : null}
                //       </React.Fragment>
                //     ))}
                //   </SliderComponent>
                // </div>
                <MediaShow feedPost={reviewData} />
              ) : (
                <>
                  {reviewData?.media?.map((mediaData, index) => (
                    <>
                      {mediaData?.type === "1" &&
                      mediaData?.path?.includes("https") ? (
                        <div className="borderforAll">
                          <img
                            src={mediaData?.path ? mediaData.path : VideoImg}
                            alt=""
                            className="video-img-review mt-3"
                          />
                        </div>
                      ) : mediaData?.type === "2" &&
                        mediaData?.path?.includes("https") ? (
                        <div className="borderforAll">
                          <video
                            src={mediaData?.path}
                            autoPlay={true}
                            height="620"
                            disablePictureInPicture={true}
                            controlsList="nodownload noplaybackspeed noplaybackrate"
                            controls
                            muted={true}
                          />
                        </div>
                      ) : null}
                    </>
                  ))}
                </>
              )}
              <div className="image_under mt-3">
                {/* {reviewData?.title && ( */}
                <div className="Borla">
                  <h6>{reviewData?.component?.name || "---"}</h6>
                </div>
                {/* )} */}

                <Link
                  className="right-section"
                  to={`/${reviewData?.user?.username}/garage/${reviewData?.build?.make}/${reviewData?.build?.model}/${reviewData?.build?.year?.value}/${reviewData?.build?._id}`}
                >
                  <div className="build-image">
                    <img
                      src={
                        isValidUrl(reviewData?.build?.bannerImage)
                          ? reviewData?.build?.bannerImage
                          : DefaultUserPng
                      }
                    />
                  </div>
                  <div className="text">
                    <p>{reviewData?.build?.name}</p>
                    <div className="child">
                      {reviewData?.categoryName && (
                        <>
                          <span>{reviewData?.categoryName}</span>
                          <div className="dots-component"></div>
                        </>
                      )}
                      <span>{reviewData?.subCategoryName}</span>
                    </div>
                  </div>
                </Link>
              </div>
              <div>
                <StarRating
                  rating={reviewData?.rating}
                  isShow={false}
                  ratingCount={reviewData?.rating}
                />
              </div>
              {reviewData?.component?.productSku && (
                <div className="code-bar">
                  <p>MPN: {reviewData?.component?.productSku}</p>
                </div>
              )}

              <div className="mt-2 mx-2">
                <ReadMore sliceText={250} isShowMarginTop={true}>
                  {reviewData?.description && reviewData?.description}
                </ReadMore>
              </div>
              {reviewData?.link && (
                <div className="link-url py-2">
                  <LinkImg />
                  <a href={`https://${reviewData?.link}`} rel="noopener" target="_blank">
                    {reviewData?.link}
                  </a>
                </div>
              )}
            </>

            {/* {FOR FUTURE USE} */}
            {/* {postsForHowTo?.posts?.docs?.length > 0 && (
              <div className="how-to-section">
                <h2>How to</h2>
                <div className="block-list">
                  {postsForHowTo?.posts?.docs?.map((post) => (
                    <div className="how-bloc">
                      <>
                        {post?.media?.length > 0 ? (
                          <Link
                            to={`/how-to-install/${post?.componentStatsId}`}
                          >
                            {post?.media[0]?.path?.includes("https") &&
                              post?.media[0]?.type === "2" && (
                                <i className="fa-solid fa-play play-btn"></i>
                              )}
                            {post?.media[0]?.path?.includes("https") &&
                              post?.media[0]?.type === "1" &&
                              post.media.length > 1 && (
                                <MultiMedia className="play-btn" />
                              )}
                            <img
                              src={
                                post?.media[0]?.path?.includes("https") &&
                                post?.media[0]?.type === "2"
                                  ? CarBgForVideo
                                  : post?.media[0]?.path?.includes("https") &&
                                    post?.media[0]?.type === "1"
                                  ? post?.media[0]?.path
                                  : DefaultImg
                              }
                              alt="posts"
                              className="how-img"
                            />
                          </Link>
                        ) : (
                          <img
                            src={DefaultImg}
                            alt="im78"
                            className="how-img"
                          />
                        )}
                      </>
                      <div className="info">
                        <ul className="install-info">
                          <li>
                            {post?.stepLength}
                            {post?.stepLength > 1 ? " Steps" : " Step"}
                          </li>
                          <li>{dateTimeFromNow(post?.createdAt)}</li>
                          <li>4/5 difficulty</li>
                        </ul>
                        <h4>{post?.title}</h4>
                        {post?.description && <p>{post?.description}</p>}
                        <div className="actions-btns">
                          <LikeButton
                            reactData={{
                              isReacted: post?.isReacted,
                              id: post?._id,
                              reactionsCount: post?.reactionsCount,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {allPosts?.docs?.length > 0 && (
              <div className="gallery-section mb-5">
                <h2>Gallery</h2>
                <div className="gallery-list">
                  {allPosts?.docs?.map((post) => (
                    <>
                      {post?.media?.length > 0 ? (
                        <Link to={`/general-post/${post?._id}`}>
                          <div
                            className="how-block"
                            key={post?._id}
                            type="button"
                          >
                            {post?.media[0]?.path?.includes("https") &&
                              post?.media[0]?.type === "2" && (
                                <i className="fa-solid fa-play play-btn"></i>
                              )}
                            {post?.media[0]?.path?.includes("https") &&
                              post?.media[0]?.type === "1" &&
                              post.media.length > 1 && (
                                <MultiMedia className="play-btn" />
                              )}
                            <img
                              src={
                                post?.media[0]?.path?.includes("https") &&
                                post?.media[0]?.type === "2"
                                  ? CarBgForVideo
                                  : post?.media[0]?.path?.includes("https") &&
                                    post?.media[0]?.type === "1"
                                  ? post?.media[0]?.path
                                  : DefaultImg
                              }
                              alt="posts"
                              className="gallery-img"
                            />
                          </div>
                        </Link>
                      ) : (
                        <div className="how-block" type="button">
                          <img
                            src={DefaultImg}
                            alt="im78"
                            className="gallery-img"
                          />
                        </div>
                      )}
                    </>
                  ))}
                </div>
                {allPosts?.totalDocs > 10 && (
                  <div className="show-more">
                    Show more posts
                    <svg
                      width="16"
                      height="19"
                      viewBox="0 0 16 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="arrow-down">
                        <path
                          id="Path 2"
                          d="M7.99967 2.75V16.25"
                          stroke="#387AFF"
                          stroke-linecap="round"
                        />
                        <path
                          id="Path 3"
                          d="M4 11.75L8 16.25L12 11.75"
                          stroke="#387AFF"
                          stroke-linecap="round"
                        />
                      </g>
                    </svg>
                  </div>
                )}
              </div> 
            )} */}
          </div>
          {/* )} */}
          {/* COMMENTS SHOW */}
          <Comments
            post={reviewData}
            isComponent={false}
            postId={reviewData?._id}
            // isTablet={isTablet}
          />
        </>
      )}
    </div>
  );
}
