import React, { useEffect, useState } from "react";

import VideoImg from "@assets/video.jpg";
import "./HowToInstall.scss";
import SideBarMain from "@components/SideBarMain";
import { useParams } from "react-router-dom";
import { ReactComponent as ThumbsUp } from "@assets/carDetailsImgs/thumb-up.svg";
import { ReactComponent as ShareHowTo } from "@assets/ShareHowTo.svg";
import { ReactComponent as BookMarkIcon } from "@assets/BookMarkIcon.svg";
import { ReactComponent as SettingsIcon } from "@assets/SettingsIcon.svg";
import { ReactComponent as ToolsIcon } from "@assets/ToolsIcon.svg";
import * as Yup from "yup";
import { useFormik } from "formik";
import Comments from "@components/Comments/Comments";
import ReadMore from "@components/ReadMore";
import Dummy from "@components/Dummy";
const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
};
export default function HowToInstall() {
  const CommentsSchema = Yup.object().shape({
    commentText: Yup.string().max(800, "Limit Exceeded!"),
  });

  const formik = useFormik({
    initialValues: {
      commentText: "",
    },
    validationSchema: CommentsSchema,
  });

  const { componentStatId } = useParams();
  let user,
    getDataComponentStatsId,
    getUserData,
    followUser,
    unFollowUser,
    likeUnlikeHowToSteps;
  const [componentStatIdData, setComponentStatIdData] = useState({});
  const [stepsForComponent, setStepsForComponent] = useState([]);
  const [posts, setPosts] = useState({});

  const [isDisableFollowUserBtn, setIsDisableFollowUserBtn] = useState(false);
  const [isFollow, setIsFollow] = useState(false);
  const [isDisableLike, setIsDisbaleLike] = useState(false);

  const [stepCount, setStepCount] = useState(0);
  const [userData, setUserData] = useState({});
  const [reactionForStep1, setReactionForStep1] = useState({});
  useEffect(() => {
    getComponentStatsIdData();
  }, []);
  const getUserDataForHowTo = async (userId) => {
    try {
      const response = await getUserData(userId);
      setUserData(response?.data?.data?.user);
    } catch (error) {
      console.log(error);
    }
  };
  const getComponentStatsIdData = async () => {
    try {
      if (componentStatId) {
        const response = await getDataComponentStatsId(componentStatId);
        if (response?.data?.data?.length > 0) {
          setComponentStatIdData(response?.data?.data[0]);
          if (response?.data?.data?.[0]?.post?.length > 0) {
            setStepsForComponent(response?.data?.data[0]?.post);
            getUserDataForHowTo(response?.data?.data?.[0]?.post[0]?.userId);
            const checkUser =
              response?.data?.data?.[0]?.post[0]?.userId === user?.user?.id;
            setIsFollow(checkUser);
            const post = response?.data?.data?.[0]?.post[0];
            setReactionForStep1({
              isReacted: post?.isReacted,
              id: post?._id,
              reactionsCount: post?.reactionsCount,
            });
            setPosts(post);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleStep = (step) => {
    window.scrollTo(0, 0);
    setPosts(stepsForComponent[step]);
    setStepCount(step);
  };
  const followUnfollowUser = async () => {
    setIsDisableFollowUserBtn(true);
    try {
      if (userData?.isFollowed) {
        await unFollowUser(posts?.userId)
          .then((res) => {
            setUserData((prev) => ({
              ...prev,
              followerCount: userData.followerCount - 1,
              isFollowed: false,
            }));
            setIsDisableFollowUserBtn(false);
          })
          .catch(() => {
            setIsDisableFollowUserBtn(false);
          });
        return;
      }
      if (posts?.userId !== undefined) {
        const body = {
          followToUserId: posts?.userId,
        };
        await followUser(body)
          .then(() => {
            setUserData((prev) => ({
              ...prev,
              followerCount: userData.followerCount + 1,
              isFollowed: true,
            }));
            setIsDisableFollowUserBtn(false);
          })
          .catch(() => {
            setIsDisableFollowUserBtn(false);
          });
      }
    } catch (error) {}
  };

  const likeUnlikeHow = async () => {
    setIsDisbaleLike(true);
    try {
      const data = {
        reactionType: reactionForStep1?.isReacted ? "0" : "1",
        modelId: reactionForStep1?.id,
        model: "post",
      };

      await likeUnlikeHowToSteps(data).then((res) => {
        if (res?.data?.message === "reaction remove success") {
          setReactionForStep1((prev) => ({
            ...prev,
            isReacted: false,
            reactionsCount: res?.data?.data?.reactionCount,
          }));
          setTimeout(() => {
            setIsDisbaleLike(false);
          }, 2000);
        } else if (res?.data?.message === "Reaction Stored Successfully") {
          setReactionForStep1((prev) => ({
            ...prev,
            isReacted: true,
            reactionsCount: res?.data?.data?.reactionCount,
          }));
          setTimeout(() => {
            setIsDisbaleLike(false);
          }, 2000);
        }
      });
    } catch (error) {
      setIsDisbaleLike(false);

      console.log(error);
    }
  };

  useEffect(() => {
    formik.validateForm();
  }, []);
  return (
    <div>
      {/* <Header /> */}
      <div className="page-container">
        <SideBarMain />
        <div>
          <div className="post-title how-to-install-header">
            <div>
              <div className="sm-title">
                {posts?.componentName && posts?.componentName}
              </div>
              <h1>
                {componentStatIdData?.title && componentStatIdData?.title}
              </h1>
              <div className="actions-btns">
                <button
                  style={{
                    border: "none",
                    background: "none",
                    padding: 0,
                    cursor: "pointer",
                  }}
                  disabled={isDisableLike}
                >
                  <ThumbsUp
                    fill={reactionForStep1?.isReacted ? "white" : ""}
                    onClick={likeUnlikeHow}
                  />
                </button>
                <span>
                  {reactionForStep1?.reactionsCount &&
                    (reactionForStep1?.reactionsCount || "")}
                </span>

                <ShareHowTo />

                <BookMarkIcon />
              </div>
            </div>

            <div className="installation-info">
              <div className="install-info">
                <div>
                  <h6>{componentStatIdData?.estimatedTime} hr</h6>
                  <p>Est. Time</p>
                </div>
                <div>
                  <h6>{componentStatIdData?.difficultyLevel}/5</h6>
                  <p>Difficulty</p>
                </div>
                <div>
                  <h6>{componentStatIdData?.stepLength}</h6>
                  <p>Steps</p>
                </div>
              </div>
              <div className="tags mt-4">
                <span>{posts?.build?.year?.value}</span>
                <span>{posts?.build?.make}</span>
                <span>{posts?.build?.model}</span>
                <span>{posts?.category?.value}</span>
                <span>{posts?.subCategory?.value}</span>
              </div>
            </div>
          </div>
          <div className="video-section">
            {posts?.media?.length > 1 ? (
              <div className="borderforAll">
                {/* <SliderComponent settings={settings}>
                  {posts?.media?.map((mediaData, index) => (
                    <>
                      {mediaData?.type === "1" ? (
                        <div>
                          <img
                            src={mediaData?.path ? mediaData.path : VideoImg}
                            alt=""
                            className="vid-img-how"
                          />
                        </div>
                      ) : mediaData?.type === "2" ? (
                        <video
                          src={mediaData?.path}
                          autoPlay={true}
                          height="620"
                          disablePictureInPicture={true}
                          controlsList="nodownload noplaybackspeed noplaybackrate"
                          controls
                          muted={true}
                        />
                      ) : null}
                    </>
                  ))}
                </SliderComponent> */}
              </div>
            ) : (
              <>
                {posts?.media?.map((mediaData, index) => (
                  <>
                    {mediaData?.type === "1" ? (
                      <div>
                        <img
                          src={mediaData?.path ? mediaData.path : VideoImg}
                          alt=""
                          className="vid-img-how"
                        />
                      </div>
                    ) : mediaData?.type === "2" ? (
                      <video
                        src={mediaData?.path}
                        autoPlay={true}
                        height="620"
                        disablePictureInPicture={true}
                        controlsList="nodownload noplaybackspeed noplaybackrate"
                        controls
                        muted={true}
                      />
                    ) : null}
                  </>
                ))}
              </>
            )}
            <div className="steps">
              Step {stepCount + 1}/
              {componentStatIdData?.stepLength &&
                componentStatIdData?.stepLength}
            </div>

            <div
              className="tools-wrapper"
              style={{
                display:
                  posts?.tools?.length === 0 &&
                  posts?.parts?.length === 0 &&
                  "none",
              }}
            >
              <div className="tools-info">
                {posts?.tools?.length > 0 && (
                  <h3>
                    <ToolsIcon />
                    Tools Needed
                  </h3>
                )}
                <ul>
                  {posts?.tools?.length > 0 && (
                    <>
                      {posts?.tools?.map((t) => (
                        <li>{t}</li>
                      ))}{" "}
                    </>
                  )}
                </ul>
              </div>
              <div className="additional-parts">
                {posts?.parts?.length > 0 && (
                  <>
                    <h3>
                      <SettingsIcon />
                      Additional Parts
                    </h3>
                    <ul>
                      {posts?.parts.map((p) => (
                        <li>{p.name}</li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </div>
            <h4>{posts?.title}</h4>
            <div className="show-more-title mt-2">
              {posts?.description && (
                <ReadMore sliceText={250}>{posts?.description}</ReadMore>
              )}
              <br></br>
            </div>
            {componentStatIdData?.stepLength > 1 && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {stepCount > 0 && (
                  <div
                    className="back-step-btn"
                    onClick={() => {
                      // resetForm();
                      // formik.validateForm();
                      handleStep(stepCount - 1);
                    }}
                  >
                    <i className="fa fa-angle-left"></i> Back Step
                  </div>
                )}
                {stepCount < componentStatIdData?.stepLength - 1 && (
                  <div
                    className="next-step-btn"
                    onClick={() => {
                      // resetForm();
                      // formik.validateForm();
                      handleStep(stepCount + 1);
                    }}
                  >
                    Next Step <i className="fa fa-angle-right"></i>
                  </div>
                )}
              </div>
            )}
          </div>{" "}
          {Object.keys(posts)?.length !== 0 && (
            <Comments
              isHideCommentIcon={true}
              commentId={Object.keys(posts)?.length !== 0 ? posts?._id : ""}
              commentsCount={posts?.commentsCount}
              posts={posts}
              setPosts={setPosts}
              isEnableApi={true}
              isHideShare={false}
              commentsCountForPost={{
                id: posts?._id,
                commentsCount: posts?.commentsCount,
              }}
            />
          )}
        </div>
        <div>
          <Dummy
            userData={userData}
            isFollow={isFollow}
            isDisableFollowUserBtn={isDisableFollowUserBtn}
            followUnfollowUser={followUnfollowUser}
          />
        </div>
      </div>
    </div>
  );
}
