import React, {  useRef, useState } from "react";
import "./Posts.scss";
import AllPosts from "./AllPosts";
import CrewFilter from "./CrewFilter";
import { useParams } from "react-router";
import HeaderFilterLoader from "components/Skeleton/HeaderFilterLoader";
import { sliceStringAtWord } from "utils/sliceStringWord";
import { useSelector } from "react-redux";
import PrivacyComp from "components/Privacy/PrivacyComp";
import { useGetFilterCategoryQuery } from "@redux/services/crewService/allCrewService";

const Posts = ({ crewData = {}, user = {}, isMobile, isTablet }) => {
  const scrollContainerRef = useRef();
  const { crewId } = useParams();
  /**
   * API CALLS
   */
  const { data: { data } = [], isLoading } = useGetFilterCategoryQuery(crewId);
  //

  const [activeIndex, setActiveIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [selectCategoryId, setSelectCategoryId] = useState(null);

  const checkPrivacy = !!(
    crewData?.userId !== user?._id &&
    crewData?.privacy &&
    !crewData?.isJoin
  );
  return (
    <React.Fragment>
      {checkPrivacy ? (
        <PrivacyComp />
      ) : (
        <div className="header-filter">
          {isLoading ? (
            <HeaderFilterLoader />
          ) : (
            <div className="filter-data" ref={scrollContainerRef}>
              {data?.length > 0 && (
                <div
                  className={`filter-text ${activeIndex === 0 ? "active" : ""}`}
                >
                  <h4>All</h4>
                </div>
              )}

              {data?.map((item, index) => (
                <div
                  className={`filter-text 
                  ${activeIndex === index + 1 ? "active" : ""}
                  `}
                  key={index}
                  onClick={() => {
                    setActiveIndex(index + 1);
                    setSelectCategoryId(item);
                  }}
                >
                  <h4>
                    {item?.title?.length >= 20
                      ? `${sliceStringAtWord(item?.title, 20) + "..."}`
                      : item?.title}
                  </h4>
                </div>
              ))}
            </div>
          )}

          {activeIndex === 0 && (
            <AllPosts isMobile={isMobile} isTablet={isTablet} />
          )}
          {activeIndex >= 1 && (
            <CrewFilter
              page={page}
              setPage={setPage}
              activeIndex={activeIndex}
              selectCategoryId={selectCategoryId}
              isMobile={isMobile}
              isTablet={isTablet}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default Posts;
