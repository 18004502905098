import React from "react";

const StarRating = ({
  rating,
  ratingCount,
  isShow,
  addRatingByStar = () => {},
  isRate = false,
  isRedirect = false,
  handleClickScroll = () => {},
}) => {
  const maxRating = [1, 2, 3, 4, 5];

  return (
    <div
      className="stars"
      style={{ cursor: isRedirect && "pointer" }}
      onClick={() => {
        if (isRedirect) {
          handleClickScroll();
        }
      }}
    >
      {maxRating?.map((item, index) =>
        item <= rating ? (
          <i key={`in-${index++}`} className={`fa-solid fa-star checked`} style={{color:"#387aff"}}></i>
        ) : (
          <i key={`in-${index}`} className="fa-regular fa-star"></i>
        )
      )}
      {isRate &&
        rating === 0 &&
        maxRating?.map((item) => (
          <i
            key={`in-${item}`}
            className="fa-regular fa-star"
            onClick={() => addRatingByStar(item)}
          ></i>
        ))}
      {isShow && <span>({ratingCount || "0"})</span>}
    </div>
  );
};

export default StarRating;
