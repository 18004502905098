import React from 'react';
import Group2 from "@assets/membershipDashboard/Group 2.png";
import SpeedSociety from "@assets/membershipDashboard/SpeedSociety.svg";
import OnlineStatusDot from "@containers/BuildProfile/components/OnlineStatusDot";

const StatisticsData = ({ Tab, handleChange, userInfo, speedSoietyConnected }) => {
  return (
    <div className="statistic-data">
      <div className={`cx-points ${Tab === 1 ? "active-points" : ""}`} onClick={() => handleChange(1)}>
        <div style={{ display: "flex", gap: "5px" }}>
          <img style={{ width: "22px" }} src={Group2} alt="Cx Points" />
          <p
            style={{
              color: "#8E8E93",
              fontWeight: "400",
              fontSize: "11px",
              lineHeight: "13.2px",
            }}
          >
            Cx Points
          </p>
        </div>
        <div>
          <p style={{ color: userInfo?.interactionPoint?.available ? "#FFFFFF" : "#8E8E93" }}>
            {userInfo?.interactionPoint?.available ? userInfo?.interactionPoint?.available : "No Data"}{" "}
            {userInfo?.interactionPoint?.available && (
              <span style={{ fontSize: "13px", color: "#F5F5F5" }}>Points</span>
            )}
          </p>
        </div>
      </div>
      <div className={`cx-points ${Tab === 2 ? "active-points" : ""}`} onClick={() => handleChange(2)}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", gap: "5px" }}>
            <img style={{ width: "22px" }} src={SpeedSociety} alt="Speed Society" />
            <p
              style={{
                color: "#8E8E93",
                fontWeight: "400",
                fontSize: "11px",
                lineHeight: "13.2px",
              }}
            >
              Speed Society
            </p>
          </div>
          <div>
            <OnlineStatusDot status={speedSoietyConnected === 0 ? "offline" : "online"} />
          </div>
        </div>
        <div>
          <p
            style={{
              color: userInfo?.speedSociety?.entries !== null && speedSoietyConnected === 1 ? "#FFFFFF" : "#8E8E93",
            }}
          >
            {userInfo?.speedSociety?.entries !== null && speedSoietyConnected === 1
              ? `${userInfo?.speedSociety?.entries} entries`
              : "No Data"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StatisticsData;
