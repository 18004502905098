import React, { useEffect, useState } from "react";
import "../searchcomponent/SearchPage.scss";
import { useLocation, useNavigate, useParams } from "react-router";
import { useMediaQuery } from "react-responsive";
import ExploreBuild from "./components/categoryBase/ExploreBuild";
import ExploreCrew from "./components/categoryBase/ExploreCrew";
import ExploreAccounts from "@containers/searchcomponent/ExploreAccounts";
import { Link } from "react-router-dom";
import ExplorePosts from "./components/categoryBase/ExplorePosts";

const ExploreByValue = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    state: { title, searchByName },
  } = location || {};
  const isMobile = useMediaQuery({ maxWidth: 599 });
  const isTablet = useMediaQuery({ minWidth: 700, maxWidth: 992 });
  const [userinfo, setUserInfo] = useState({
    years: [],
  });
  // let state = {};
  const { keyword } = useParams();
  const [search, setSearch] = useState(searchByName || "");
  const [activeCategory, setActiveCategory] = useState(title);
  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };
  useEffect(() => {
    const toggleIcons = (event) => {
      const icon = event.currentTarget.querySelector("i");
      if (icon.classList.contains("fa-chevron-down")) {
        icon.classList.remove("fa-chevron-down");
        icon.classList.add("fa-chevron-up");
      } else {
        icon.classList.remove("fa-chevron-up");
        icon.classList.add("fa-chevron-down");
      }

      const allIcons = document.querySelectorAll(".acc-btn i");
      allIcons.forEach((otherIcon) => {
        if (otherIcon !== icon) {
          otherIcon.classList.remove("fa-chevron-up");
          otherIcon.classList.add("fa-chevron-down");
        }
      });
    };

    const accordionButtons = document.querySelectorAll(".acc-btn");
    accordionButtons.forEach((button) => {
      button.addEventListener("click", toggleIcons);
    });

    return () => {
      accordionButtons.forEach((button) => {
        button.removeEventListener("click", toggleIcons);
      });
    };
  }, []);

  return (
    <>
      <div
        className="searchResults-container"
        style={{
          gridColumn:
            ((activeCategory === "" || activeCategory === "Accounts") &&
              !isMobile) ||
            isTablet
              ? "2 / 4"
              : "",
        }}
      >
        <div className="full-search">
          <div className="search-form-explore">
            <div className="search-fld">
              <input
                type="search"
                className="form-control"
                placeholder="Search for anything"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {activeCategory !== "" && (
                <>
                  <span className="active-tag">
                    {activeCategory}
                    <svg
                      onClick={() => {
                        setActiveCategory("");
                        navigate("/explore/all");
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M8.82869 8.82828L3.17184 3.17142"
                        stroke="white"
                        stroke-linecap="round"
                      />
                      <path
                        d="M8.82816 3.17142L3.17131 8.82828"
                        stroke="white"
                        stroke-linecap="round"
                      />
                    </svg>
                  </span>
                </>
              )}

              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M12.0094 12.5102L16.666 17.1667"
                    stroke="#F5F5F5"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.33398 13.8333C11.0954 13.8333 13.334 11.5947 13.334 8.83325C13.334 6.07183 11.0954 3.83325 8.33398 3.83325C5.57256 3.83325 3.33398 6.07183 3.33398 8.83325C3.33398 11.5947 5.57256 13.8333 8.33398 13.8333Z"
                    stroke="#F5F5F5"
                    stroke-width="1.5"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="search-cate">
            {["Builds", "Crews", "Accounts", "Posts"].map((category) => (
              <Link
                to={`/explore/categories/${category}`}
                state={{ title: category }}
                key={category}
                className={`${
                  category === activeCategory ? "active" : ""
                } active-category`}
                onClick={() => handleCategoryClick(category)}
              >
                {category}
              </Link>
            ))}
          </div>
          {activeCategory == "Builds" && (
            <>
              <div className="view-all-heading">
                <h2>Builds</h2>
              </div>

              <ExploreBuild categorySearch={search} />
            </>
          )}
          {activeCategory == "Crews" && <ExploreCrew categorySearch={search} />}
          {activeCategory == "Accounts" && (
            <ExploreAccounts categorySearch={search} />
          )}
          {activeCategory == "Posts" && (
            <ExplorePosts categorySearch={search} />
          )}
        </div>
      </div>
      {/* {activeCategory !== "" && activeCategory !== "Accounts" && (
        <ExploreFilters
          activeCategory={activeCategory}
          userinfo={userinfo}
          setUserInfo={setUserInfo}
        />
      )} */}
    </>
  );
};

export default ExploreByValue;
