import React from "react";
import "./UserProfile.scss";
import DefaultUserImg from "@assets/defaultUser.png";
import { Link, useParams } from "react-router-dom";
import { isValidUrl } from "@utils/validUrl";
import { UserImg } from "@utils/defaultImages";
import { useGetShowCaseQuery } from "@redux/services/userProfile/BuildComponentService";

export default function UserProfileComponent({ reviewData = {} }) {
  const { componentId } = useParams();
  /**
   * API CALLS
   */
  const { data: { data: showCaseComponent } = {}, isLoading } =
    useGetShowCaseQuery(componentId);

  return (
    <div className="sticky-clm-2">
      <div className="user-detail-wrapper">
        <Link
          // to={
          //   user?.user?._id === userData?._id
          //     ? `/profile`
          //     : `/profile/${userData?._id}`
          // }
          className="profile-link"
        >
          <div className="avtar-in">
            <img
              src={
                isValidUrl(reviewData?.build?.bannerImage)
                  ? reviewData?.build?.bannerImage
                  : DefaultUserImg
              }
              alt={reviewData?.build?.name}
              className="main-img"
            />
            <div>
              <div className="name">{reviewData?.build?.name}</div>
            </div>
          </div>

          <div className="categories-component">
            <div className="build-categories">
              <p>{reviewData?.build?.year?.value}</p>
            </div>
            <div className="build-categories">
              <p>{reviewData?.build?.make}</p>
            </div>
            <div className="build-categories">
              <p>{reviewData?.build?.model}</p>
            </div>
          </div>
        </Link>

        {/* <div className="follow-btn">
            <ButtonLoader />
          </div> */}

        {/* <div className="follow-btn"
          // onClick={followUnfollowUser}
        >
           Following
          </div> */}
      </div>
      {showCaseComponent?.postCount !== 0 && (
        <div className="related">
          <div className="post-heading">
            <div className="posts">
              <p>Related Posts</p>
            </div>
            {showCaseComponent?.postCount > 4 && (
              <Link
                className="view"
                to={`/get-all-mods/${componentId}`}
                state={reviewData}
              >
                <p>View All</p>
              </Link>
            )}
          </div>
          <div className="image-title d-flex flex-wrap gap-1">
            {showCaseComponent?.post?.slice(0, 4)?.map((itemCase, index) => {
              const mediaPath = itemCase?.media[0]?.path;
              const isValidMediaPath = isValidUrl(mediaPath);
              const isVideo = mediaPath?.includes("mp4");
              const thumbnailPath = itemCase?.media[0]?.thumbnail;
              return (
                <Link className="build-image" to={`/post/${itemCase?._id}`}>
                  <img
                    src={
                      isValidMediaPath
                        ? isVideo
                          ? thumbnailPath
                          : mediaPath
                        : UserImg
                    }
                  />
                  {itemCase?.title && <h6>{itemCase?.title}</h6>}
                </Link>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
