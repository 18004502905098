import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "../baseQuery";
export const signUpApi = createApi({
  reducerPath: "signUpApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    userInfoEmail: builder.mutation({
      query: (value) => ({
        url: "auth/check-email",
        method: "POST",
        body: value,
      }),
    }),
    userInfoUserName: builder.mutation({
      query: (value) => ({
        url: "auth/check-username",
        method: "POST",
        body: value,
      }),
    }),
    userCheckPhone: builder.query({
      query: (phoneNumber) => ({
        url: `auth/send-otp/${phoneNumber}`,
        method: "GET",
      }),
    }),
    userVerifyOtp: builder.mutation({
      query: (value) => ({
        url: "auth/verify-otp",
        method: "POST",
        body: value,
      }),
    }),
    registerUserInfo: builder.mutation({
      query: (payload) => ({
        url: "auth/register",
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          carvonixToken: process.env.REACT_APP_CARVONIX_TOKEN,
        },
      }),
    }),
    popularAccounts: builder.query({
      query: (page = 1) => ({
        url: `users/popular-account?limit=100&page=${page}`,
        method: "GET",
      }),
    }),
    followAccount: builder.mutation({
      query: (ids) => ({
        url: "/users/follow",
        method: "POST",
        body: ids,
      }),
    }),
    geatherUserInfo: builder.mutation({
      query: (payload) => ({
        url: "/users/update-profile",
        method: "POST",
        body: payload,
        formData: true,
      }),
    }),
  }),
});

export const {
  useUserInfoEmailMutation,
  useUserInfoUserNameMutation,
  useLazyUserCheckPhoneQuery,
  useUserVerifyOtpMutation,
  useRegisterUserInfoMutation,
  usePopularAccountsQuery,
  useFollowAccountMutation,
  useGeatherUserInfoMutation,
} = signUpApi;
