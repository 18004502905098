import { useMediaQuery } from "react-responsive";

const useMediaQueryHook = () => {
  const isMobileSmall = useMediaQuery({ minWidth: 320, maxWidth: 424 });
  const isMobile = useMediaQuery({ minWidth: 425, maxWidth: 599 });
  const isMobTab = useMediaQuery({ minWidth: 600, maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isTabScr = useMediaQuery({ minWidth: 1024, maxWidth: 1320 });

  return { isMobile, isTablet, isMobTab, isTabScr, isMobileSmall };
};

export default useMediaQueryHook;
