import React, { useState } from "react";
import "@containers/searchcomponent/SearchPage.scss";
import UserImg from "@assets/defaultUser.png";
import { isValidUrl } from "@utils/validUrl";
import { Link } from "react-router-dom";
import {
  useFollowProfileExploreMutation,
  useRemovefollowProfileExploreMutation,
} from "@redux/services/exploreService/exploreService";
import FollowUnFollow from "@components/Button/FollowBtn/FollowUnFollow";
import { toast } from "react-toastify";

const CategoryCrewAccounts = ({ listAccount }) => {
  // const filteredAccount = resAccount?.filter(
  //   (item) => !followAccount?.includes(item?._id)
  // );
  /**
   * USESTATES
   */
  const [LoadingFollow, setLoadingFollow] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  /**
   * API CALLS
   */

  const [followProfile] = useFollowProfileExploreMutation();
  const [removefollowProfile] = useRemovefollowProfileExploreMutation();
  /**
   * FUNCTIONS
   */
  const followUnFollowProfile = async (userInfo) => {
    const body = {
      followToUserId: userInfo?._id,
    };
    setLoadingFollow(true);

    try {
      if (userInfo?.isFollow) {
        await removefollowProfile(userInfo?._id).unwrap();
      } else {
        await followProfile(body).unwrap();
      }
    } catch (error) {
      toast.error(error?.data?.message);
    } finally {
      setLoadingFollow(false);
    }
  };

  return (
    <>
      <div className="view-all-heading">
        <h2>Accounts</h2>
        {listAccount?.docs?.length > 5 && (
          <Link
            to={`/explore/categories/Accounts`}
            state={{ title: "Accounts" }}
          >
            See More
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.25 11.25L10 7.5L6.25 3.75"
                stroke="#387AFF"
                stroke-linecap="round"
              />
            </svg>
          </Link>
        )}
      </div>

      <div className="crew-list-content-search">
        {listAccount?.docs?.slice(0, 6)?.map((item, index) => {
          return (
            <div className="list-block user-list-block" key={item?._id}>
              <Link
                to={`/profile/${item?._id}`}
                className="list-block"
                style={{
                  borderBottom: "none",
                  color: "#fff",
                  padding: "unset",
                  marginBottom: "unset",
                }}
              >
                <img
                  src={
                    isValidUrl(item?.profileImage)
                      ? item?.profileImage
                      : UserImg
                  }
                  alt={item?.username}
                  style={{ width: "40px", height: "40px" }}
                  className="main-img"
                />
                <div className="list-info">
                  <h4>{item?.username}</h4>
                  <p>{item?.firstName}</p>
                </div>
              </Link>
              <FollowUnFollow
                followUnfollow={followUnFollowProfile}
                userInfo={item}
                loading={LoadingFollow}
                btnIndex={index}
                compareIndex={setSelectedIndex}
                selectedIndex={selectedIndex}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CategoryCrewAccounts;
