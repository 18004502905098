import React from "react";

const Horsepower = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="10" fill={props?.fill} />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9996 10.2498C14.9996 12.4588 13.2088 14.2496 10.9998 14.2496C8.79077 14.2496 7 12.4588 7 10.2498C7 8.04077 8.79077 6.25 10.9998 6.25C13.2088 6.25 14.9996 8.04077 14.9996 10.2498ZM10.9998 11.7497C11.8282 11.7497 12.4997 11.0782 12.4997 10.2498C12.4997 9.42141 11.8282 8.74987 10.9998 8.74987C10.1714 8.74987 9.49987 9.42141 9.49987 10.2498C9.49987 11.0782 10.1714 11.7497 10.9998 11.7497Z"
        fill="#F5F5F5"
      />
      <rect x="5.5" y="6.25" width="5.49972" height="2.49987" fill="#F5F5F5" />
      <rect x="5.5" y="6" width="0.999949" height="2.99985" fill="#F5F5F5" />
    </svg>
  );
};
const Engine = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="10" fill={props?.fill} />
      <path
        d="M7.40143 12.8404L7.28996 12.7187H7.125H6.375V11.6562V11.2812H6H4.9375H4.5625V11.6562V12.7187H4.375V9.65625H4.5625V10.6562V11.0312H4.9375H6H6.375V10.6562V9.65625H7.125H7.29892L7.41127 9.52348L8.67392 8.03125H10.125H10.5V7.65625V6.40625V6.03125H10.125H9.3125V5.78125H12.0625V6.03125H11.25H10.875V6.40625V7.65625V8.03125H11.25H13.6118L13.6875 8.14479V10.0937V10.4687H14.0625H14.875H15.25V10.0937V9.28125H15.625V12.5312H15.25V11.6562V11.2812H14.875H14.0625H13.6875V11.6562V12.4384L11.9072 14.2187H8.66496L7.40143 12.8404Z"
        stroke="#F5F5F5"
        stroke-width="0.75"
      />
    </svg>
  );
};
export { Horsepower, Engine };
