import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import logo from "@assets/authImgs/logo.svg";
import logo from "@assets/logo-header.svg";

import { ReactComponent as LoginGoogle } from "@assets/login-google.svg";
import { ReactComponent as LoginFacebook } from "@assets/LoginFacebook.svg";
import { ReactComponent as LoginApple } from "@assets/LoginApple.svg";
import "./Login.scss";
import ButtonLoader from "@components/Loaders/ButtonLoader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  useGoogleLoginMutation,
  useLoginUserMutation,
} from "@redux/services/auth/loginService.js";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setAuth, setLoginData } from "@redux/slices/authSlice/loginSlice.js";
import {
  setTokenAuth,
  setTemporaryLoginData,
  clearTokenAuth,
} from "@redux/slices/authSlice/tokenSlice";
import ResetModal from "@components/Modal/ResetModal.js";
import { signInWithGooglePopup } from "@utils/firebase.utils";
import { FaRegEye } from "react-icons/fa6";
import { FaRegEyeSlash } from "react-icons/fa6";




const google_client_id = process.env.REACT_APP_GG_APP_ID || "";
const facebook_app_id = process.env.REACT_APP_FACEBOOK_APP_ID || "";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

function Login() {
  const dispatch = useDispatch();
  const [loginUser] = useLoginUserMutation();
  const [socialGoogle] = useGoogleLoginMutation();
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (values) => {
    try {
      const loginResponse = await loginUser(values).unwrap();
      if (loginResponse?.data?.tokens) {
        if (loginResponse?.data?.user?.isCompleted) {
          dispatch(clearTokenAuth());
          dispatch(setAuth(loginResponse?.data?.tokens));
          dispatch(setLoginData(loginResponse?.data?.user));
        } else {
          dispatch(setTokenAuth(loginResponse?.data?.tokens));
          dispatch(setTemporaryLoginData(loginResponse?.data?.user));
          navigate("/congratulations-view");
        }
      } else {
        navigate(`/waiting-list/${loginResponse?.data?.waitingNumber}`);
      }

      // console.log("refreshResult---??", loginResponse);
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  // const handleGoogleLogin = useCallback(async (data) => {
  //   const sendData = {
  //     firstName: data?.given_name,
  //     lastName: data?.family_name,
  //     email: data?.email,
  //     type: 2,
  //     token: data?.access_token,
  //     socialId: data?.sub,
  //     profileImage: data?.picture,
  //   };
  //   const loginResponse = await socialGoogle(sendData);
  //   if (loginResponse?.data?.data?.tokens) {
  //     if (loginResponse?.data?.data?.user?.isCompleted) {
  //       dispatch(clearTokenAuth())
  //       dispatch(setAuth(loginResponse?.data?.data?.tokens));
  //       dispatch(setLoginData(loginResponse?.data?.data?.user))
  //     }
  //     else{
  //       dispatch(setTokenAuth(loginResponse?.data?.data?.tokens));
  //       dispatch(setTemporaryLoginData(loginResponse?.data?.data.user))
  //       navigate("/congratulations-view")
  //     }
  //   }
  //   else {
  //     navigate(`/waiting-list/${loginResponse?.data?.data?.waitingNumber}`)
  //   }
  //   // dispatch(socialLogin(sendData));
  // }, []);
  const logGoogleUser = async () => {
    const response = await signInWithGooglePopup();
    const { firstName, lastName, email, oauthIdToken, localId, photoUrl } =
      response?._tokenResponse;
    const sendData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      type: 2,
      token: oauthIdToken,
      socialId: localId,
      profileImage: photoUrl,
    };
    const loginResponse = await socialGoogle(sendData);
    if (loginResponse?.data?.data?.tokens) {
      if (loginResponse?.data?.data?.user?.isCompleted) {
        dispatch(clearTokenAuth());
        dispatch(setAuth(loginResponse?.data?.data?.tokens));
        dispatch(setLoginData(loginResponse?.data?.data?.user));
      } else {
        dispatch(setTokenAuth(loginResponse?.data?.data?.tokens));
        dispatch(setTemporaryLoginData(loginResponse?.data?.data.user));
        navigate("/congratulations-view");
      }
    } else {
      navigate(`/waiting-list/${loginResponse?.data?.data?.waitingNumber}`);
    }
  };
  const handleFacebookLogin = useCallback(async (facebookResponse) => {
    const { provider, data } = facebookResponse;
  }, []);

  const onLoginStart = useCallback(() => {
    alert("login start");
  }, []);

  const onLogoutSuccess = useCallback(() => {
    alert("logout success");
  }, []);

  const handleForgotPassword = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <div>
      <div className="login-wrapper">
        <div className="wrapper">
          <h1 style={{ fontSize: "16px", textAlign: "center" }}>
            Find your drive with
          </h1>
          <Link to="/">
            <img src={logo} alt="Carvonix Logo" className="logo" />
          </Link>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="form-group mb-4 login-info">
                  <label className="pb-1">Email</label>
                  <Field
                    type="text"
                    name="email"
                    label="email"
                    placeholder="Enter your email"
                    className="form-control field-inp"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger error-message"
                  />
                </div>
                <div className="form-group position login-info">
                  <label className="pb-1">Password</label>
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    className="form-control field-inp"
                  />
                  <div
                    className="eye-lash"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {!showPassword ? (
                     <FaRegEyeSlash />
                    ) : (
                      <FaRegEye />
                    )}
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-danger error-message"
                  />
                </div>
                <div className="btn-pass">
                  <button
                    type="button"
                    className="btn-forgot"
                    onClick={handleForgotPassword}
                  >
                    <h1 className="forgot">Forgot password?</h1>
                  </button>
                </div>

                <button
                  type="submit"
                  className="login-btn my-4"
                  style={{ outline: "none" }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <div style={{ padding: "10px" }}>
                      <ButtonLoader />
                    </div>
                  ) : (
                    "Log in"
                  )}
                </button>
                {open && (
                  <ResetModal open={open} closeModal={() => setOpen(false)} />
                )}
              </Form>
            )}
          </Formik>
          <div className="or my-4">OR</div>
          <ul className="signup-options my-4">
            {/* <LoginSocialGoogle
              client_id={google_client_id}
              scope="openid profile email"
              onResolve={({ data }) => handleGoogleLogin(data)}
              onReject={(provider, data) => console.log(provider, data)}
              
            > */}
            <li onClick={logGoogleUser}>
              <LoginGoogle />
            </li>
            {/* </LoginSocialGoogle> */}

            {/* <LoginSocialFacebook
              appId={facebook_app_id}
              onResolve={handleFacebookLogin}
              fieldsProfile={
                "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
              }
              onLoginStart={onLoginStart}
              onLogoutSuccess={onLogoutSuccess}
              redirect_uri="https:localhost:8081/user-profile"
              onReject={(err) => {
                console.log(err);
              }}
            >
              <li>
                <LoginFacebook />
              </li>
            </LoginSocialFacebook> */}
            {/* <li>
              <LoginApple />
            </li> */}
          </ul>
          <div className="signup-text">
            Dont have an account? <Link to="/user-info">Sign up</Link>
          </div>
          {/* <div className="terms-text">
            By signing up you agree to our <Link to="#">Terms</Link>,{" "}
            <Link to="#">Privacy Policy</Link>, and{" "}
            <Link to="#">Cookie Use</Link>.
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Login;
