import React from "react";
import "./CrewNearYou.scss";
import { isValidUrl } from "@utils/validUrl";
import { capitalizeAndAddSpace } from "@utils/capitalizeAddSpace";
import { Link } from "react-router-dom";
import NearYouRecomendedSkeleton from "@components/Skeleton/NearYouRecomendedSkeleton";
import { DefaultImg, TrendingIcon, LocalIcon } from "@utils/defaultImages";
import {
  useGetNearCrewQuery,
  useGetRecomendedCrewsQuery,
} from "@redux/services/crewService/allCrewService";
import SearchAllCrew from "./SearchAllCrew";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";

const CrewNearYou = ({ isMobile, isTablet }) => {
  const { data: { data: nearCrew } = {}, isLoading } = useGetNearCrewQuery();

  const { data: { data } = {}, isLoading: Loading } =
    useGetRecomendedCrewsQuery();
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {" "}
      {/* Find Your Crew */}
      <div>
        <div className="crews">
          <h1>Find Your Crew</h1>
          <p>Connect, Share, Collaborate</p>
        </div>
        <div className="quick-question">
          {nearCrew?.docs?.length > 0 && (
            <div
              className="trending"
              onClick={() => handleClickScroll("near-you")}
            >
              <LocalIcon />
              <span>Near you</span>
            </div>
          )}
          {data?.docs?.length > 0 && (
            <div
              className="trending"
              onClick={() => handleClickScroll("recommended-view")}
            >
              <TrendingIcon />
              <span>Recommended</span>
            </div>
          )}

          {/* <div className="trending">
            <AutoMessageIcon />
            <span>Auto Talk</span>
          </div>
          <div className="trending">
            <NewIcon />
            <span>New</span>
          </div>
          <div className="trending">
            <QuestionIcon />
            <span>Questions & Answers</span>
          </div> */}
        </div>
        {/* search */}
        <SearchAllCrew />
        <div className="cate-gories">
          <h3>Categories</h3>
        </div>
      </div>
      {isLoading ? (
        isMobile || isTablet ? (
          <SpinnerLoader />
        ) : (
          Array.from({ length: 3 }).map(
            (
              _,
              index //  remove duplication of section
            ) => <NearYouRecomendedSkeleton key={index} />
          )
        )
      ) : (
        nearCrew?.docs?.length > 0 && (
          <>
            <div className="view-all-heading" id="near-you">
              <h4 className="d-flex gap-2">
                <span className="location">
                  <LocalIcon className="mb-3" />
                </span>
                {capitalizeAndAddSpace("Near you")}{" "}
              </h4>
              {nearCrew?.docs?.length > 6 && (
                <Link
                  to={`/crews/list`}
                  state={{
                    nearYou: 1,
                  }}
                >
                  View All
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.25 11.25L10 7.5L6.25 3.75"
                      stroke="#387AFF"
                      stroke-linecap="round"
                    />
                  </svg>
                </Link>
              )}
            </div>{" "}
            <div className="crew-garage-row">
              {nearCrew?.docs?.slice(0, 6)?.map((item) => {
                return (
                  <Link
                    style={{ color: "#fff" }}
                    to={`/crew-post/${item?._id}`}
                  >
                    <div className="garage-block">
                      <img
                        src={
                          isValidUrl(item?.bannerImage)
                            ? item?.bannerImage
                            : DefaultImg
                        }
                      // alt={`${item?.make}-${item?.model}-${item?.year?.value}`}
                      />
                      <div className="info">
                        <div className="model">{item?.name}</div>
                        <div className="tags">
                          {item?.hashtags?.map((hashtag, index) => {
                            if (index > 2) return null;
                            return (
                              <>
                                <p>{hashtag}</p>
                                {index !== item?.hashtags?.length - 1 && index < 2 && (
                                  <p className="px-1">•</p>
                                )}
                              </>
                            );
                          })}
                        </div>

                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </>
        )
      )}
      {/* Recommended */}
      {data?.docs?.length > 0 && (
        <>
          <div className="view-all-heading" id="recommended-view">
            <h4 className="d-flex gap-2">
              {" "}
              <span className="location">
                <TrendingIcon className="mb-3" />
              </span>{" "}
              {capitalizeAndAddSpace("Recommended")}{" "}
            </h4>
            {data?.docs?.length > 6 && (
              <Link
                to={`/crews/list`}
                state={{
                  nearYou: 0,
                }}
              >
                View All
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.25 11.25L10 7.5L6.25 3.75"
                    stroke="#387AFF"
                    stroke-linecap="round"
                  />
                </svg>
              </Link>
            )}
          </div>{" "}
          <div className="crew-garage-row">
            {data?.docs?.slice(0, 6)?.map((item) => (
              <Link style={{ color: "#fff" }} to={`/crew-post/${item?._id}`}>
                <div className="garage-block">
                  <img
                    src={
                      isValidUrl(item?.bannerImage)
                        ? item?.bannerImage
                        : DefaultImg
                    }
                    alt={`${item?.make}-${item?.model}-${item?.year?.value}`}
                  />
                  <div className="info">
                    <div className="model">{item?.name}</div>
                    <div className="tags">
                      {item?.hashtags?.map((hashtag, index) => {
                        if (index > 2) return null;
                        return (
                          <>
                            <p>{hashtag}</p>
                            {index !== item?.hashtags?.length - 1 && index < 2 && (
                              <p className="px-1">•</p>
                            )}
                          </>
                        );
                      })}
                    </div>
                    {/* <div className="name">MeetUp</div> */}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default CrewNearYou;
