import { createApi } from "@reduxjs/toolkit/query/react";
import  baseQueryWithReauth  from "../baseQuery";

export const componentPageApi = createApi({
  reducerPath: "componentPageApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getReviewDataById: builder.query({
      query: (componentId) => ({
        url: `/posts/component/get-review/${componentId}`,
        method: "GET",
      }),
    }),
    getShowCase: builder.query({
      query: (componentId) => ({
        url: `/posts/component/show-case/${componentId}`,
        method: "GET",
      }),
    }),
    getAllRelatedPost: builder.query({
      query: ({componentId, page}) => ({
        url: `/posts/component/get-by-type/${componentId}`,
        method: "GET",
        params: {
          page: page,
          type: 3
        }
      }),
    }),
  }),
});

export const { useGetReviewDataByIdQuery, useGetShowCaseQuery, useLazyGetAllRelatedPostQuery } =
  componentPageApi;
