import React, { useRef, useState } from "react";
import SiteLogo from "@assets/site-logo.svg";
import "./Signup.scss";
import { ReactComponent as Back } from "@assets/back.svg";
import DefaultUserImg from "@assets/defaultUser.png";
import { ReactComponent as TickIcon } from "@assets/TickIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import PopularLoader from "./Loader/PopularLoader";
import ButtonLoader from "@components/Loaders/ButtonLoader";
import {
  useFollowAccountMutation,
  useGeatherUserInfoMutation,
  usePopularAccountsQuery,
} from "@redux/services/auth/signUpService";
import { setAuth, setLoginData } from "@redux/slices/authSlice/loginSlice";
import { toast } from "react-toastify";
import { clearTokenAuth } from "@redux/slices/authSlice/tokenSlice";
import { useNavigate } from "react-router";
import moment from "moment";

const PopularAccounts = () => {
  const scrollContainerRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const geatherAllData = useSelector((state) => state?.signUp);
  const [followToUserId, setFollowToUserId] = useState([]);
  const userToken = useSelector(
    (state) => state?.temporaryAuth?.userTemporaryAuthInfo
  );
  // console.log("image---???", geatherAllData);

  const [page, setPage] = useState(1);
  const { data, isLoading } = usePopularAccountsQuery(page);
  const [followAccount, { isLoading: loading }] = useFollowAccountMutation();
  const [geatherUserInfo, { isLoading: loader }] = useGeatherUserInfoMutation();
  //   const handleScroll = () => {
  //     const scrollContainer = scrollContainerRef.current;

  //     if (
  //       scrollContainer.scrollTop + scrollContainer.clientHeight >=
  //         scrollContainer.scrollHeight ||
  //       loading
  //     ) {
  //       popularAccounts();
  //     }
  //   };

  //   useEffect(() => {
  //     const scrollContainer = scrollContainerRef.current;
  //     scrollContainer.addEventListener("scroll", handleScroll);
  //     return () => {
  //       scrollContainer.removeEventListener("scroll", handleScroll);
  //     };
  //   }, [page]);

  const handleClick = (id) => {
    followToUserId.push(id);
    setFollowToUserId([...followToUserId]);
  };
  const handleSubmit = async () => {
    let DOB = `${geatherAllData?.regUserValueGenInfo?.dateOfBirth?.month} ${geatherAllData?.regUserValueGenInfo?.dateOfBirth?.day}`;
    let formData = new FormData();
    formData.append("profileImage", geatherAllData?.GenSelectedFile?.data);
    formData.append("bio", geatherAllData?.BioDescGen);
    formData.append("dateOfBirth", moment(DOB).format("L") || "");
    formData.append("gender", geatherAllData?.regUserValueGenInfo?.gender);
    formData.append(
      "location",
      JSON.stringify(geatherAllData?.regUserValueGenInfo?.location)
    );
    formData.append("isCompleted", true);
    // const obj = {
    //   profileImage: formData,
    //   bio: geatherAllData?.signUp?.BioDescGen,
    //   dateOfBirth: geatherAllData?.signUp?.dateOfBirth,
    //   gender: geatherAllData?.signUp?.gender,
    //   location: geatherAllData?.signUp?.location,
    //   isCompleted: true,
    // };

    try {
      // First API call
      const followUsersResult = await followAccount({
        followToUserId: followToUserId,
      }).unwrap();

      if (followUsersResult.error) {
        throw followUsersResult.error;
      }

      // Second API call only if the first one succeeds
      const userInfoResult = await geatherUserInfo(formData).unwrap();
      if (userInfoResult.code === 200) {
        dispatch(setAuth(userToken));
        dispatch(setLoginData(userInfoResult.data.user));
        dispatch(clearTokenAuth());
        // navigate("/main-feed")
      }
    } catch (error) {
      toast.error(error?.data?.message || error.message);
    }
  };

  return (
    <div>
      <div className="signup-container">
        <div
          className="signup-wrapper"
          // style={{ maxWidth: "600px", flex: "0 0 600px", width: "100%" }}
        >
          <img alt="alt78" src={SiteLogo} className="logo" />
          <div className="lets-go">You are all set!</div>
          <div className="enter-text">
            Here are some popular accounts you may want to follow.
          </div>
          <div
            className="form-group"
            style={{ overflowY: "scroll", height: "30vh" }}
            ref={scrollContainerRef}
          >
            <div className="follow-accounts">
              {isLoading ? (
                <>
                  <PopularLoader />
                </>
              ) : (
                data?.data?.docs?.map((item, index) => {
                  return (
                    <label key={index} onChange={() => handleClick(item?._id)}>
                      <input type="checkbox" name="car" />
                      <span className="follow">
                        <TickIcon />
                      </span>
                      <div className="account-img">
                        <img
                          alt="alt78"
                          src={item?.profileImage ?? DefaultUserImg}
                        />
                      </div>
                      <div className="name">{item?.username?.slice(0, 14)}</div>
                    </label>
                  );
                })
              )}
            </div>
          </div>

          <button
            className="next-btn"
            // disabled={!loader}
            onClick={handleSubmit}
          >
            {loader || loading ? (
              <div>
                <ButtonLoader />
              </div>
            ) : (
              "Done"
            )}
          </button>

          <div className="ft-buttons">
            <div className="back-btn" onClick={() => navigate(-1)}>
              <Back />
              Go Back
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PopularAccounts;
