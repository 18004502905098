import React, { useState } from "react";
import CarProfileInfo from "./components/CarPorfileInfo";
import { useParams } from "react-router-dom";
import "./CarDetail.scss";
export default function CarDetail() {
  const [activeIndex, SetActiveIndex] = useState(0);
  return (
    <>
      <div>
        <CarProfileInfo
          SetActiveIndex={SetActiveIndex}
          activeIndex={activeIndex}
        />
      </div>
    </>
  );
}
