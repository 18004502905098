import React, { useState } from "react";
import YourCrewsTab from "./YourCrewsTab";
import "./Followerslist.scss";
import ModeratorOfList from "./TabSwitch/ModeratorOfList";
import MemberOfList from "./TabSwitch/MemberOfList";

export default function FollowersFollowing() {
  const [tabOpen, setTabOpen] = useState(1);
  return (
    <div className="crew-post-Wrapper follow-followers">
      <div>
        <YourCrewsTab tabOpen={tabOpen} setTabOpen={setTabOpen} />
        {tabOpen === 1 && <ModeratorOfList />}
        {tabOpen === 2 && <MemberOfList />}
      </div>
    </div>
  );
}
