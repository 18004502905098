import React from "react";
import WrapperModal from "./WrapperModal";
import CheckYourEmail from "@containers/Auth/ForgotPassword/CheckYourEmail";

const CheckEmailModal = React.memo(
  ({ checkEmail, closeModal, userData, resetModal }) => {
    if (!checkEmail) return null;

    return (
      <WrapperModal
        open={checkEmail}
        closeModal={closeModal}
        style={{
          top: "50%",
          transform: "translateY(-50%)",
          left: 0,
          right: 0,
          margin: "0 auto",
          minHeight: "700px",
          maxWidth:"100%"  
        }}
      >
        <CheckYourEmail
          userData={userData}
          closeModal={closeModal}
          resetModal={resetModal}
        />
      </WrapperModal>
    );
  }
);

export default CheckEmailModal;
