import React from "react";
import LandingFooter from "@components/layouts/LandingFooter";
import LandingHeader from "@components/layouts/LandingHeader";
import useMediaQuery from "@utils/hooks/useMediaQueryHook";
import { FaRegEnvelope } from "react-icons/fa";
import "./contactus.scss";
import ContactForm from "@components/ContactForm";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";

const ContactUs = () => {
  const isMobile = useMediaQuery({ minWidth: 425, maxWidth: 599 });
  const isMobileSmall = useMediaQuery({ minWidth: 320, maxWidth: 424 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isTabScr = useMediaQuery({ minWidth: 1024, maxWidth: 1320 });
  const isMobTab = useMediaQuery({ minWidth: 600, maxWidth: 767 });
  return (
    <>
      <LandingHeader />
      <div className="contact-page">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 px-4 py-5 d-flex flex-column justify-content-center every-car">
              {/* <HeroLogo /> */}
              <div className="title pb-4">
                <h1 className="py-4">Have a question?</h1>
                <p>
                  Please fill out the form below and we'll get back to you as
                  soon as possible.
                </p>
              </div>

              <div className="d-flex align-items-center gap-2 my-3">
                <FaRegEnvelope />
                <span>support@carvonix.com</span>
              </div>
              {/* <div className="d-flex align-items-center gap-2">
                <FaPhoneAlt />
                <span>@gmail.com</span>
              </div> */}
              <div className="d-flex align-items-center gap-2">
                <FaLocationDot />
                <span>1400 E 69th Ave 80229 Denver Colorado</span>
              </div>

              {/* <div className="video-responsive">
                <iframe
                  src="https://player.vimeo.com/video/1014971040?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1&amp;loop=1&amp;muted=1"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  loading="lazy"
                ></iframe>
              </div> */}
            </div>

            <div
              className={`col-lg-6 col-md-12 col-sm-12 col-12 py-5 launches ${
                isTablet || isMobTab
                  ? "d-flex justify-content-center"
                  : "d-flex justify-content-end"
              }`}
            >
              <ContactForm />
            </div>
          </div>
        </div>
      </div>

      <LandingFooter />
    </>
  );
};

export default ContactUs;
