import React, { useEffect, useState } from "react";
import "./EventComments.scss";
import { toast } from "react-toastify";
import { useLazyListAllCommentsQuery } from "@redux/services/commentsService/commentService";
import GetComments from "./components/GetComments";

const EventComments = ({ post = {}, isTablet, postId }) => {
  // {API CALLS}
  const [listAllComments, { data: { data = [] } = [], isLoading, isFetching }] =
    useLazyListAllCommentsQuery();
  //

  // {USESTATES}
  const [page, setPage] = useState(1);

  // { FUNCTIONS }
  const showCommentsList = async () => {
    const body = {
      postId: post?._id,
      page: page,
    };

    try {
      const response = await listAllComments(body).unwrap();
      if (response?.data?.hasNextPage) {
        setPage(response?.data?.nextPage);
      }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };
  // Handle updating comments data and count
  const handleNewComment = () => {
    // setCommentsCount((prevCount) => prevCount + 1);
  };
  /**
   * USEEFFECT
   */
  useEffect(() => {
    showCommentsList();
  }, []);
  return (
    <>
      <div className="comments-wrapper">
        <GetComments
          dataComments={data}
          // storePrevComment={allComments}
          isTablet={isTablet}
          Loading={isLoading}
          postId={postId}
          onNewComment={handleNewComment}
          loadMore={showCommentsList}
          fetchingNextPageData={isFetching}
        />
      </div>
    </>
  );
};

export default EventComments;
