import React from "react";

const YourCrewsTab = ({ tabOpen, setTabOpen }) => {
  const tabs = [
    { id: 1, label: "Moderator of" },
    { id: 2, label: "Member of" },
  ];
  return (
    <ul class="nav nav-pills mb-3">
      {tabs?.map((item) => (
        <li class="nav-item" key={item?.id}>
          <button
            class={`nav-link ${item?.id === tabOpen ? "active" : ""}`}
            onClick={() => setTabOpen(item?.id)}
          >
            {item?.label}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default YourCrewsTab;
