import React, { Component } from "react";
import CtaImg from "@assets/cta-img.png";

import "./CallToAction.scss";
import { AppStore, GooglePlay } from "@utils/defaultImages";

export default class CallToAction extends Component {
  render() {
    return (
      <div className="cta-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="info">
                <h2>Call to action download the app</h2>
                <p>
                  Everything you need to know about car builds and upgrades, all
                  in one place...
                </p>
                <a href="/" className="d-flex align-items-center">
                  Learn more <i className="ms-2 fa-solid fa-arrow-right"></i>
                </a>
                <div className="d-flex">
                  <a href="/">
                    <AppStore className="me-3"/>
                  </a>
                  <a href="/">
                    <GooglePlay />{" "}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={CtaImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
