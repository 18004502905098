import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
});

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery,
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (user) => ({
        url: "auth/login",
        method: "POST",
        body: user,
      }),
    }),
    resetEmail: builder.mutation({
      query: (value) => ({
        url: "/auth/forgot-password",
        method: "POST",
        body: value,
      }),
    }),
    verifyOtp: builder.mutation({
      query: (value) => ({
        url: "/auth/reset-password/verify-otp",
        method: "POST",
        body: value,
      }),
    }),
    resetPassword: builder.mutation({
      query: (value) => ({
        url: "/auth/reset-password",
        method: "POST",
        body: value,
      }),
    }),
    logoutApp: builder.mutation({
      query: (value) => ({
        url: "/auth/logout",
        method: "POST",
        body: value,
      }),
    }),
    googleLogin: builder.mutation({
      query: (value) => ({
        url: "/auth/login/social",
        method: "POST",
        body: value,
        headers: {
          "Content-Type": "application/json",
          carvonixToken: process.env.REACT_APP_CARVONIX_TOKEN,
        },
      }),
    }),
  }),
});

export const {
  useLoginUserMutation,
  useResetEmailMutation,
  useVerifyOtpMutation,
  useResetPasswordMutation,
  useLogoutAppMutation,
  useGoogleLoginMutation,
} = loginApi;
