import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userSignUpInfo: {},
  regUserValueGenInfo: {},
  GenSelectedFile: { preview: "", data: "" },
  BioDescGen: "",
  regRefreshToken: {},
};

const signUpSlice = createSlice({
  name: "signUp",
  initialState,
  reducers: {
    setUserInfo(state, action) {
      state.userSignUpInfo = action.payload;
    },
    setGeneralInfo(state, action) {
      state.regUserValueGenInfo = action.payload;
    },
    setGenSelectedFile(state, action) {
      state.GenSelectedFile = action.payload;
    },
    setBioDescGen(state, action) {
      state.BioDescGen = action.payload;
    },
    setRegRefreshToken(state, action) {
      state.regRefreshToken = action.payload;
    },
    SetclearRegToken(state) {
      state.regRefreshToken = {};
    },
    SetclearSignUpInfo(state) {
      state.userSignUpInfo = {};
    },
  },
});

export const {
  setUserInfo,
  setGeneralInfo,
  setBioDescGen,
  setGenSelectedFile,
  setRegRefreshToken,
  SetclearRegToken,
  SetclearSignUpInfo,
} = signUpSlice.actions;
export default signUpSlice;
