import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { getOnlyDate, getOnlyMonth, getOnlyTime } from "utils/DateFormatter";
import { BannerImg, UserImgAvatar, MapImg } from "utils/defaultImages";
import { useGetUpcomingEventsQuery } from "@redux/services/crewService/allCrewService";
import { isValidUrl } from "@utils/validUrl";
import { Link } from "react-router-dom";

const UpcomingEvents = ({ crewData = {}, user = {} }) => {
  const { crewId } = useParams();
  /**
   * API CALLS
   */
  const {
    data: { data: { events: { docs: upCommingEvents } = [] } = {} } = {},
  } = useGetUpcomingEventsQuery(crewId);
  //
  const checkPrivacy = !!(
    crewData?.userId !== user?._id &&
    crewData?.privacy &&
    !crewData?.isJoin
  );
  // console.log("upCommingEvents", upCommingEvents);
  return (
    <div>
      {checkPrivacy
        ? null
        : upCommingEvents?.length > 0 && (
            <>
              <div className="upcoming-events">Upcoming Events</div>
              {upCommingEvents?.slice(0, 3)?.map((event, index) => (
                <Link
                  key={index}
                  className="coming-events"
                  to={`/crew-event/detail/${event?._id}`}
                >
                  <div className="date">
                    <div className="month">
                      {getOnlyMonth(event?.startDateTime).substring(0, 3)}
                    </div>
                    <div className="date-text">
                      {getOnlyDate(event?.startDateTime)}
                    </div>
                  </div>
                  <div className="eve-info">
                    <div className="meet">
                      Meetup - {getOnlyTime(event?.startDateTime)}
                    </div>
                    <div className="loaction">{event?.location?.name}</div>
                    <div className="attending-imgs">
                      <div className="img-holder">
                        <img
                          src={
                            isValidUrl(event?.bannerImage)
                              ? event?.bannerImage
                              : BannerImg
                          }
                          className="post-img"
                        />
                        <img
                          src={
                            event?.profileImage
                              ? event?.profileImage
                              : UserImgAvatar
                          }
                          className="post-img"
                        />
                        <img src={BannerImg} className="post-img" />
                      </div>
                      <div className="attending-text">5 attending</div>
                    </div>
                  </div>
                  <div>
                    <img src={MapImg} alt="" className="map-img" />
                  </div>
                </Link>
              ))}
            </>
          )}
    </div>
  );
};

export default UpcomingEvents;
