import React, { useCallback, useEffect, useState } from "react";
import "@containers/searchcomponent/SearchPage.scss";
import { useLazySearchExplorePostsQuery } from "@redux/services/exploreService/exploreService";
import { Link } from "react-router-dom";
import Comments from "@components/Comments/Comments";
import { isValidUrl } from "@utils/validUrl";
import { DefaultImg, DefaultUserImg } from "@utils/defaultImages";
import { useMediaQuery } from "react-responsive";
import { useDebounce } from "use-debounce";
import { toast } from "react-toastify";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import NotFound from "@components/NotFound";

const ExplorePosts = ({ categorySearch }) => {
  const isMobile = useMediaQuery({ maxWidth: 599 });
  /**
   * USESTATES
   */
  const [allPosts, setAllPosts] = useState([]);
  const [page, setPage] = useState(1);
  /**
   * DEBOUNCE
   */
  const [debouncedSearch] = useDebounce(categorySearch, 1000);
  /**
   * API CALLS
   */
  const [
    listAllPosts,
    { data: { data: allExplorePosts } = {}, isLoading, isFetching },
  ] = useLazySearchExplorePostsQuery();

  /**
   * FUNCTIONS
   */
  const fetchPosts = async () => {
    const value = {
      page: page,
      search: debouncedSearch,
    };
    try {
      const res = await listAllPosts(value).unwrap();
      setAllPosts((prevItem) =>
        page === 1 ? res?.data?.docs : [...prevItem, ...res?.data?.docs]
      );
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };
  const loadMore = useCallback(() => {
    if (!isFetching && allExplorePosts?.nextPage) {
      setPage(allExplorePosts?.nextPage);
    }
  }, [isFetching, allExplorePosts?.nextPage]);
  /**
   * USEEFFECT
   */
  useEffect(() => {
    fetchPosts();
  }, [page, debouncedSearch]);

  useEffect(() => {
    setPage(1);
    setAllPosts([]);
  }, [debouncedSearch]);
  return (
    <>
      <div className="view-all-heading">
        <h2>Posts </h2>
      </div>
      <InfiniteScroll
        style={{ overflow: "hidden" }}
        dataLength={allPosts?.length}
        next={loadMore}
        hasMore={!!allExplorePosts?.nextPage}
        loader={page !== 1 && <SpinnerLoader />}
      >
        {allPosts?.length === 0 && isFetching ? (
          <SpinnerLoader />
        ) : allExplorePosts?.totalDocs === 0 ? (
          <NotFound message="posts" />
        ) : (
          <div className="Trendingparts pb-0">
            <div className="row">
              {allPosts?.map((item, index) => (
                <div
                  className="col-lg-6 col-md-6"
                  key={index}
                  style={{ paddingLeft: 0 }}
                >
                  <div className="trending-part-block">
                    <Link to={`/post/${item._id}`}>
                      <div className="vehicle-avtar">
                        <div className="img-holders">
                          <img
                            src={
                              isValidUrl(item?.user?.profileImage)
                                ? item?.user?.profileImage
                                : DefaultUserImg
                            }
                            alt="profile-image"
                            style={{ marginLeft: "unset" }}
                          />
                          {item?.build && (
                            <img
                              src={
                                isValidUrl(item?.build?.bannerImage)
                                  ? item?.build?.bannerImage
                                  : DefaultImg
                              }
                              alt={`${item?.build?.make}-${item?.build?.model}-${item?.build?.year?.value}`}
                            />
                          )}
                        </div>
                        <div className="name-info">
                          <div className="name">
                            {item?.user?.firstName}
                            {item?.user?.lastName}
                          </div>
                          <p>{item?.user?.username}</p>
                        </div>
                      </div>
                      <img
                        src={
                          item?.media?.[0]?.type === "2"
                            ? item.media[0].thumbnail || DefaultImg
                            : isValidUrl(item?.media?.[0]?.path)
                            ? item.media[0].path
                            : DefaultImg
                        }
                        alt="Media Content"
                        className="main-img"
                      />
                    </Link>
                    {/* COMMENTS SHOW */}
                    <Comments
                      post={item}
                      commentsCount={item?.commentsCount}
                      postId={item?._id}
                      // isTablet={isTablet}
                      isComponent={false}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </InfiniteScroll>
    </>
  );
};

export default ExplorePosts;
