import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./ContactForm.scss";
import { useContactToTeamMutation } from "@redux/services/public/publicService";
import { toast } from "react-toastify";
import { Turnstile } from "@marsidev/react-turnstile";
import { Link } from "react-router-dom";

const ContactForm = () => {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
  });

  /**
   * API CALL
   */
  const [contactTeam, { isLoading }] = useContactToTeamMutation();

  return (
    <>
      <div className="Contact-form">
        <div className="line-pass pt-5 pb-2">
          <h4>Contact Us</h4>
          {/* <p>
            Sign up to be first in line when the app launches later this year.
          </p> */}
        </div>
        <div className="pass-input px-2">
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              subject: "",
              message: "",
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              try {
                const response = await contactTeam(values).unwrap();
                if (response?.code === 200) {
                  toast.success(
                    "Thank you! Our representative will contact you shortly"
                  );
                  resetForm();
                }
              } catch (err) {
                toast.error(err?.data?.message);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="form-data my-3">
                  <Field
                    type="text"
                    name="firstName"
                    className="form-control-lg bg-transparent custom-input"
                    placeholder="First Name"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="form-data my-3">
                  <Field
                    type="text"
                    name="lastName"
                    className="form-control-lg bg-transparent custom-input"
                    placeholder="Last Name"
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="form-data">
                  <Field
                    type="email"
                    name="email"
                    className="form-control-lg bg-transparent custom-input"
                    placeholder="Email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="form-data my-3">
                  <Field
                    type="text"
                    name="subject"
                    className="form-control-lg bg-transparent custom-input"
                    placeholder="Subject"
                  />
                  <ErrorMessage
                    name="subject"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="form-data my-3">
                  <Field
                    type="text"
                    name="message"
                    className="form-control-lg bg-transparent custom-input custom-textarea"
                    placeholder="Message"
                  />
                  <ErrorMessage
                    name="message"
                    component="div"
                    className="error"
                  />
                </div>

                {/* <div className="submit px-3 pt-3">
                  <p>
                    By submitting this form and signing up for texts, you
                    consent to receive marketing text messages (e.g. promos,
                    cart reminders) from Carvonix at the number provided,
                    including messages sent by autodialer. Consent is not a
                    condition of purchase. Msg & data rates may apply. Msg
                    frequency varies. Unsubscribe at any time by replying STOP
                    or clicking the unsubscribe link (where available).{" "}
                    <Link to="/privacy-policy">
                      <span>Privacy Policy</span>
                    </Link>{" "}
                    &{" "}
                    <Link to="/terms-and-conditions">
                      <span>Terms</span>
                    </Link>
                    .
                  </p>
                </div> */}
                <Turnstile
                  siteKey={process.env.REACT_APP_RECAPTCHA_KEY_SECRET}
                  className="d-flex justify-content-center mt-3"
                  onError={() => console.log("CAPTCHA verification failed")}
                  onSuccess={() => console.log("CAPTCHA verification Success")}
                />
                <div className="submit-btn py-4">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={isSubmitting || isLoading}
                  >
                    {isLoading ? "Loading..." : "Submit"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
