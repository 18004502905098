import React from 'react'
import {ReactComponent as NotFounDData} from "@assets/not-found.svg" 
const NotFound = ({message=""}) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "auto",
          alignItems: "center",
          flexDirection: "column",
          marginTop:"10px"
        }}
      >
        <NotFounDData />
        <div className="mt-2" style={{ color: "#8E8E93" }}>
          No {message} Found
        </div>
      </div>
    );
}

export default NotFound;