import React, { useState } from "react";
import "./SpecsFeatures.scss";
import { useParams } from "react-router";
import SubCategories from "./SubCategories";
import ModsSkeleton from "@components/Skeleton/ModsSkeleton";
import { useGetAllModsCategoriesQuery } from "@redux/services/userProfile/UserProfileService";
const ModsBuild = ({ activeIndex, SetActiveIndex, type }) => {
  const { Id: vehicleId } = useParams();
  /**
   * API CALLS
   */
  const { data: { data: { categories } = {} } = [], isLoading } =
    useGetAllModsCategoriesQuery(vehicleId);
  /**
   * USESTATES
   */
  const [categoriesValues, setCategoriesValues] = useState({});
  const [page, setPage] = useState(1);
  /**
   * FUNCTIONS
   */
  const handleValues = (categoryItm, index) => {
    if (categoryItm?.totalCategoryComponents > 0)
      setCategoriesValues(categoryItm);
    SetActiveIndex(index);
    setPage(1);
  };
  return (
    <React.Fragment>
      {isLoading ? (
        <ModsSkeleton />
      ) : (
        <>
          <div className="mods">
            <div className="heading">
              <h1>Mods</h1>
            </div>
            <div className="parent-wrap">
              {categories?.map((categoryItm, index) => {
                return (
                  <button
                    disabled={categoryItm?.totalCategoryComponents <= 0}
                    className="categoties"
                    key={categoryItm?._id}
                    style={{
                      border:
                        categoryItm?.totalCategoryComponents &&
                        activeIndex === index
                          ? `1px solid ${type ? "#387AFF" : "#E56424"}`
                          : "transparent",
                    }}
                    onClick={() => handleValues(categoryItm, index)}
                  >
                    {categoryItm?.name && (
                      <div className="inner-categoties">
                        <p>{categoryItm?.name}</p>
                        <img
                          src={require("@assets/categories/" +
                            categoryItm?.name +
                            ".svg")}
                          alt="img78"
                          style={{
                            filter:
                              categoryItm?.totalCategoryComponents > 0
                                ? "opacity(1) drop-shadow(0 0 0 white)"
                                : "opacity(0.2) drop-shadow(0 0 0 grey)",
                          }}
                        />
                      </div>
                    )}

                    {categoryItm?.totalCategoryComponents > 0 && (
                      <div className="count">
                        <h6 style={{ color: type ? "#387AFF" : "#E56424" }}>
                          {categoryItm?.totalCategoryComponents}
                        </h6>
                      </div>
                    )}
                  </button>
                );
              })}
            </div>
          </div>
          {categories && (
            <SubCategories
              categoriesValues={categoriesValues}
              vehicleId={vehicleId}
              categories={categories}
              activeIndex={activeIndex}
              setPage={setPage}
              page={page}
              typeBuild={type}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default ModsBuild;
