import React, { useState, useEffect, useCallback } from "react";
import UserScroll from "./UserScroll";
import searchIcon from "@assets/search-Icon.svg";
import ViewInbox from "./ViewInbox";
import MessengerSkeleton from "@components/Skeleton/MessengerSkeleton";
import { useLazyListUserRoomQuery } from "@redux/services/messenger/messengerService";
import { useDebounce } from "use-debounce";
import { toast } from "react-toastify";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import NotFound from "@components/NotFound";

const ViewMessages = () => {
  /**
   * USESTATE
   */
  const [page, setPage] = useState(1);
  const [inboxList, setInboxList] = useState([]);
  const [inputSearch, setInputSearch] = useState("");
  /**
   * DEBOUNCE
   */
  const [debouncedSearch] = useDebounce(inputSearch, 1000);
  /**
   * API CALL
   */
  const [listUsers, { data: { data: inboxChat = {} } = [], isFetching }] =
    useLazyListUserRoomQuery();
  /**
   * FUNCTIONS
   */
  const handleChange = (e) => {
    setInputSearch(e.target.value);
    setPage(1);
    setInboxList([]);
  };

  const showUsers = async () => {
    const params = {
      page: page,
      searchBy: debouncedSearch,
    };

    try {
      const response = await listUsers(params).unwrap();
      setInboxList((prevItems) =>
        page === 1
          ? response?.data?.docs
          : [...prevItems, ...response?.data?.docs]
      );
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  const loadMore = useCallback(() => {
    if (!isFetching && inboxChat?.nextPage) {
      setPage(inboxChat?.nextPage);
    }
  }, [isFetching, inboxChat?.nextPage]);
  /**
   * USEEFEECT
   */
  useEffect(() => {
    showUsers();
  }, [debouncedSearch, page]);

  return (
    <>
      <div className="user-inbox">
        <UserScroll />
        <div className="search-container ">
          <img src={searchIcon} alt="Search Icon" className="search-icon" />
          <input
            value={inputSearch}
            type="search"
            className="form-control"
            onChange={handleChange}
            placeholder="Search People"
          />
        </div>
        <InfiniteScroll
          style={{ overflow: "hidden" }}
          dataLength={inboxList?.length}
          next={loadMore}
          hasMore={!!inboxChat?.nextPage}
          loader={page !== 1 && <SpinnerLoader />}
          scrollableTarget="nested-comp"
        >
          <div className="nested-list" id="nested-comp">
            {inboxList?.length < 1 && isFetching ? (
              [1, 2, 3].map(() => <MessengerSkeleton />)
            ) : inboxChat?.totalDocs === 0 ? (
              <NotFound message="Chat" />
            ) : (
              <ViewInbox items={inboxList} />
            )}
          </div>
        </InfiniteScroll>
      </div>
    </>
  );
};

export default ViewMessages;
