import React from "react";
import "./CommentLoader.scss";
const CommentLoader = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <span class="cmmnt-loader"></span>
    </div>
  );
};

export default CommentLoader;
