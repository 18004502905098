import React from "react";

import ContentLoader from "react-content-loader";

const ModsSkeleton = () => (
  <ContentLoader
    viewBox="0 0 400 30"
    backgroundColor="#ababab"
    foregroundColor="#fafafa"
    speed={2}
  >
    {/* Rectangle for "All" filter */}
    <rect x="0" y="0" rx="3" ry="3" width="70" height="30" />

    {/* Rectangles for dynamic filters */}
    <rect x="78" y="0" rx="3" ry="3" width="70" height="30" />
    <rect x="156" y="0" rx="3" ry="3" width="70" height="30" />
    <rect x="235" y="0" rx="3" ry="3" width="70" height="30" />
    {/* Add more rectangles based on your design */}
  </ContentLoader>
);
export default ModsSkeleton;
