import React from "react";
import ContentLoader from "react-content-loader";
import { useMediaQuery } from "react-responsive";

const InstagramFeedMain = (props) => {
  const isTargetSmallMobile = useMediaQuery({ minWidth: 320, maxWidth: 381 });

  const isMobile = useMediaQuery({ minWidth: 382, maxWidth: 599 });
  const isSmallTablet = useMediaQuery({ minWidth: 600, maxWidth: 766 });
  const isTablet = useMediaQuery({ minWidth: 767, maxWidth: 1023 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  const getViewBox = () => {
    if (isTargetSmallMobile) return "0 0 380 400";
    if (isMobile) return "0 0 380 400";
    if (isSmallTablet) return "0 0 420 550";
    if (isTablet) return "0 0 600 450";
    return "0 0 700 550"; // Default for desktop
  };

  const getHeight = () => {
    if (isTargetSmallMobile) return 320;
    if (isMobile) return 400;
    if (isSmallTablet) return 550;
    if (isTablet) return 450;
    return 550; // Default for desktop
  };

  const getWidth = () => {
    if (isTargetSmallMobile) return 310;
    if (isMobile) return 380;
    if (isSmallTablet) return 420;
    if (isTablet) return 600;
    return 700; // Default for desktop
  };

  const getRectWidth = () => {
    if (isTargetSmallMobile) return 380;
    if (isMobile) return 380;
    if (isSmallTablet) return 420;
    if (isTablet) return 600;
    return 700; // Default for desktop
  };

  const getRectHeight = () => {
    if (isTargetSmallMobile) return 400;
    if (isMobile) return 400;
    if (isSmallTablet) return 500;
    if (isTablet) return 400;
    return 600; // Default for desktop
  };

  return (
    <ContentLoader
      viewBox={getViewBox()}
      height={getHeight()}
      width={getWidth()}
      {...props}
      speed={2}
      backgroundColor="#ababab"
      foregroundColor="#fafafa"
    >
      <rect
        x="16"
        y="107"
        rx="0"
        ry="0"
        width={getRectWidth()}
        height={getRectHeight()}
      />
      <circle cx="35" cy="48" r="20" />
      <rect x="69" y="29" rx="2" ry="2" width="275" height="15" />
      <rect x="69" y="53" rx="2" ry="2" width="140" height="15" />
    </ContentLoader>
  );
};

export default InstagramFeedMain;
