import React from "react";
import Arrow from "@assets/membershipDashboard/chevron-right.svg";
import ArrowUp from "@assets/membershipDashboard/arrow-up.svg";
import Logo from "@assets/membershipDashboard/Logo.svg";

const CarvonixStatisticsTab = ({ userInfo }) => {
  return (
    <div>
      <p>Carvonix Statistics</p>
      <div className="total-carvonix-points">
        <div className="total">
          <div className="carvonix-image">
            <img src={Logo} alt="Bolt icon" />
            <p>Total Carvonix Points</p>
          </div>
          <h1 style={{ color: userInfo.interactionPoint.available ? "#FFFFFF" : "#8E8E93" }}>
            {userInfo.interactionPoint.available ? `${userInfo.interactionPoint.available} ` : ""}
          </h1>
          {userInfo.carvonixPointStats.lastWeekPoint ? (
            <div className="points-last-week">
              <img src={ArrowUp} alt="Bolt icon" />
              <p>{userInfo.carvonixPointStats.lastWeekPoint} from last week</p>
            </div>
          ) : (
            // <p style={{ color: "#8E8E93" }}>---</p>
            ""
          )}
        </div>
      </div>
      <div className="AboutSpeedSociety" style={{cursor:"not-allowed"}}>
        <p>How To Earn Points</p>
        <img src={Arrow} alt="" />
      </div>
      <div className="AboutSpeedSociety" style={{cursor:"not-allowed"}}>
        <p>More about Carvonix Points</p>
        <img src={Arrow} alt="" />
      </div>
    </div>
  );
};

export default CarvonixStatisticsTab;
