import SiteLogo from "@assets/site-logo.svg";
import "./Signup.scss";
import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Back } from "@assets/back.svg";
import { debounce } from "lodash";
import { otpValid } from "@utils/validationFunctions";
import { showNotification } from "@utils/utilityFunctions";
import RenderError from "@components/Error/RenderError";
import Form from "@components/Forms/Formik/Form";
import {
  useLazyUserCheckPhoneQuery,
  useRegisterUserInfoMutation,
  useUserVerifyOtpMutation,
} from "@redux/services/auth/signUpService";
import { SetclearSignUpInfo, setRegRefreshToken } from "@redux/slices/authSlice/signUpSlice";
import { toast } from "react-toastify";
export default function VerifyOtp() {
  const userInfoReg = useSelector((state) => state?.signUp?.userSignUpInfo);

  const [userVerifyOtp, { error }] = useUserVerifyOtpMutation();
  const [registerUserInfo, { isLoading }] = useRegisterUserInfoMutation();
  const [triggerCheckPhone] = useLazyUserCheckPhoneQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");

  const SignInSchema = Yup.object().shape({
    code: Yup.string()
      .matches(/^\d{6}$/, "Must only contain 6 digits")
      .required("Code is required"),
  });

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: SignInSchema,
  });
  const { errors, touched, values, handleChange, handleBlur, isValid } = formik;
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  const handleSubmitForDeboun = async (e) => {
    e.preventDefault();

    const validate = otpValid(values?.code);
    if (!validate.valid) {
      setErrorMsg(validate.errors);
      return;
    }

    const verifyOtpBody = {
      otp: values?.code,
      phoneNumber: userInfoReg?.phoneNumber,
    };

    const reg_user = {
      firstName: userInfoReg.firstName,
      lastName: userInfoReg.lastName,
      email: userInfoReg.email,
      username: userInfoReg.username,
      password: userInfoReg.password,
      phoneNumber: userInfoReg.phoneNumber,
    };

    try {
      // First API call
      const verifyOtpResponse = await userVerifyOtp(verifyOtpBody).unwrap();
      // If the first API call is successful
      if (verifyOtpResponse?.code === 200) {
        // Second API call
        try {
          const registerUserInfoResponse = await registerUserInfo(
            reg_user
          ).unwrap();
          if (registerUserInfoResponse?.code === 200) {
            dispatch(
              setRegRefreshToken(
                registerUserInfoResponse?.data?.tokens?.access
              ),
              dispatch(SetclearSignUpInfo()),
              navigate(`/waiting-list/${registerUserInfoResponse.data.waitingNumber}`)
            );
          }
        } catch (error) {
          toast.error(error?.data?.message);
        }
      }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  const handleDidNotRecieveDebon = async (e) => {
    e.preventDefault();
    setMinutes(1);
    setSeconds(30);
    try {
      const response = await triggerCheckPhone(userInfoReg?.phoneNumber);
    } catch (error) {
      showNotification(
        error?.response?.data?.message || error?.message,
        "error"
      );
    }
  };
  const handleDidNotRecieveCode = debounce(handleDidNotRecieveDebon, 2000, {
    leading: false,
    trailing: true,
  });
  useEffect(() => {
    formik.validateForm();
  }, []);
  return (
    <div>
      <div className="signup-container">
        <div className="signup-wrapper">
          <img src={SiteLogo} alt="" className="logo" />
          <div className="lets-go">Confirm your number.</div>
          <div className="enter-text">
            Enter your 6 digit verification code.
          </div>
          <Form
            onSubmit={handleSubmitForDeboun}
            formik={formik}
            submitText="Next"
            className="next-btn"
            disableSubmit={!isValid}
            isDisableBtn={isLoading}
          >
            <div className="form-group">
              <label></label>
              <input
                type="text"
                placeholder="Code"
                className="form-control"
                name="code"
                onChange={(e) => {
                  handleChange(e);
                  setErrorMsg("");
                  // setDisableBtn(false);
                }}
                onBlur={handleBlur}
              />
              {errorMsg !== "" && <RenderError message={errorMsg} />}
              {touched.code && <RenderError message={errors?.code} />}
              <div
                className="message code"
                style={{ marginBottom: "40px" }}
                onClick={(e) => {
                  handleDidNotRecieveCode(e);
                }}
                disabled={seconds > 0 || minutes > 0}
              >
                Didn’t receive a code?
              </div>
            </div>
          </Form>
          <div
            className="back-btn"
            onClick={(e) => {
              e.preventDefault();
              navigate("/user-info");
            }}
          >
            <Back />
            Go Back
          </div>
        </div>
      </div>
    </div>
  );
}
