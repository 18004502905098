import React, {  useState } from "react";
import searchIcon from "@assets/dropDown/searchIcon.svg";
import arrowIcon from "@assets/dropDown/arrow.svg";
import "./ExploreDropDown.scss";
import { useLazyAllSearchExploreQuery } from "@redux/services/exploreService/exploreService";
import { toast } from "react-toastify";
import { useDebounce } from "use-debounce";
import { useEffect } from "react";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";
import { Link } from "react-router-dom";
import NotFound from "@components/NotFound";

export default function ExploreDropDowns() {
  /**
   * USESTATES
   */
  const [searchVal, setSearchVal] = useState("");
  const [dataGloball, setDataGloball] = useState([]);

  /**
   * DEBOUNCE
   */
  const [debouncedSearch] = useDebounce(searchVal, 1000);

  /**
   * API CALLS
   */
  const [searchGlobally, { isFetching }] = useLazyAllSearchExploreQuery();
  /**
   * FUNCTIONS
   */
  const fetchData = async () => {
    try {
      const response = await searchGlobally({
        search: debouncedSearch,
      }).unwrap();

      const filteredData = response.data.filter((item) => item.value > 0);
      setDataGloball(filteredData || []);
    } catch (error) {
      toast.error(error?.data?.message || "An error occurred.");
    }
  };

  const handleChangeSearch = (e) => {
    setSearchVal(e.target.value);
  };
  /**
   * USEEFFECT
   */
  useEffect(() => {
    if (debouncedSearch !== "") {
      fetchData();
    } else {
      setDataGloball([]);
    }
  }, [debouncedSearch]);

  return (
    <div className="banner-search-explore">
      <div className="search-form">
        <div className="search-fld">
          <input
            type="search"
            className="form-control"
            onChange={(e) => handleChangeSearch(e)}
            placeholder="Search for anything"
            value={searchVal}
          />
        </div>
        {searchVal && (
          <div className="search-dropdown">
            {isFetching ? (
              <SpinnerLoader />
            ) : (
              <>
                {dataGloball?.length > 0 ? (
                  dataGloball?.map(
                    (item, index) =>
                      item?.value > 0 && (
                        <Link
                          className="result-loop"
                          key={index}
                          to={`/explore/categories/${item?.name}`}
                          state={{ title: item?.name, searchByName: searchVal }}
                        >
                          <div className="search-name">
                            <img src={searchIcon} alt="Search Icon" />
                            <p style={{ margin: "unset" }}>
                              {searchVal}{" "}
                              <span className="tag">{item?.name}</span>
                            </p>
                          </div>
                          <img src={arrowIcon} alt="Arrow Icon" />
                        </Link>
                      )
                  )
                ) : (
                  <NotFound message="record" />
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div className="explore-search-name d-flex">
        {/* <div className="search-filter">
            <select className="default-select">
              <option>All categories</option>
              <option>All categories</option>
            </select>
          </div> */}
        <Link
          className="search-btn-explore"
          to={`${searchVal !== "" ? "/explore/categories" : "/explore/all"} `}
          state={{ searchExplore: searchVal }}
        >
          <button className="default-btn">Search</button>
        </Link>
      </div>
    </div>
  );
}
