import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth  from "../baseQuery";

export const reactionApi = createApi({
  reducerPath: "reactionApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["reaction"],

  endpoints: (builder) => ({
    likedByUser: builder.mutation({
      query: (payload) => ({
        url: `/Reactions`,
        method: "POST",
        body: payload,
      }),
    }),
    allReactionPost: builder.query({
      query: (modelId) => ({
        url: `/Reactions/${modelId}`,
        method: "GET",
      }),
      providesTags: ["reaction"],
    }),
    followUserModal: builder.mutation({
      query: (value) => ({
        url: `/users/follow/user`,
        method: "POST",
        body: value,
      }),
      invalidatesTags: ["reaction"],
    }),
    removefollowUser: builder.mutation({
      query: (removeId) => ({
        url: `/users/remove-following/${removeId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["reaction"],
    }),
  }),
});

export const {
  useLikedByUserMutation,
  useAllReactionPostQuery,
  useFollowUserModalMutation,
  useRemovefollowUserMutation
} = reactionApi;
