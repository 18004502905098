import React from "react";
import { Horsepower, Engine } from "@components/Icons";

const SpecsSummary = ({ buildData }) => {
  const isModified = buildData?.isVehicleModified;
  const horsepower = buildData?.engine?.horsepower;
  const stockHorsepower = buildData?.engine?.stock_values?.horsepower;
  const greenDotHorsepower =
    buildData?.engine?.stock_values?.greenDot_horsepower;
  const camType = buildData?.engine?.cam_type;
  const stockCamType = buildData?.engine?.stock_values?.cam_type;
  const compressorType = buildData?.engine?.compressor_type;
  const stockCompressorType = buildData?.engine?.stock_values?.compressor_type;
  const cylinder = buildData?.engine?.cylinder;
  const stockCylinder = buildData?.engine?.stock_values?.cylinder;

  const hasEngineStockValues =
    stockCamType || stockCompressorType || stockCylinder;

  const hasEngineValues = camType || compressorType || cylinder;
  return (
    <div className="extra-info py-4" id="Summary">
      <div className="summary">
        <h1>Summary</h1>
      </div>
      <div className="specs py-3">
        <div className="hp">
          <h1>Specs</h1>
        </div>
        <div className="modified">
          {isModified && <div className="online"></div>}
          <div className="text">
            <p>Modified</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
          <div
            className="wrap-specs"
            style={{
              height: "125px"
                // !(stockHorsepower || horsepower) ? "125px" : "auto",
            }}
          >
            <div className="info-block">
              <div className="wrap">
                <div className="icon">
                  <Horsepower fill={buildData?.type ? "#387AFF" : "#E56424"} />
                  <p>Horsepower</p>
                </div>
                {greenDotHorsepower && <div className="online"></div>}
              </div>
              {horsepower ? (
                <div
                  className={`hp py-3`}
                  style={{
                    borderBottom: stockHorsepower && "1px solid #323334",
                  }}
                >
                  {horsepower && (
                    <p>
                      {horsepower} <span>hp</span>
                    </p>
                  )}
                </div>
              ) : (
                <span className="hp py-3">---</span>
              )}

              {stockHorsepower && (
                <div className="moderator pt-2">
                  <p>{stockHorsepower} hp</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
          <div
            className="wrap-specs"
            style={{
              height: "125px"
                // hasEngineValues && hasEngineStockValues ? "125px" : "auto",
            }}
          >
            <div className="info-block">
              <div className="wrap">
                <div className="icon">
                  <Engine fill={buildData?.type ? "#387AFF" : "#E56424"} />
                  <p>Engine</p>
                </div>
                {hasEngineStockValues && <div className="online"></div>}
              </div>
              {camType === "Piston" || camType === "Electric" ? (
                <div
                  className="hp py-3"
                  style={{
                    borderBottom: hasEngineStockValues
                      ? "1px solid #323334"
                      : "none",
                  }}
                >
                  <p>{camType === "Piston" ? "V" + cylinder : camType}</p>
                  <h6>{camType !== "Electric" && compressorType}</h6>
                </div>
              ) : (
                <span className="hp py-3">---</span>
              )}

              {hasEngineStockValues && (
                <div className="moderator pt-2">
                  <p>
                    {stockCamType === "Piston"
                      ? "V" + stockCylinder
                      : stockCamType}
                  </p>
                  <h6>{stockCamType !== "Electric" && stockCompressorType}</h6>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecsSummary;
