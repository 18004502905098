import React from "react";
import WrapperModal from "./WrapperModal";
import ShowLikedUserModal from "@components/Modal/ShowLikedUserModal";

const LikeModal = React.memo(({ open, closeModal, navigateVal }) => {
  return (
    <div id="modal">
      <WrapperModal
        open={open}
        closeModal={closeModal}
        style={{
          top: "50%",
          transform: "translateY(-50%)",
          left: 0,
          right: 0,
          margin: "0 auto",
        }}
      >
        <ShowLikedUserModal />
      </WrapperModal>
    </div>
  );
});

export default LikeModal;
