import React from "react";
import "./bannerwithsearch.scss";
import ExploreDropDowns from "./ExploreDropDown";

export default function BannerWithSearch() {
  return (
    <div>
      {/* <Header /> */}
      <div className="home-banner">
        <div className="container">
          <h5>Redefining the car community,</h5>
          <h1>one build at a time</h1>
          <ExploreDropDowns />
        </div>
      </div>
    </div>
  );
}
