import React from "react";

import ContentLoader from "react-content-loader";

const CrewEventDetailSkeleton = () => (
  <ContentLoader
    viewBox="0 0 1050 450"
    height={450}
    width={1050}
    backgroundColor="#ababab"
    foregroundColor="#fafafa"
    speed={2}

    // {...props}
  >
    {/* <circle cx="30" cy="258" r="30" /> */}
    {/* <rect x="75" y="233" rx="4" ry="4" width="100" height="13" /> */}
    {/* <rect x="75" y="260" rx="4" ry="4" width="50" height="8" /> */}
    {/* <rect x="25" y="340" rx="5" ry="5" width="1000" height="330" /> */}
    <rect x="0" y="0" rx="5" ry="5" width="1050" height="400" />
  </ContentLoader>
);
export default CrewEventDetailSkeleton;
