import React from "react";
import ButtonLoader from "@components/Loaders/ButtonLoader";

const FollowUnFollow = ({ followUnfollow, userInfo, loading, btnIndex, selectedIndex }) => {
  return (
    <button
      className="default-btn"
      onClick={() => followUnfollow(userInfo)}
      disabled={loading}
      style={{
        backgroundColor: userInfo?.isFollow ? "transparent" : "",
        border: userInfo?.isFollow ? "2px solid white" : "",
      }}
    >
      {btnIndex === selectedIndex && loading ? (
        <ButtonLoader />
      ) : userInfo?.isFollow ? (
        "Following"
      ) : (
        "Follow"
      )}
    </button>
  );
};

export default FollowUnFollow;
