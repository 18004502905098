import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DefaultUserImg } from "@utils/defaultImages";
import { isValidUrl } from "@utils/validUrl";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import DropMenu from "@components/DropDownProfile/DropMenu";

const FeedHeader = ({ feedPost, isMobile, isTablet, index }) => {
  const [openDropdowns, setOpenDropdowns] = useState([]);
  const dropDownTitle = ["Save", "Share", "Link", "Report"];
  const toggleDropdown = (commentIndex) => {
    setOpenDropdowns((prevIndex) =>
      prevIndex === commentIndex ? -1 : commentIndex
    );
  };
  // console.log("reacted----???", feedPost);

  return (
    <>
      <div className="feed-header">
        <div className="username">
          {feedPost?.parentType !== 2 ? (
            <>
              <Link
              // className="profile-link"
              // to={feedPost?.user?._id && `/profile/${feedPost?.user?._id}`}
              >
                <img
                  src={
                    isValidUrl(feedPost?.user?.profileImage)
                      ? feedPost?.user?.profileImage
                      : DefaultUserImg
                  }
                  alt=""
                />
              </Link>
              <div>
                <Link
                  // to={feedPost?.user?._id && `/profile/${feedPost?.user?._id}`}
                  to={feedPost?.user?._id && `/profile/${feedPost?.user?._id}`}
                  className="profile-link"
                >
                  <h5>
                    {feedPost?.user?.username?.length > 0 &&
                    (isTablet || isMobile)
                      ? sliceStringAtWord(feedPost?.user?.username, 15)
                      : feedPost?.user?.username}
                  </h5>
                </Link>
              </div>
            </>
          ) : (
            <div className="avtar-in">
              <div className="profile-link">
                <div className="car-image">
                  <img
                    src={
                      isValidUrl(feedPost?.build?.bannerImage)
                        ? feedPost?.build?.bannerImage
                        : DefaultUserImg
                    }
                    alt={`${feedPost?.build?.make}/${feedPost?.build?.model}/${feedPost?.build?.year?.value}`}
                    className="main-img"
                  />
                  <Link to={`/profile/${feedPost?.userId}`}>
                    <div className="user-image">
                      <img
                        src={
                          feedPost?.user?.profileImage
                            ? feedPost?.user?.profileImage
                            : DefaultUserImg
                        }
                        alt={feedPost?.user?.username}
                        className="main-img"
                      />
                    </div>
                  </Link>
                </div>
              </div>
              <div>
                <div className="name" style={{ wordBreak: "unset" }}>
                  <Link to={`/profile/${feedPost?.userId}`} style={{color:"#f5f5f5"}}>
                    <div className="profile-link">
                      {feedPost?.user?.username?.length > 0 &&
                      (isTablet || isMobile)
                        ? sliceStringAtWord(feedPost?.user?.username, 15)
                        : feedPost?.user?.username}
                    </div>
                  </Link>
                </div>
                <div className="d-flex align-items-center">
                  {/* {component?.user?.username} */}
                  {feedPost?.build?.name}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <Link role="button" onClick={() => toggleDropdown(index)}>
          <ThreeDots />
        </Link> */}
      </div>
      {/* Uncomment DropMenu if needed */}
      {openDropdowns === index && (
        <DropMenu post={feedPost} dropDownTitle={dropDownTitle} />
      )}
    </>
  );
};

export default FeedHeader;
