import React from "react";
import WrapperWarningModal from "./warningModalWrapper";
import Error from "@assets/error (1).svg";

const WarningModal = ({
  open,
  closeModal,
  chatMsg = false,
  deleteBtn = {},
}) => {
  const handleClose = (str) => {
    if (str === "sure") {
      closeModal(str);
      if (chatMsg) {
        deleteBtn();
      }
    } else {
      closeModal("");
    }
  };

  return (
    <WrapperWarningModal
      open={open}
      closeModal={closeModal}
      style={{
        top: "50%",
        width: chatMsg ? "345px" : "315px",
        height: chatMsg ? "314px" : "400px",
        transform: "translateY(-50%)",
        borderRadius: "10px",
        left: 0,
        right: 0,
        margin: "0 auto",
        backgroundColor: "#18191A",
        boxShadow: "2px 8px 20px -6px #0A0A0A",
        zIndex: "999999999",
      }}
    >
      <div className="warning-modal-body">
        <div className="warningImage">
          <img src={Error} alt="Error icon" />
        </div>
        <p>
          {chatMsg
            ? "Are you sure you want to delete chat?"
            : "Are you sure you want to Delete?"}
        </p>
        <div className="linkToSocietyBtn">
          <button onClick={() => handleClose("cancel")}>Cancel</button>
          <button
            style={{ backgroundColor: "#E83434" }}
            onClick={() => handleClose("sure")}
          >
            Yes, I am sure
          </button>
        </div>
      </div>
    </WrapperWarningModal>
  );
};

export default WarningModal;
