import React, { useState } from "react";
import "./Comments.scss";
import {
  ThumbsUp,
  CommentIcon,

} from "@utils/defaultImages";
import { useLazyListAllCommentsQuery } from "@redux/services/commentsService/commentService";
import LikedUsername from "../LikedBy/LikedUsername";
import { useLikedByUserMutation } from "@redux/services/reactionService/reactionService";
import { useDebouncedCallback } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";
import { setLoginModel } from "@redux/slices/authSlice/publicSlice";
import CommentsPopUp from "@components/Modal/CommentsPopUp";
import { toast } from "react-toastify";
import CommentForm from "./components/CommentForm/CommentForm";
import CommentInput from "./latestDesign/CommentInput";

const NewComments = ({ post = {}, isTablet, postId, isComponent = false, setAllComments, commentsCount, setCommentsCount ,replyData ,setreplyData ,
  selected, setSelected,commentList,addedReplyComment,setAddedREplyComment ,postIsLiked,setPostIsLiked ,PostReactionCount ,setPostReactionCount,}) => {
  /**
   * GET GLOBAL STATE
   */
  const userLoginToken = useSelector(
    (state) => state?.auth?.userAuthInfo?.access?.token
  );
  const showLoginModel = useSelector((state) => state.loginModel.showModel);
  const dispatch = useDispatch();

  // {API CALLS}

  const [listAllComments, { data: { data = [] } = [], isLoading, isFetching }] =
    useLazyListAllCommentsQuery();
  const [likedByUser] = useLikedByUserMutation();
  //
  // {USESTATES}
  const [isLiked, setIsLiked] = useState(postIsLiked || false); // Initial state for like status
  const [reactionCount, setReactionCount] = useState(PostReactionCount || 0); // Initial state for reaction count
  // const [commentsCount, setCommentsCount] = useState(post?.commentsCount || 0); // Initial state for comments count
  const [showComments, setShowComments] = useState(false); // State to toggle comments visibility
  // const [allComments, setAllComments] = useState(data?.docs || []);
  const [page, setPage] = useState(1);
  //
  // useEffect(() => {
  //   if (page > 1) {
  //     setAllComments((prev) => {
  //       const mergedComments = [...prev, ...data?.docs]; // Prepend new comments to existing ones
  //       const uniqueComments = mergedComments?.reduce((acc, current) => {
  //         const x = acc?.find((item) => item?.id === current?.id);
  //         if (!x) {
  //           return acc?.concat([current]);
  //         } else {
  //           return acc;
  //         }
  //       }, []);
  //       return uniqueComments;
  //     });
  //   }
  // }, [data, page]);

  // { FUNCTIONS }
  const showCommentsList = async () => {
    const body = {
      postId: post?.id || postId,
      page: page,
    };
    try {
      const response = await listAllComments(body).unwrap();
      // if (page === 1) {
      //   setAllComments(response?.data?.docs);
      // }
      if (response?.data?.hasNextPage) {
        setPage(response?.data?.nextPage);
      }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };
  const handleToggle = () => {
    dispatch(setLoginModel(!showLoginModel));
  };
  // Toggle comments visibility
  //   const toggleCommentsVisibility = () => {
  //     if (userLoginToken) {
  //       setShowComments(!showComments);
  //       if (data?.length === 0) {
  //         showCommentsList();
  //       }
  //     } else {
  //       handleToggle();
  //     }
  //   };

  // {LIKE AND UNLIKE REACTIONS}
  const likeAndUnlikeReaction = async () => {
    const newIsLiked = !isLiked;
    setIsLiked(newIsLiked);
    setReactionCount(reactionCount + (newIsLiked ? 1 : -1));
    setPostIsLiked(newIsLiked);
    setPostReactionCount(reactionCount + (newIsLiked ? 1 : -1));


    const value = {
      reactionType: newIsLiked ? 1 : 0, // 0 for unlike, 1 for like
      modelId: post?._id,
      model: "post",
    };

    try {
      const response = await likedByUser(value);
      if (response?.data?.code !== 200) {
        // Revert the state back if the API call fails
        setIsLiked(!newIsLiked);
        setReactionCount(reactionCount + (newIsLiked ? -1 : 1)); // Revert the reaction count
      }
    } catch (error) {
      console.log("errorlike", error);
      // Revert the state back if the API call fails
      setIsLiked(!newIsLiked);
      setReactionCount(reactionCount + (newIsLiked ? -1 : 1)); // Revert the reaction count
    }
  };
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  // Handle updating comments data and count
  const handleNewComment = () => {
    setCommentsCount((prevCount) => prevCount + 1);
  };
  const PostType = [
    "Profile Post",
    "Vehicle Post",
    "Component Post",
    "Crew Post",
  ];
  // Debounce on like and unlike
  const debouncedLikeAndUnlikeReaction = useDebouncedCallback(
    likeAndUnlikeReaction,
    500
  );
  function handleLikeClick() {
    if (userLoginToken) {
      debouncedLikeAndUnlikeReaction();
    } else {
      handleToggle();
    }
  }
  return (
    <>
      <div className="comments-wrapper pb-2">
        <>
          {/* {reactionCount > 0 && ( */}
          <LikedUsername
            reacted={post}
            reactionCount={reactionCount}
            isLiked={isLiked}
          />
          {/* )}  */}
          {/* {isComponent && post?.title && (
            <div className="show-more-title-post mt-2">
              <ReadMore sliceText={70} isShowMarginTop={false}>
                {post?.title}
              </ReadMore>
            </div>
          )} */}
          {/* {isComponent && post?.description && (
            <div className="show-more-title mt-2">
              <ReadMore sliceText={140} isShowMarginTop={true}>
                {post?.description}
              </ReadMore>
            </div>
          )} */}
          <div className="total-coments my-2" type="button">
            <div className="wrap-buttons">
              <button
                style={{ border: "none", background: "none" }}
                onClick={handleLikeClick}
              // disabled={isDisableLike}
              >
                <ThumbsUp fill={isLiked ? "white" : "transparent"} />
              </button>
              {reactionCount}
            </div>
            <span
              //   onClick={toggleCommentsVisibility}
              className="comments-section"
            >
              <CommentIcon />
              {commentsCount}&nbsp;
            </span>
            {/* <div className="share">
              <Share />
              <span>Share</span>
            </div> */}
          </div>
        </>
        <>
          {/* {isComponent && (
            <Link>
              <div
                className="post-submission-info-2"
                style={{
                  marginTop: "10px",
                }}
              >
                <span
                  style={{
                    color: post?.parentType == 2 ? "#52FF00" : "#387AFF",
                  }}
                >
                  {PostType[post.parentType]}
                </span>

                <span>{dateTimeFromNow(post?.createdAt)}</span>
                {post?.location?.name && (
                  <>
                    <span>
                      <LocationIcon />
                      {isTablet
                        ? sliceStringAtWord(post?.location?.name, 10)
                        : post?.location?.name}
                    </span>
                  </>
                )}
              </div>
            </Link>
          )} */}
          {/* <CommentsPopUp
            openPopUp={showComments}
            closeModal={() => setShowComments(false)}
          /> */}
          {/* <ShowAllComments
            showComments={showComments}
            dataComments={data}
            // storePrevComment={allComments}
            isTablet={isTablet}
            Loading={isLoading}
            postId={postId}
            onNewComment={handleNewComment}
            loadMore={showCommentsList}
            fetchingNextPageData={isFetching}
          /> */}
          <div className="">
            {/* <textarea rows="2" cols="50"></textarea> */}
            <CommentInput post_id={post?._id} setAllComments={setAllComments} commentsCount={commentsCount}
              setCommentsCount={setCommentsCount}  replyData={replyData} setreplyData={setreplyData} commentList={commentList} 
              selected={selected} setSelected={setSelected} addedReplyComment={addedReplyComment} setAddedREplyComment={setAddedREplyComment}
              />
          </div>
        </>
      </div>
    </>
  );
};

export default NewComments;
