import React from "react";
import "./CrewsBuilds.scss";
import { Link } from "react-router-dom";

export default function CrewsBuilds({ setOpen }) {
  let user;
  return (
    <div>
      <div className="container">
        <div className="crews-builds">
          <div
            className="crews-block"
            // onClick={() => {
            //   if (!user?.tokens?.refresh?.token) {
            //     setOpen(true);
            //   }
            // }}
            type="button"
          >
            <div className="info">
              <h3>Crews</h3>
              <p>
                Short explanation worem ipsum dolor sit amet, consectetur
                adipiscing elit. Nunc vulputate libero et velit interdum, ac
                aliquet odio mattis.
              </p>
              <Link
                to={user?.tokens?.refresh?.token && `/`}
                // onClick={() => {
                //   if (!user?.tokens?.refresh?.token) {
                //     setOpen(true);
                //   }
                // }}
                className="d-flex align-items-center"
              >
                Learn more <i className="ms-2 fa-solid fa-arrow-right"></i>
              </Link>
            </div>
          </div>
          <div
            className="crews-block"
            // onClick={() => {
            //   if (!user?.tokens?.refresh?.token) {
            //     setOpen(true);
            //   }
            // }}
            type="button"
          >
            <div className="info">
              <h3>Builds</h3>
              <p>
                Short explanation worem ipsum dolor sit amet, consectetur
                adipiscing elit. Nunc vulputate libero et velit interdum, ac
                aliquet odio mattis.
              </p>
              <Link
                to="/"
                // onClick={() => {
                //   if (!user?.tokens?.refresh?.token) {
                //     setOpen(true);
                //   }
                // }}
                className="d-flex align-items-center"
              >
                Learn more <i className="ms-2 fa-solid fa-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
