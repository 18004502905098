import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userTemporaryAuthInfo: null,
  temporaryUserData: {},
};

const tokenSlice = createSlice({
  name: "temporaryAuth",
  initialState,
  reducers: {
    setTemporaryLoginData(state, action) {
      state.temporaryUserData = action.payload;
    },
    setTokenAuth(state, action) {
      state.userTemporaryAuthInfo = action.payload;
    },
    clearTokenAuth(state) {
      state.userTemporaryAuthInfo = null;
      state.temporaryUserData={};
      // state.token = null;
    },
  },
});

export const { setTokenAuth, clearTokenAuth, setTemporaryLoginData } = tokenSlice.actions;
export default tokenSlice;
