import React from 'react';
import './OnlineStatusDot.scss'; // assuming the CSS is in this file

const OnlineStatusDot = ({ status }) => {
  return (
    <div className={`status-indicator ${status === 'online' ? 'online' : 'offline'}`}></div>
  );
};

export default OnlineStatusDot;
