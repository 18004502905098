import React from "react";
import "./ButtonLoader.scss";
const ButtonLoader = () => {
  return (
    <div
      class="snippet"
      data-title="dot-flashing"
      style={{
        padding: "8px 30px",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
      }}
    >
      <div class="stage">
        <div class="dot-flashing"></div>
      </div>
    </div>
  );
};

export default ButtonLoader;
