import React, { useRef, useEffect, useState } from "react";

const VideoPlayer = ({ src, autoPlay, isHeight ,commentsPost,bordertop,aspectRatio }) => {
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Trigger when 50% of the element is in the viewport
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setIsVisible(entry.isIntersecting);
      });
    }, options);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      // videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, [isVisible]);
  // const handleVisibilityChange = () => {
  //   if (document.hidden) {
  //     // Tab or window is not visible, pause the video
  //     videoRef.current.pause();
  //   } else {
  //     // Tab or window is visible, play the video if needed
  //     // videoRef.current.play();
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, []);
  return (
    <video
      ref={videoRef}
      src={src}
      autoPlay={autoPlay}
      muted={false}
      controls
      // width="835"
      style={{
        aspectRatio:aspectRatio,
        maxHeight: commentsPost && "350px" ,
        marginBottom: !bordertop && "10px",
        ...(bordertop && {
          objectFit: "cover",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "100%",
          position: "absolute",
          boxSizing: "border-box",
        }),
      }}
      
      disablePictureInPicture={true}
      controlsList="nodownload noplaybackspeed noplaybackrate"
      // height={isHeight ? "620" : "620"}

    />
  );
};

export default VideoPlayer;
