import React, { useEffect, useState } from "react";

const CheckPassword = ({ password }) => {
  const [number, setNumber] = useState(false);
  const [character, setCharacter] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [specialCharacter, setSpecialCharacter] = useState(false);

  useEffect(() => {
    if (password.length >= 8) {
      setCharacter(true);
    } else {
      setCharacter(false);
    }

    if (/[A-Z]/.test(password)) {
      setUpperCase(true);
    } else {
      setUpperCase(false);
    }

    if (/[-_@#$%^&*`()+={}|~/<>,.;'?!]/.test(password)) {
      setSpecialCharacter(true);
    } else {
      setSpecialCharacter(false);
    }

    if (/\d/.test(password)) {
      setNumber(true);
    } else {
      setNumber(false);
    }
  }, [password]);

  const listItems = [
    { label: "8+ characters", state: character },
    { label: "1 special character", state: specialCharacter },
    { label: "1 uppercase letter", state: upperCase },
    { label: "1 number", state: number },
  ];

  return (
    <>
      {listItems.map((item, index) => (
        <>
          <ul className="password-requirements" key={index}>
            <li className={`${item.state ? "fulfilled" : ""}`}>
              {` ${item.label}`}
            </li>
          </ul>

          {/* <View>
            <Text>{`\u25CF`}</Text>
            <Text style={{ color: "placeholder" }}></Text>
          </View> */}
        </>
      ))}
    </>
  );
};

export default CheckPassword;
