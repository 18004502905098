import React, { useState } from "react";
import { useEffect } from "react";

const useNavigateMap = () => {
  const [openMap, setOpenMap] = React.useState("");
  const [currentLoc, setCurrentLoc] = useState({
    lat: 36.778259,
    lng: -119.417931,
  });
  useEffect(() => {
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        setCurrentLoc(pos);
      }
    );
  }, [openMap]);
  function generateGoogleMapsUrl(loc) {
    const baseUrl = "https://www.google.com/maps/dir/";

    if (loc?.length > 1) {
      let waypoints = loc
        ?.map((point) => {
          let lat = point.coordinates[1];
          let lng = point.coordinates[0];
          return `${lat},${lng}`;
        })
        .join("/");

      const googleMapsUrl = `${baseUrl}${currentLoc?.lat},${currentLoc?.lng}/${waypoints}`;

      setOpenMap(googleMapsUrl);
    } else {
      const [startLng, startLat] = loc[0].coordinates;
      setOpenMap(`${baseUrl}${startLat},${startLng}`);
    }
  }
  return { openMap, generateGoogleMapsUrl };
};

export default useNavigateMap;
