import React from "react";
import ContentLoader from "react-content-loader";
import { useLocation } from "react-router";

const FadingLoader = () => {
  const { pathname } = useLocation();
  return (
    <div
      style={{
        display: pathname == "/explore/all" && "flex",
        gap: pathname == "/explore/all" && "17px",
        alignItems: pathname == "/explore/all" && "center",
      }}
    >
      <FadingLoaderCard1 />
      <br />
      <FadingLoaderCard2 />
      <br />
      <FadingLoaderCard3 />
      <br />
      <FadingLoaderCard4 />
      {/* <FadingLoaderCard5 /> */}
    </div>
  );
};

const FadingLoaderCard1 = () => {
  return (
    <ContentLoader
      width={200}
      height={65}
      backgroundColor="#ababab"
      foregroundColor="#fafafa"
    >
      <rect x="70" y="15" rx="0" ry="5" width="180" height="15" />
      <rect x="70" y="39" rx="5" ry="5" width="150" height="9" />
      <rect x="0" y="10" rx="0" ry="0" width="40" height="40" />
    </ContentLoader>
  );
};

const FadingLoaderCard2 = () => {
  return (
    <ContentLoader
      width={200}
      height={65}
      backgroundColor="#ababab"
      foregroundColor="#fafafa"
    >
      <rect x="70" y="15" rx="0" ry="5" width="200" height="15" />
      <rect x="70" y="39" rx="5" ry="5" width="150" height="9" />
      <rect x="0" y="10" rx="0" ry="0" width="40" height="40" />
    </ContentLoader>
  );
};

const FadingLoaderCard3 = () => {
  return (
    <ContentLoader
      width={200}
      height={65}
      backgroundColor="#ababab"
      foregroundColor="#fafafa"
    >
      <rect x="70" y="15" rx="0" ry="5" width="200" height="15" />
      <rect x="70" y="39" rx="5" ry="5" width="150" height="9" />
      <rect x="0" y="10" rx="0" ry="0" width="40" height="40" />
    </ContentLoader>
  );
};

const FadingLoaderCard4 = () => {
  return (
    <ContentLoader
      width={200}
      height={65}
      backgroundColor="#ababab"
      foregroundColor="#fafafa"
    >
      <rect x="70" y="15" rx="0" ry="5" width="200" height="15" />
      <rect x="70" y="39" rx="5" ry="5" width="150" height="9" />
      <rect x="0" y="10" rx="0" ry="0" width="40" height="40" />
    </ContentLoader>
  );
};

export default FadingLoader;
