import React from "react";
import "./Drawer.scss";
import { ReactComponent as Close } from "@assets/close.svg";
import { ReactComponent as ArrowDown } from "@assets/arrow-down.svg";
import UserImg from "@assets/defaultUser.png";

const Drawer = ({ closeDrwaer }) => {
  return (
    <div className="main-div">
      <div className="wrapper-drawer">
        <div>
          <h4>Notifications</h4>
        </div>
        <div style={{ cursor: "pointer" }} onClick={closeDrwaer}>
          <Close />
        </div>
      </div>
      {[1, 2, 3, 4, 5, 6, 7].map(() => (
        <>
          <div className="notifications">
            <div className="noti-img">
              <img src={UserImg} alt="" />
              <p>
                <strong>CrewName</strong> has accepted your member request{" "}
                <span>1m</span>
              </p>
            </div>
            <div className="arrow-down">
              <ArrowDown />
            </div>
          </div>
          <div className="notifications">
            <div className="noti-img">
              <img src={UserImg} alt="" />
              <p>
                <strong>CrewName</strong> has accepted your member request{" "}
                <span>1m</span>
              </p>
            </div>
            <div className="arrow-down">
              <p>Join</p>
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

export default Drawer;
