import SendComment from "@assets/Triangle.svg";
import { Button } from "@components/index";
import { useUpdateMessageMutation } from "@redux/services/messenger/messengerService";
import { SendChat } from "@utils/defaultImages";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

const StyledIconChat = styled.div`
  .send-btn {
    path {
      fill: #8e8e93;
    }
  }

  &.actived .send-btn {
    path {
      fill: #387aff;
    }
  }
`;

const SendMessage = ({
  socket,
  loginUser,
  messengerUser,
  editMsg,
  resetEditMsg,
  setTypingUser,
  chatRoomId
}) => {
  /**
   * USESTATE
   */
  const [isEditing, setIsEditing] = useState(false);
  const [messageText, setMessageText] = useState("");

  /**
   * API CALL
   */
  const [updateMessage] = useUpdateMessageMutation();

  /**
   * FUNCTIONS
   */
  const handleClick = async () => {
    if (isEditing) {
      const body = {
        messageContent: messageText,
        roomId: editMsg?.roomId,
        messageId: editMsg?._id,
      };

      try {
        socket.emit("update-message", {
          userId: editMsg?.senderId,
          roomId: editMsg?.roomId,
          chatId: editMsg?._id,
          messageContent: messageText,
        });
        await updateMessage(body).unwrap();
        resetEditMsg({});
        setMessageText("");
        setIsEditing(false);
      } catch (error) {
        toast.error(error?.data?.message);
      }
    } else {
      socket.emit("message-room", {
        type: 1,
        messageContent: messageText,
        senderId: loginUser?._id,
        roomId: chatRoomId,
        receiverId: messengerUser?.user?._id || messengerUser?._id,
      });
      // setMessageText("");
    }
    // Reset states
    setMessageText("");
    resetEditMsg({});
    setIsEditing(false);
  };

  const handleChange = (e) => {
    setMessageText(e.target.value);
    socket?.emit("is-typing", chatRoomId, loginUser?._id, true);
  };
  /**
   * UEFFECTS
   */
  useEffect(() => {
    if (Object.keys(editMsg)?.length > 0) {
      setMessageText(editMsg?.messageContent);
      setIsEditing(true);
    } else {
      setMessageText([]);
      setIsEditing(false);
    }
  }, [editMsg]);
  // Emit stop typing event after 2 seconds of inactivity
  useEffect(() => {
    let typingTimer;
    if (messageText) {
      typingTimer = setTimeout(() => {
        socket?.emit("is-typing", chatRoomId, loginUser?._id, false);
      }, 2000);
    } else {
      socket?.emit("is-typing", chatRoomId, loginUser?._id, false);
    }
    return () => {
      clearTimeout(typingTimer);
    };
  }, [messageText, socket, chatRoomId, loginUser?._id]);

  // Listen for typing events
  useEffect(() => {
    socket?.on("typing-status", (data) => {
      setTypingUser(data);
    });

    

    return () => {
      socket?.off("typing-status");
    };
  }, [socket]);
  return (
    <div className="message-box">
      <textarea
        placeholder="Add a Message"
        value={messageText}
        onChange={handleChange}
      />
      <div className="wrap-send-btn">
        <Button className="messageSendBtn" onClick={handleClick}>
          <StyledIconChat
            className={`${messageText.length > 0 ? "actived" : ""}`}
          >
            <SendChat className="send-btn" />
          </StyledIconChat>
        </Button>
      </div>
    </div>
  );
};

export default SendMessage;
