import ButtonLoader from "@components/Loaders/ButtonLoader";
import React from "react";

const CrewJoinBtn = ({
  btnType,
  joinCrew = {},
  disjoin = {},
  loadingValue,
  handleCrewIndex = {},
  comapreIndex,
  listIndex,
}) => {
  return (
    <button
      className="default-btn"
      style={{
        backgroundColor:
          btnType?.privacy === 1 && btnType?.userJoinStatus === 2
            ? "transparent"
            : "",
        borderColor:
          btnType?.privacy === 1 && btnType?.userJoinStatus === 2
            ? "#ffffff"
            : "",
        padding: "6px 12px",
      }}
      onClick={() => {
        if (btnType?.userJoinStatus === 1) {
          joinCrew(btnType?._id);
        }
        if (
          btnType?.isJoin ||
          (btnType?.userJoinStatus === 2 && btnType?.isJoin === false)
        ) {
          disjoin(btnType?._id);
        }
        handleCrewIndex(listIndex);
      }}
      disabled={loadingValue}
    >
      {comapreIndex === listIndex && loadingValue ? (
        <ButtonLoader />
      ) : btnType?.isJoin ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          {/* <Chat /> */}
          Leave
        </div>
      ) : btnType?.privacy === 1 && btnType?.userJoinStatus === 2 ? (
        "Requested to join"
      ) : btnType?.userJoinStatus === 1 && btnType.privacy === 1 ? (
        "Ask to join"
      ) : (
        "Join"
      )}
    </button>
  );
};

export default CrewJoinBtn;
