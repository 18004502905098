import React from "react";
import UpComingCrewEvents from "./components/UpComingCrewEvents";
import CrewLists from "./components/CrewLists";
import { useMediaQuery } from "react-responsive";

const CrewDetails = () => {
  // media Query
  const isMobile = useMediaQuery({ maxWidth: 599 });
  const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 1023 });
  return (
    <>
      <div style={{ padding: "5%",borderRight:"1px solid #323334" }}>
        <CrewLists isMobile={isMobile} isTablet={isTablet} />
      </div>
      {!(isMobile || isTablet) && (
        <div>
          <UpComingCrewEvents />
        </div>
      )}
    </>
  );
};

export default CrewDetails;
