import React from "react";
import BannerWithSearch from "./components/BannerWithSearch";
import "@containers/searchcomponent/SearchPage.scss";
import PostPage from "./components/PostPage";
import AccountParts from "./components/AccountParts";
import CrewPart from "./components/CrewPart";
import GaragePart from "./components/GaragePart";

const ExplorePage = () => {
  return (
    <>
      <div style={{ padding: "30px 0px 0px 0px" }}>
        <BannerWithSearch />
        <div>
          {/* Garage Row */}
          <div className="Builds-car">
            <GaragePart />
            {/* Crew list heading */}
            <CrewPart />
            {/* Accounts details */}
            <AccountParts />
            {/* post details */}
            <PostPage />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExplorePage;
