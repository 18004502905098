/* eslint-disable react/button-has-type */
import React, { useCallback, forwardRef } from "react";

const Button = forwardRef(
  ({ className, type, children, disabled, value, onClick }, ref) => {
    const onButtonClick = useCallback(
      (e) => {
        if (onClick) {
          onClick(value, e);
        }
      },
      [value, onClick]
    );

    return (
      <button
        ref={ref}
        type={type}
        className={className}
        disabled={disabled}
        onClick={onButtonClick}
      >

        {children}

      </button>
    );
  }
);

export default Button;
