import React from "react";
import VideoPlayer from "@components/VideoPlayer";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";

import { Navigation, Pagination } from "swiper/modules";
import { Demo_Car } from "@utils/defaultImages";
const MediaShow = ({ feedPost, commentsPost = false, isHeight ,bordertop = false} = false) => {
  // console.log('feedPost', feedPost?.media)
  return (
    <>
      <div
        className="borderforAll"
        style={{
          aspectRatio:
            feedPost?.media?.length > 0
              ? feedPost?.media[0].aspectRatio
              : undefined,
          width: "100%",
          height: "auto",
          marginTop: bordertop && "0px",
          // maxHeight: commentsPost ? "350px" : isHeight ? "600px" : "",
        }}
      >
        {/* {feedPost?.media?.length > 1 ? ( */}

        <Swiper
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {feedPost?.media?.map((mediaData, index) => (
            <SwiperSlide
              key={index}
              style={{
                aspectRatio:
                  feedPost?.media?.length > 0
                    ? feedPost?.media[0].aspectRatio
                    : undefined,
                width: "100%",
                height: "auto",
              }}
            >
              {mediaData?.type === "1" ? (
                <>
                  {mediaData?.path?.includes("https") && (
                    // <img

                    //   src={mediaData.path}
                    //   alt=""
                    //   className="vid-img-how"
                    //   style={{
                    //     width: "100%",
                    //     height: "auto",
                    //     maxHeight:commentsPost ?"350px":isHeight ?"600px" :"",
                    //     // aspectRatio: mediaData?.width > mediaData?.height ? "5/4":"4/5",
                    //     aspectRatio: mediaData?.aspectRatio
                    //   }}
                    // />
                    <AsyncImage
                      key={index}
                      src={mediaData.path}
                      loading="lazy"
                      Transition={Blur}
                      style={{
                        width: "100%",
                        height: "auto",
                        
                        // maxHeight: commentsPost
                        //   ? "350px"
                        //   : isHeight
                        //   ? "600px"
                        //   : "",
                        // aspectRatio: mediaData?.width > mediaData?.height ? "5/4":"4/5",
                        aspectRatio: mediaData?.aspectRatio || 5/4,
                        // marginBottom:"10px"
                      }}
                      // loader={<div style={{ background: "#888" }} />}
                      loader={
                        <img
                          src={mediaData.path}
                          style={{ filter: "blur(25px)" }}
                        />
                      }
                    />
                  )}
                </>
              ) : mediaData?.type === "2" ? (
                <>
                  {mediaData?.path?.includes("https") && (
                    <VideoPlayer
                      src={mediaData?.path}
                      autoPlay={false}
                      muted={true}
                      commentsPost={commentsPost}
                      isHeight={isHeight}
                      bordertop={bordertop}
                      aspectRatio={mediaData?.aspectRatio}
                      style={{
                        width: "100%",
                        height: "auto",
                        // aspectRatio: mediaData?.width > mediaData?.height ? "16/9":"9/16",
                        // aspectRatio: `${mediaData?.aspectRatio} !important`,
                      }}
                    />
                  )}
                </>
              ) : (
                ""
              )}
            </SwiperSlide>
          ))}
        </Swiper>

        {/* )  */}
        {/* : (
          <>
            {feedPost?.media?.map((mediaData, index) => {
              return (
                <>

                  {mediaData?.type === "1" ? (
                    <>
                      {mediaData?.path?.includes("https") && (

                        <img
                          src={mediaData.path}
                          alt=""
                          className="vid-img-how"
                          style={{
                            width: "100%",
                            height: "auto",
                            maxHeight:commentsPost ?"350px":isHeight ?"600px" :"",
                            // aspectRatio: mediaData?.width > mediaData?.height ? "5/4":"4/5",
                            aspectRatio: mediaData?.aspectRatio
                          }}
                        />

                      )}
                    </>
                  ) : mediaData?.type === "2" ? (
                    <>
                      {mediaData?.path?.includes("https") && (

                        <VideoPlayer
                          src={mediaData?.path}
                          autoPlay={false}
                          commentsPost={commentsPost}
                          muted={true}
                          isHeight={isHeight}
                          style={{
                            width: "100%",
                            height: "auto",
                            // aspectRatio: mediaData?.width > mediaData?.height ? "16/9":"9/16",
                            aspectRatio: `${mediaData?.aspectRatio} !important`
                          }}
                        />

                      )}
                    </>
                  ) : null}

                </>
              )
            }

            )}
          </>
        )
        } */}
      </div>
      {feedPost?.componentStatsId !== undefined && (
        <Link
          to={`${
            feedPost.parentType === 0
              ? `/general-feedPost/${feedPost?._id}`
              : ""
          }`}
        >
          <div
            className="feedPost-submission-info-2"
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <span>6 Steps</span>
            {/* <span>{dateTimeFromNow(feedPost?.createdAt)}</span> */}
            {feedPost?.subCategory?.value && (
              <>
                <span>
                  {/* <LocationIcon /> */}
                  {(feedPost?.subCategory?.value &&
                    feedPost?.subCategory?.value) ||
                    ""}
                </span>
              </>
            )}
          </div>
        </Link>
      )}
    </>
  );
};

export default MediaShow;
