import React, { useCallback, useEffect, useState } from "react";
import { LocationIcon, DefaultImg, BackArrow } from "@utils/defaultImages";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { Link, useNavigate } from "react-router-dom";
import { isValidUrl } from "@utils/validUrl";
import _debounce from "lodash/debounce";
import { useLazyGetAllNearCrewQuery } from "@redux/services/crewService/allCrewService";
import NearYouRecomendedSkeleton from "@components/Skeleton/NearYouRecomendedSkeleton";
import NotFound from "@components/NotFound";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";

const AllNearCrews = ({ isMobile, isTablet }) => {
  const navigate = useNavigate();
  /**
   * API CALLS
   */
  const [getAllNearCrew, { data: apiData, isLoading, isFetching }] =
    useLazyGetAllNearCrewQuery();

  const data = apiData?.data;

  /**
   * USESTATES
   */
  // const [searchValues, setSearchValues] = useState("");
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);

  /**
   * FUNCTIONS
   */
  const nearByResult = useCallback(
    async (values) => {
      const value = {
        page: page,
        // searchBy: values,
      };
      try {
        const res = await getAllNearCrew(value).unwrap();
        setItems((prevItems) =>
          page === 1 ? res?.data?.docs : [...prevItems, ...res?.data?.docs]
        );
      } catch (error) {
        toast.error(error?.data?.message);
      }
    },
    [getAllNearCrew, page]
  );

  // const debouncedHandleSearch = useCallback(
  //   _debounce((inputValue) => nearByResult(inputValue), 1000),
  //   [nearByResult]
  // );

  // const handleChange = (e) => {
  //   const inputValue = e.target.value;
  //   setSearchValues(inputValue);
  //   setPage(1);
  //   debouncedHandleSearch(inputValue);
  // };

  const loadMore = useCallback(() => {
    if (!isFetching && data?.nextPage) {
      setPage(data?.nextPage);
    }
  }, [isFetching, data?.nextPage]);

  /**
   * USEEFFECT
   */
  useEffect(() => {
    nearByResult();
  }, [page]);

  return (
    <div>
      {/* search */}
      {/* <div className="full-search-crew">
        <div className="search-form">
          <div className="search-fld">
            <input
              value={searchValues}
              type="search"
              className="form-control"
              placeholder="Search for Carvonix communities & crews"
              onChange={handleChange}
            />
            <button>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  d="M12.0094 12.5102L16.666 17.1667"
                  stroke="#F5F5F5"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.33398 13.8333C11.0954 13.8333 13.334 11.5947 13.334 8.83325C13.334 6.07183 11.0954 3.83325 8.33398 3.83325C5.57256 3.83325 3.33398 6.07183 3.33398 8.83325C3.33398 11.5947 5.57256 13.8333 8.33398 13.8333Z"
                  stroke="#F5F5F5"
                  strokeWidth="1.5"
                />
              </svg>
            </button>
          </div>
        </div>
      </div> */}
      {/* {show location} */}
      {/* <div className="view-all-heading">
        <h2>
          Near you
          <span className="location" style={{ fontSize: "17px" }}>
            <LocationIcon
              style={{
                stroke: "#ffffff",
                width: "16px",
                height: "16px",
              }}
            />{" "}
            {name}
          </span>
        </h2>
      </div> */}
      <div className="crew-header">
        <div className="crew-header-items">
          <div className="back-button">
            <BackArrow className="pointer" onClick={() => navigate(-1)} />
            <h3>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="10" fill="#E56424" />
                <path
                  d="M9.9998 4C7.68099 4 5.7998 5.728 5.7998 7.85641C5.7998 10.8566 9.9998 16 9.9998 16C9.9998 16 14.1998 10.8566 14.1998 7.85682C14.1998 5.728 12.3186 4 9.9998 4ZM9.9998 9.50769C9.72906 9.50769 9.4644 9.42829 9.23929 9.27953C9.01418 9.13077 8.83872 8.91932 8.73512 8.67194C8.63151 8.42456 8.6044 8.15234 8.65722 7.88972C8.71004 7.6271 8.84041 7.38587 9.03185 7.19653C9.2233 7.00719 9.46721 6.87825 9.73275 6.82601C9.99829 6.77378 10.2735 6.80059 10.5237 6.90306C10.7738 7.00553 10.9876 7.17905 11.138 7.40169C11.2884 7.62433 11.3687 7.88608 11.3687 8.15385C11.3687 8.51291 11.2245 8.85727 10.9678 9.11116C10.711 9.36506 10.3629 9.50769 9.9998 9.50769Z"
                  stroke="#F5F5F5"
                />
              </svg>
              Local To You
            </h3>
          </div>{" "}
          {/* <div className="pt-2"> 
            <h1> Near you</h1>
          </div> */}
        </div>
      </div>
      {/* {show result of API} */}
      {isLoading ? (
        isLoading && (isMobile || isTablet) ? (
          <SpinnerLoader />
        ) : (
          [1, 2, 3].map((_, index) => <NearYouRecomendedSkeleton key={index} />)
        )
      ) : (
        <>
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={items?.length}
            next={loadMore}
            hasMore={!!data?.nextPage}
            loader={isFetching && <SpinnerLoader />}
            // endMessage={<p style={{ textAlign: "center" }}>No more results</p>}
          >
            <div className="crew-garage-row" style={{ borderBottom: "unset" }}>
              {items?.map((item) => (
                <Link
                  to={`/crew-post/${item?._id}`}
                  style={{ color: "#FFF" }}
                  key={item?._id}
                >
                  <div
                    className="garage-block"
                    style={{ position: "relative" }}
                  >
                    <img
                      src={
                        isValidUrl(item?.bannerImage)
                          ? item?.bannerImage
                          : DefaultImg
                      }
                      alt={`${item?.make}-${item?.model}-${item?.year?.value}`}
                    />
                    <div className="info">
                      <div className="model">
                        {sliceStringAtWord(item?.name, 10)}
                      </div>
                      <div className="tags">
                        {item?.hashtags?.map((hashtag, index) => {
                          if (index > 2) return null;
                          return (
                            <>
                              <p>{hashtag}</p>
                              {index !== item?.hashtags?.length - 1 &&
                                index < 2 && <p className="px-1">•</p>}
                            </>
                          );
                        })}
                      </div>
                      {/* <div className="name">MeetUp</div> */}
                    </div>
                    {/* <div
                      className="group-2"
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "28px",
                        display: "flex",
                        flexDirection: "row-reverse",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        className="text-wrapper-3"
                        style={{ fontSize: "14px", fontWeight: 400 }}
                      >
                        {item?.memberCount} members
                      </div>
                      <MultipleUsers
                        crewMembers={item?.crewMembersData}
                        className="multiple-users-instance"
                        property1="users-sm"
                      />
                    </div> */}
                  </div>
                </Link>
              ))}
            </div>
          </InfiniteScroll>
        </>
      )}
    </div>
  );
};

export default AllNearCrews;
