import React from 'react'
import ContentLoader from 'react-content-loader'

const MessengerSkeleton = props => {
  const { width = '100%', height = 100 } = props;

  return (
    <ContentLoader
      height={height}
      width={width}  
      // viewBox={`0 0 400 80`} 
      backgroundColor="#ababab"
      foregroundColor="#fafafa"
      speed={2}
      {...props}
    >
      <circle cx="27" cy="27" r="18" />
      <rect x="53" y="14" rx="3" ry="3" width="60%" height="13" /> 
      {/* <rect x="53" y="30" rx="3" ry="3" width="10" height="10" /> */}
      <rect x="67" y="30" rx="3" ry="3" width="20%" height="10" /> 
      <circle cx="90%" cy="27" r="8" /> 
      <rect x="0" y="53" rx="0" ry="0" width="100%" height="1" /> 
    </ContentLoader>
  )
}

export default MessengerSkeleton;
