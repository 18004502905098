const specsConstantKeys = [
  "engine-horsepower-hide",
  "engine-cylinder-hide",
  "engine-cam_type-hide",
  "engine-compressor_type-hide",
  "engine-torque",
  "drive_train-transmission",
  "drive_train-drive_type",
  "overview-body_style",
  "weight_and_demensions-weight",
  "weight_and_demensions-overall_height",
  "weight_and_demensions-overall_length",
  "overview-exterior_color",
  "overview-doors",
];

const generateSpecsFromKeys = (data, hideTokens) => {
  if (!data) {
    return [];
  }

  const generatedObjects = [];
  specsConstantKeys.forEach((key) => {
    const includesHide = key?.indexOf("hide");
    if (hideTokens && includesHide > 0) {
      return;
    }
    const [objectName, propertyName] = key?.split("-");
    if (!propertyName || !objectName) {
      return;
    }
    const specObj = {
      key: propertyName,
      value: data[objectName] && data[objectName][propertyName],
      tokenName: objectName,
    };
    // Adding unit value for torque
    if (propertyName === "torque") {
      specObj.unit = "lb-ft";
    }
    // Adding unit value for weight
    if (propertyName === "weight") {
      specObj.unit = "lbs";
    }
    // Special case for "overall_height"
    if (
      propertyName === "overall_height" ||
      propertyName === "overall_length"
    ) {
      specObj.unit = "height"; // Set empty string as the value
    }
    // Check if stock value exists
    if (data[objectName]?.stock_values) {
      specObj.stockValue = data[objectName]?.stock_values[propertyName];
      const isUpdatedKey = `isUpdated_${propertyName}`;
      const greenDot = `greenDot_${propertyName}`;
      specObj[isUpdatedKey] =
        data[objectName]?.stock_values[`isUpdated_${propertyName}`];
      specObj[greenDot] =
        data[objectName]?.stock_values[`greenDot_${propertyName}`];
    }
    generatedObjects?.push(specObj);
  });
  transformKey(generatedObjects);
  return generatedObjects;
};

function transformKey(data) {
  if (data && typeof data === "string") {
    return data
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }
}
export { generateSpecsFromKeys, transformKey };
