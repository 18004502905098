import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "../baseQuery";

export const chatApi = createApi({
  reducerPath: "chatApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    listUserRoom: builder.query({
      query: (values) => ({
        url: `/chat/user-room/list`,
        method: "GET",
        params: {
          page: values?.page,
          username: values?.searchBy,
          sortBy: "createdAt:desc",
          limit: 10,
        },
      }),
    }),
    listOnlineUser: builder.query({
      query: () => ({
        url: `/users/follow/list`,
        method: "GET",
      }),
    }),
    chatListing: builder.query({
      query: (value) => ({
        url: `/chat/${value?.room_Id}`,
        method: "GET",
        params: {
          sortBy: "createdAt:desc",
          limit: 10,
          page: value?.page || 1,
        },
      }),
    }),
    updateMessage: builder.mutation({
      query: (value) => ({
        url: `/chat/update`,
        method: "POST",
        body: value,
      }),
    }),
    delSingleMsg: builder.query({
      query: (msgId) => ({
        url: `/chat/${msgId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyListUserRoomQuery,
  useLazyChatListingQuery,
  useListOnlineUserQuery,
  useUpdateMessageMutation,
  useLazyDelSingleMsgQuery,
} = chatApi;
