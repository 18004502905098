import React, { useEffect, useState } from "react";
import LandingHeader from "@components/layouts/LandingHeader";
import "./Terms.scss";
import LandingFooter from "@components/layouts/LandingFooter";
import { Link } from "react-router-dom";
const TermsCondition = () => {
  return (
    <>
      <LandingHeader />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* <div className="term-use">
              <h1>Terms of Use</h1>
              <h3>These terms of use are effective as of November 2024.</h3>
              <p>
                These Terms of Use (the “Terms”) are a legal agreement and cover
                all of the websites of Carvonix, LLC (“Carvonix”), and any
                associated content, including web or desktop applications,
                mobile applications (“Mobile Apps”), widgets, APIs, email, and
                other online products and services (the “Services”), as defined
                below. Please read this statement carefully before using
                proceeding to access any of the Carvonix Services. By visiting
                the Carvonix websites or downloading, installing or using any
                Carvonix application software or services, or other materials or
                services made available on, from, or through the Services, you
                signify your agreement to these Terms and Carvonix’s Privacy
                Policy, found here (carvonix.com/privacypolicy) and incorporated
                herein by reference. If you do not agree to any of these Terms
                or the Carvonix Privacy Policy, you must not use any of the
                Services. The Services are provided, controlled, operated and
                administered by Carvonix from its offices within the United
                States of America. Carvonix makes no representation that the
                Services are appropriate or available for use at other locations
                outside of the United States and access to them from territories
                where their contents are illegal is prohibited. <br />
                Further, as outlined in this Agreement, UNLESS YOU OPT OUT OF
                ARBITRATION WITHIN 30 DAYS OF THE DATE YOU AGREE TO THIS
                AGREEMENT, YOU AGREE THAT ANY DISPUTE THAT CANNOT BE INFORMALLY
                RESOLVED BETWEEN US WILL BE RESOLVED BY BINDING, INDIVIDUAL
                ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS
                ACTION LAWSUIT OR CLASS-WIDE ARBITRATION. If you do not agree to
                these provisions, you may not use the Services.
              </p>
            </div> */}
            <div className="list-terms">
              <h1 className="my-3">
                <strong>Terms of Use</strong>
              </h1>
              <h3>
                <span>
                  <span size="2">
                    These terms of use are effective as of November 2024.
                  </span>
                  <span size="2">oni</span>
                </span>
              </h3>
              <p>
                <span>
                  <span size="2">These Terms of Use (the &ldquo;</span>
                  <span size="2">
                    <strong>Terms</strong>
                  </span>
                  <span size="2">
                    &rdquo;) are a legal agreement and cover all of the websites
                    of&nbsp;
                  </span>
                </span>
                <span size="2">Carvonix</span>
                <span>
                  <span size="2">, LLC (&ldquo;</span>
                  <span size="2">
                    <strong>Carvonix</strong>
                  </span>
                  <span size="2">
                    &rdquo;), and any associated content, including web or
                    desktop applications, mobile applications (&ldquo;
                  </span>
                  <span size="2">
                    <strong>Mobile Apps</strong>
                  </span>
                  <span size="2">
                    &rdquo;), widgets, APIs, email, and other online products
                    and services (the &ldquo;
                  </span>
                  <span size="2">
                    <strong>Services</strong>
                  </span>
                  <span size="2">
                    &rdquo;), as defined below. Please read this statement
                    carefully before using proceeding to access any of the
                    Carvonix Services. By visiting the Carvonix websites or
                    downloading, installing or using any Carvonix application
                    software or services, or other materials or services made
                    available on, from, or through the Services, you signify
                    your agreement to these Terms and Carvonix&rsquo;s Privacy
                    Policy, found <Link to="/privacy-policy">here</Link> and incorporated herein
                    by reference. If you do not agree to any of these Terms or
                    the Carvonix Privacy Policy, you must not use any of the
                    Services. The Services are provided, controlled, operated
                    and administered by Carvonix from its offices within the
                    United States of America. Carvonix makes no representation
                    that the Services are appropriate or available for use at
                    other locations outside of the United States and access to
                    them from territories where their contents are illegal is
                    prohibited.&nbsp;
                  </span>
                </span>
              </p>
              <p>
                <span size="2">
                  Further, as outlined in this Agreement, UNLESS YOU OPT OUT OF
                  ARBITRATION WITHIN 30 DAYS OF THE DATE YOU AGREE TO THIS
                  AGREEMENT, YOU AGREE THAT ANY DISPUTE THAT CANNOT BE
                  INFORMALLY RESOLVED BETWEEN US WILL BE RESOLVED BY BINDING,
                  INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE
                  IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION. If you do
                  not agree to these provisions, you may not use the
                  Services.&nbsp;
                </span>
              </p>
              <ol type="1">
                <li>
                  <h2>1. ACCESS TO THE SERVICES</h2>
                </li>
              </ol>
              <p>
                <span>
                  <span size="2">
                    When you use the Services, you represent and warrant that
                    you are at least 18 years of age and a resident of the
                    United States. If you are under 18 years of age or a
                    resident of any country other than the United States, you
                    must not use the Services.
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    Carvonix may, in its sole discretion, modify or revise these
                    Terms at any time. Unless Carvonix makes a change for legal
                    or administrative reasons, Carvonix will provide reasonable
                    advance notice before the updated Terms become effective.
                    You agree that Carvonix may notify you of the updated Terms
                    by posting them on the Services, and that your use of the
                    Services after the effective date of the updated Terms (or
                    engaging in such other conduct as Carvonix may reasonably
                    specify) constitutes your agreement to the updated Terms.
                    Therefore, you should review these Terms before using the
                    Services. The updated Terms will be effective as of the time
                    of posting, or such later date as may be specified in the
                    updated Terms, and will apply&nbsp;
                  </span>
                </span>
                to your use of the Services from that point forward.
              </p>
              <p>
                <span size="2">
                  The Services may include temporarily free services and
                  Carvonix can begin charging at any time for use of any or all
                  parts of the Services. Carvonix reserves the right to
                  discontinue any aspect of the Services at any time.&nbsp;
                </span>
              </p>
              <p>
                Carvonix reserves the right to reclaim usernames that become
                inactive or on behalf of businesses or individuals that hold
                legal claim or trademark on those usernames.
              </p>
              <p>
                These Terms apply to all users of the Services, including users
                who contribute User Submissions as defined below. If you are
                agreeing to be bound by this Agreement on behalf of your
                employer or other entity, you represent and warrant that you
                have full legal authority to bind your employer or such entity
                to this Agreement. If you do not have the requisite authority,
                you may not accept these Terms or use these Services for your
                employer or other entity.
              </p>
              <ol start="2">
                <li>
                  <h2>2. CARVONIX ACCOUNTS</h2>
                </li>
              </ol>
              <p>
                <span>
                  <span size="2">
                    You do not need an account in order to access some content
                    on the Services; however, in order to access some features
                    of the Services, you will have to create an account. You may
                    be able to create your account using your Facebook or Apple
                    account or other accounts. Your use of these third-party
                    services for this purpose is governed by the terms of use
                    and privacy policies of these third parties. Additionally,
                    you may never use another user&rsquo;s account without
                    permission. When creating your account, you must provide
                    accurate and complete information. Carvonix prohibits the
                  </span>
                </span>
                <span size="2">&nbsp;</span>
                <span>
                  <span size="2">
                    creation of, and you agree that you will not create, an
                    account for anyone other than yourself. You also represent
                    that all information you provide or provided to Carvonix
                    upon registration and at all other times will be true,
                    accurate, current and complete and you agree to update your
                    information as necessary to maintain its truth and accuracy.
                    You are solely responsible for the activity that occurs on
                    your account, and you must keep your account password
                    secure. It is your sole responsibility to protect your
                    password from unauthorized use. You must notify Carvonix
                    immediately of any breach of security or unauthorized use of
                    your account. You agree that you will not solicit, collect
                    or use the login credentials of other Carvonix users.
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    Although Carvonix will not be liable for your losses caused
                    by any unauthorized use of your account, you may be liable
                    for the losses of Carvonix or others due to such
                    unauthorized use as set forth in these Terms. Carvonix will
                    not be responsible for any unauthorized access to, or
                    alteration of, your transmissions or data or information
                    sent or received from your account, regardless of whether
                    the data is actually received by Carvonix.
                  </span>
                </span>
              </p>
              <ol start="3">
                <li>
                  <h2>3. PROPRIETARY RIGHTS</h2>
                </li>
              </ol>
              <p>
                <span>
                  <span size="2">
                    You acknowledge and agree that the Services and all
                    materials incorporated by Carvonix on the Services, other
                    than the User Submissions, including, without limitation,
                    all content, visual interfaces, interactive features,
                    information, graphics, design, compilation, computer code,
                    API&rsquo;s, products, software, services, and all other
                    elements of the Services (&ldquo;
                  </span>
                  <span size="2">
                    <strong>Material(s)</strong>
                  </span>
                  <span size="2">
                    &rdquo;) constitute the proprietary and valuable property of
                    Carvonix and are protected by United States and
                    international copyrights, patents, trade secrets or other
                    proprietary rights and laws (&ldquo;
                  </span>
                  <span size="2">
                    <strong>IP Rights</strong>
                  </span>
                  <span size="2">
                    &rdquo;). Any use of the Services other than as specifically
                    authorized herein is strictly prohibited. Any rights not
                    expressly granted herein are reserved by Carvonix.
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    Some of the characters, logos or other images incorporated
                    by Carvonix on Carvonix websites are also protected as
                    registered or unregistered trademarks, trade names and/or
                    service marks owned by Carvonix or others (&ldquo;
                  </span>
                  <span size="2">
                    <strong>Trademarks</strong>
                  </span>
                  <span size="2">
                    &rdquo;). You agree not to use any Carvonix Trademarks
                    without Carvonix&rsquo;s written consent. Other trademarks,
                    service marks, trade names, logos and other branding used
                    and displayed via the Services (&ldquo;
                  </span>
                  <span size="2">
                    <strong>Third Party Mark(s)</strong>
                  </span>
                  <span size="2">
                    &rdquo;) may be trademarks or service marks of their
                    respective owners who may or may not endorse or be
                    affiliated with or connected to Carvonix. Except as
                    expressly provided in these Terms or in terms provided by
                    the owner of a Third Party Mark, nothing in these Terms or
                    the Services should be construed as granting, by
                    implication, estoppel, or otherwise, any license or right to
                    use any of Carvonix Trademarks displayed on the Services,
                    without Carvonix&rsquo;s prior written permission in each
                    instance. All goodwill generated from the use of Carvonix
                    Trademarks will inure to the exclusive benefit of Carvonix.
                  </span>
                </span>
              </p>
              <ol start="4">
                <li>
                  <h2>4. YOUR USE OF MATERIAL</h2>
                </li>
              </ol>
              <p>
                <span>
                  <span size="2">
                    You&rsquo;re right to make use of Carvonix Services and any
                    Material or other content appearing on it is subject to your
                    compliance with these Terms. Material is provided to you AS
                    IS. Modification or use of the Material or any other content
                    on Carvonix websites for any purpose not permitted by these
                    Terms may be a violation of the IP Rights and is prohibited.
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    You may access and display Material and all other content
                    displayed on Carvonix websites for non-commercial, personal,
                    entertainment use on a single computer or device only. The
                    Material and all other content on Carvonix websites may not
                    otherwise be copied, reproduced, republished, uploaded,
                    posted, transmitted, distributed or used in any way unless
                    specifically authorized by Carvonix. Any authorization to
                    copy Material granted by Carvonix in any part of the
                    Services for any reason is restricted to making a single
                    copy for non-commercial, personal, entertainment use on a
                    single computer or device only, and is subject to your
                    reproducing and keeping intact, and not altering, removing,
                    modifying or suppressing, all copyright and other
                    proprietary notices. Using any Material on any other web
                    site or networked computer environment is prohibited. Also,
                    decompiling, reverse engineering, disassembling, or
                    otherwise reducing the code used in any software on Carvonix
                    websites into a readable form in order to examine the
                    construction of such software and/or to copy or create other
                    products based (in whole or in&nbsp;part) on such software,
                    is prohibited.
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    You understand that when using the Services, you will be
                    exposed to Material from a variety of sources, and that
                    Carvonix is not responsible for the accuracy, timeliness,
                    usefulness, or IP Rights of or relating to such Material.
                    You further understand and acknowledge that you may be
                    exposed to Material that is inaccurate, offensive, indecent,
                    or objectionable, and you agree to waive, and hereby do
                    waive, any legal or equitable rights or remedies you have or
                    may have against Carvonix with respect thereto, and, to the
                    extent permitted by applicable law, agree to indemnify and
                    hold harmless Carvonix, its owners, operators, affiliates,
                    licensors, and licensees to the fullest extent allowed by
                    law regarding all matters related to your use of the
                    Services.
                  </span>
                </span>
              </p>
              <ol start="5">
                <li>
                  <h2>5. YOUR USER SUBMISSIONS AND CONDUCT</h2>
                </li>
              </ol>
              <p>
                <span>
                  <span size="2">
                    A. You may submit picture or video content (&ldquo;
                  </span>
                  <span size="2">
                    <strong>User Pictures</strong>
                  </span>
                  <span size="2">
                    &rdquo;) and textual content, including but not limited to,
                    comments (&ldquo;
                  </span>
                  <span size="2">
                    <strong>User Comments</strong>
                  </span>
                  <span size="2">
                    &rdquo;) to the Services, via photo upload function or other
                    methods that may be made available in the future. User
                    Pictures and User Comments are collectively referred to as
                    &ldquo;
                  </span>
                  <span size="2">
                    <strong>User Submissions</strong>
                  </span>
                  <span size="2">
                    &rdquo;. You understand that whether or not such User
                    Submissions are published, Carvonix, does not guarantee, and
                    disclaims, any confidentiality with respect to any User
                    Submissions. Carvonix has the right to edit and/or alter any
                    User Submissions. Carvonix reserves the right not to display
                    or use the User Submissions at all and/or to use as much or
                    as little of the User Submissions as it chooses. Some of the
                    Services are supported by advertising revenue and may
                    display advertisements and promotions, and you hereby agree
                    that Carvonix may place such advertising and promotions on
                    the Services or on, about, or in conjunction with your User
                    Submissions. The manner, mode and extent of such advertising
                    and promotions are subject to change without specific notice
                    to you.
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    B. You shall be solely responsible for your own User
                    Submissions and the consequences of posting or publishing
                    them. In connection with User Submissions, you affirm,
                    represent, and/or warrant that: you own or have all the
                    necessary licenses, rights, consents, and permissions to use
                    and publish your User Submissions and you license and
                    authorize Carvonix, and its affiliates, successors, agents
                    and assigns, employees, licensees, and designees to use all
                    IP Rights in and to any and all User Submissions to enable
                    publication and use of the User Submissions in the manner
                    contemplated by the Services and these Terms.
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    C. For clarity, you retain all of your ownership rights in
                    your User Submissions. However, by submitting User
                    Submissions to Carvonix, you hereby grant Carvonix, and its
                  </span>
                </span>
                <span size="2">&nbsp;</span>
                <span>
                  <span size="2">
                    agents, employees, affiliates, licensees, designees, assigns
                    and successors, a perpetual, irrevocable, worldwide,
                    non-exclusive, royalty-free, sub-licensable and transferable
                    license to use, reproduce, copy, transmit, distribute,
                    prepare derivative works of, cache, store, display, edit,
                    telecast, rerun, syndicate, print, perform and otherwise
                    exploit the User Submissions in connection with the
                    Services, including without limitation for advertising,
                    marketing, promoting and redistributing part or all of the
                    Services (and derivative works thereof) in any media formats
                    and through any media channels. You hereby waive any claims
                    you may have based on any usage of User Submissions or the
                    works derived therefrom including but not limited to claims
                    for invasion of privacy. You grant Carvonix and its
                    affiliates and sublicensees the right to use the name that
                    you submit in connection with such User Submissions,
                    including User Submissions applicable to &ldquo;crews&rdquo;
                    or groups within the Services, if they choose.&nbsp;
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    D. You acknowledge that Carvonix may establish general
                    practices and limits concerning use of the Services,
                    including without limitation the maximum period of time that
                    User Submissions or other data will be retained by the
                    Services and the maximum storage space that will be allotted
                    on Carvonix&rsquo;s servers on your behalf. You agree that
                    Carvonix has no responsibility or liability for the deletion
                    or failure to store or maintain any User Submissions or
                    other data maintained or transmitted by the Services. You
                    acknowledge that Carvonix reserves the right to terminate
                    accounts that are inactive for an extended period of time.
                    Also, Carvonix reserves the right to delete any User
                    Submissions for any reason, without prior notice, including,
                    without limitation, to provide additional capacity on the
                    Services. Deleted User Submissions may be stored by Carvonix
                    in its sole discretion in order to comply with certain legal
                    obligations and are not retrievable without a valid court
                    order. Consequently, Carvonix encourages you to maintain
                    your own backup of your User Submissions.
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    E. You agree not to post, email, or otherwise make available
                    User Submissions:
                  </span>
                </span>
              </p>
              <ol>
                <li>
                  <p>
                    <span>
                      1){" "}
                      <span size="2">
                        that are unlawful, harmful, pornographic, obscene,
                        threatening, abusive, harassing, defamatory, libelous,
                        invasive of another&rsquo;s privacy, or is harmful to
                        minors in any way.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      2){" "}
                      <span size="2">
                        that harass, degrade, intimidate or are hateful toward
                        an individual or group of individuals on the basis of
                        religion, gender, sexual orientation, race, ethnicity,
                        age, or disability.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      3){" "}
                      <span size="2">
                        that impersonate any person or entity, including, but
                        not limited to, a Carvonix employee, or falsely state or
                        otherwise misrepresent your affiliation with a person or
                        entity.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      4){" "}
                      <span size="2">
                        that include personal or identifying information about
                        another person without that person&rsquo;s explicit
                        consent.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      5){" "}
                      <span size="2">
                        that are false, deceptive, misleading, deceitful, or
                        misinformative.&nbsp;
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      6){" "}
                      <span size="2">
                        that infringe any IP Right or other proprietary rights
                        of any party.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      7){" "}
                      <span size="2">
                        that you do not have a right to make available under any
                        law, or under contractual or fiduciary relationships.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      8){" "}
                      <span size="2">
                        that constitute or contain any form of advertising or
                        solicitation, &ldquo;affiliate marketing,&rdquo;
                        &ldquo;link referral code,&rdquo; &ldquo;junk
                        mail,&rdquo; &ldquo;spam,&rdquo; &ldquo;chain
                        letters,&rdquo; &ldquo;pyramid schemes,&rdquo; or
                        unsolicited commercial advertisement.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      9){" "}
                      <span size="2">
                        that contain software viruses or any other computer
                        code, files or programs designed to interrupt, destroy
                        or limit the functionality of the Services or any
                        computer software or hardware or telecommunications
                        equipment.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      10){" "}
                      <span size="2">
                        that disrupt the normal flow of dialogue with an
                        excessive amount of User Submissions
                        (flooding&nbsp;attack) to the Services, or that
                        otherwise negatively affects other users&rsquo; ability
                        to use the Services. or
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      11){" "}
                      <span size="2">
                        that employ misleading email addresses, or forged
                        headers or otherwise manipulated identifiers in order to
                        disguise the origin of User Submissions transmitted
                        through the Services.
                      </span>
                    </span>
                  </p>
                </li>
              </ol>
              <p>
                <span>
                  <span size="2">
                    F. In connection with User Submissions, you further agree
                    that you will not submit anything that is subject to a third
                    party&rsquo;s copyright, protected by third party&rsquo;s
                    trade secret or otherwise subject to third party proprietary
                    rights, including privacy and publicity rights, unless you
                    are the owner of such rights or have permission from their
                    rightful owner to post the content and to grant Carvonix,
                    all of the license rights granted here in.
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    G. Carvonix does not control the User Submissions posted by
                    users or otherwise made available by other persons and does
                    not have any obligation to monitor such User Submissions for
                    any purpose. If at any time, Carvonix chooses, in its sole
                    discretion, to monitor the User Submissions, Carvonix
                    nonetheless assumes no responsibility for the User
                    Submissions, no obligation to modify or remove any
                    inappropriate User Submissions, and no responsibility for
                    the conduct of the user submitting any such User
                    Submissions. Carvonix, does not endorse any User Submission
                    or any opinion, recommendation, or advice expressed therein,
                    and Carvonix, expressly disclaims any and all liability in
                    connection with User Submissions. Carvonix does not permit
                    copyright infringing activities on its Services, and
                    Carvonix will remove all User Submissions if properly
                    notified that such User Submission infringes on
                    another&rsquo;s copyright pursuant to Carvonix&rsquo;s
                    Digital Millenium Copyright Act &ldquo;
                  </span>
                  <span size="2">
                    <strong>DMCA</strong>
                  </span>
                  <span size="2">
                    &rdquo; policy herein. Carvonix, reserves the right to
                    remove User Submissions without prior notice.
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    H. You agree not to alter or modify any part of the Services
                    or to obtain or attempt to access or otherwise obtain any
                    materials or information through hacking, password mining or
                    any other any means not intentionally made available or
                    provided for through the Services. You must not interfere or
                    disrupt the Services or servers or networks connected to the
                    Services, including by transmitting any worms, viruses,
                    spyware, malware or any other code of a destructive or
                    disruptive nature. You may not inject content or code or
                    otherwise alter or interfere with the way any Carvonix page
                    is rendered or displayed in a user&rsquo;s browser or
                    device. You agree not to use or launch any automated system,
                    including without limitation, &ldquo;robots,&rdquo;
                    &ldquo;spiders,&rdquo; or &ldquo;offline readers,&rdquo;
                    that accesses the Services in a manner that sends more
                    request messages to the Carvonix servers in a given period
                    of time than a human can reasonably produce in the same
                    period by using a conventional on-line web browser. You
                    agree not to collect or harvest any personally identifiable
                    information, including account names, from the Services, nor
                    to use any communication systems provided by the Services
                    (e.g., comments, email) for any commercial solicitation
                    purposes. You agree not to solicit, for
                  </span>
                </span>
                <span size="2">&nbsp;</span>
                <span>
                  <span size="2">
                    commercial purposes, any users of the Services with respect
                    to their User Submissions. In your use of the Services, you
                    will comply with all applicable laws, statutes and
                    regulations.
                  </span>
                </span>
              </p>
              <ol start="6">
                <li>
                  <h2>6. COMMENTS AND DISCUSSION GROUPS</h2>
                </li>
              </ol>
              <p>
                <span>
                  <span size="2">
                    In addition to the other requirements provided in these
                    Terms, the following guidelines must be adhered to by all
                    users posting and/or viewing comments in or on the Services:
                  </span>
                </span>
              </p>
              <ol>
                <li>
                  <p>
                    <span>
                      1){" "}
                      <span size="2">
                        If a comment is made using your identity or account it
                        will be deemed to have been posted by you.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      2){" "}
                      <span size="2">
                        Do not post abusive, obscene, threatening, harassing,
                        defamatory, libelous, offensive or sexually explicit
                        material.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      3){" "}
                      <span size="2">
                        Do not intentionally make false or misleading
                        statements.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      4){" "}
                      <span size="2">
                        Do not offer to sell or buy any product or service.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      5){" "}
                      <span size="2">
                        Do not post material that infringes copyright.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      6){" "}
                      <span size="2">
                        Do not post information that you know to be confidential
                        or sensitive or otherwise in breach of the law.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      7){" "}
                      <span size="2">
                        Keep all comments relevant and &lsquo;on topic.&rsquo;
                      </span>
                    </span>
                  </p>
                </li>
              </ol>
              <p>
                <span>
                  <span size="2">
                    Carvonix accepts no responsibility for information posted by
                    users in User Submissions.
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    If Carvonix receives notice that any posting is not in
                    keeping with these terms and conditions, Carvonix may remove
                    that posting and/ or any other related postings. Carvonix
                    may discontinue the Services, any of the features, and/or
                    support for the Services, including their use on certain
                    devices and platforms, at any time.
                  </span>
                </span>
              </p>
              <ol start="7">
                <li>
                  <h2>7. FEEDBACK</h2>
                </li>
              </ol>
              <p>
                <span>
                  <span size="2">
                    If you provide feedback, questions, comments, suggestions,
                    ideas, or other information to us regarding the Services, or
                    User Submissions (collectively, &ldquo;
                  </span>
                  <span size="2">
                    <strong>Feedback</strong>
                  </span>
                  <span size="2">
                    &rdquo;), you acknowledge that the Feedback is
                    non-confidential and you authorize us to use that Feedback
                    without restriction and without acknowledgment, payment or
                    other compensation to you. Accordingly, you hereby grant to
                    us perpetual and irrevocable license to use the Feedback for
                    any purpose, commercial or otherwise.
                  </span>
                </span>
              </p>
              <ol start="8">
                <li>
                  <h2>8. COPYRIGHT INFRINGEMENT CLAIMS / DMCA POLICY</h2>
                </li>
              </ol>
              <p>
                <span>
                  <span size="2">
                    The Digital Millennium Copyright Act of 1998 (the &ldquo;
                  </span>
                  <span size="2">
                    <strong>DMCA</strong>
                  </span>
                  <span size="2">
                    &rdquo;) provides recourse for copyright owners who believe
                    that material appearing on the Internet infringes their
                    rights under U.S. copyright law. If you believe in good
                    faith that materials available through the Services infringe
                    your copyright, you (or your agent) may send to us a written
                    notice by mail, e-mail or fax, requesting that we remove
                    such material or block access to it. If you believe in good
                    faith that someone has wrongly filed a notice of copyright
                    infringement against you, the DMCA permits you to send to us
                    a counter-notice. Notices and counter-notices must meet the
                    then-current statutory requirements imposed by the DMCA. See{" "}
                    <a href="http://www.copyright.gov/" target="_blank">
                      http://www.copyright.gov/
                    </a>{" "}
                    for details. Notices and counter-notices with respect to
                    copyright infringement claims must be sent in writing to the
                    Copyright Officer as follows: By mail to 1400 East 69th
                    Avenue, Denver, Colorado 80229 or by e-mail to{" "}
                    <a href="mailto:support@carvonix.com">
                      support@carvonix.com
                    </a>
                    .&nbsp;
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    WE CAUTION YOU THAT UNDER FEDERAL LAW, IF YOU KNOWINGLY
                    MISREPRESENT THAT ONLINE MATERIAL IS INFRINGING, YOU MAY BE
                    SUBJECT TO HEAVY CIVIL PENALTIES. THESE INCLUDE MONETARY
                    DAMAGES, COURT COSTS, AND ATTORNEYS FEES INCURRED BY US, BY
                    ANY COPYRIGHT OWNER, OR OUR RELYING UPON YOUR
                    MISREPRESENTATION. YOU MAY ALSO BE SUBJECT TO CRIMINAL
                    PROSECUTION FOR PERJURY.
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    We suggest that you consult your legal advisor before filing
                    a DMCA notice or counter-notice.
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    Carvonix reserves the right to reclaim usernames that become
                    inactive or on behalf of businesses or individuals that hold
                    legal claim or trademark on those usernames.
                  </span>
                </span>
              </p>
              <ol start="9">
                <li>
                  <h2>9. EXTERNAL LINK DISCLAIMER</h2>
                </li>
              </ol>
              <p>
                <span>
                  <span size="2">
                    Carvonix websites contain links to external, third party or
                    affiliate web sites . Some of these sites may not be
                    affiliated with Carvonix.
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    By providing links to other sites, Carvonix does not
                    necessarily guarantee, approve or endorse the information or
                    products available at these sites, nor does a link indicate
                    any association with or endorsement by the linked site to
                    the Carvonix website in question.
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    Carvonix does not operate or control and has no
                    responsibility for the information, content, products and/or
                    services found on any external sites. Nor does it represent
                    or endorse the accuracy or reliability of any information,
                    products and/or services provided on or through any external
                    sites, including, without limitation, warranties of any
                    kind, either express or implied, warranties of title or
                    non-infringement or implied warranties of merchantability or
                    fitness for a particular purpose.
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    Visiting any such external sites is at your own risk. We
                    encourage our users to be aware when they leave our site and
                    to read the terms of use and privacy statements of such
                    external websites. Visitors should direct any concerns
                    regarding any external link to its site administrator or
                    webmaster.
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    No authorization or permission is given for framing or
                    linking to any pages of a website or any of its User
                    Submissions, whether in whole or in part.
                  </span>
                </span>
              </p>
              <ol start="10">
                <li>
                  <h2>
                    10. SMS/MMS MOBILE MESSAGE MARKETING PROGRAM TERMS AND
                    CONDITIONS
                  </h2>
                </li>
              </ol>
              <p>
                <span size="2">Carvonix</span>
                <span>
                  <span size="2">&nbsp;</span>
                </span>
                <span size="2">
                  may offer a mobile messaging program (the &ldquo;
                </span>
                <span size="2">
                  <strong>Program</strong>
                </span>
                <span size="2">
                  &rdquo;), which you agree to use and participate in subject to
                  these Terms and the Privacy Policy. By opting in to or
                  participating in this Program, you accept and agree to these
                  terms and conditions, including, without limitation, consent
                  to receive marketing and promotional calls and messages from
                  Carvonix, Speed Society, and our partners and affiliates, at
                  any number you have provided, including by autodialer, and (2)
                  consent to resolve any disputes with us through binding,
                  individual-only arbitration, as detailed in the &ldquo;
                </span>
                <span size="2">
                  <strong>Dispute Resolution</strong>
                </span>
                <span size="2">
                  &rdquo; section below. Consent is not a condition of purchase
                  or use of the Services and if you wish to opt out of this
                  program you must opt-out using the instructions below.
                  Messages and data rates may apply and frequency varies.&nbsp;
                </span>
              </p>
              <ol type="a" className="my-2 program-desc">
                <li>
                  <span>
                    <strong>Program Description</strong>
                    <span>
                      :&nbsp;Without limiting the scope of the Program, users
                      that opt into the Program can expect to receive messages
                      concerning the marketing and sale of digital and physical
                      products, services, and events.
                    </span>
                  </span>
                </li>
                <li className="my-2">
                  <span>
                    <strong>
                      User Opt In<span>:</span>{" "}
                    </strong>
                    <span>
                      The Program allows users to receive marketing or
                      promotional SMS/MMS mobile messages by affirmatively
                      opting into the Program. Regardless of the opt-in method
                      you utilized to join the Program, you agree that this
                      Agreement applies to your participation in the Program. By
                      participating in the Program, you agree to receive
                      SMS/MMS, autodialed, or prerecorded marketing mobile
                      messages at the phone number associated with your opt-in
                      from us and our contractual partners and affiliates,
                      including Speed Vonix, LLC d/b/a &ldquo;Speed
                      Society&rdquo;, and you understand that consent is not
                      required to make any purchase from us. While you consent
                      to receive messages sent using an autodialer, the
                      foregoing shall not be interpreted to suggest or imply
                      that any or all of our mobile messages are sent using an
                      automatic telephone dialing system (&ldquo;ATDS&rdquo; or
                      &ldquo;autodialer&rdquo;). Message and data rates may
                      apply.
                    </span>
                  </span>
                </li>
                <li className="mb-2">
                  <span>
                    <strong> User Opt Out: </strong>
                    <span>
                      If you do not wish to continue participating in the
                      Program, you agree to reply STOP, END, CANCEL,
                      UNSUBSCRIBE, or QUIT to any mobile message from Us in
                      order to opt out of the Program or opt-out in the Mobile
                      App or web or desktop application. You may receive an
                      additional mobile message confirming your decision to opt
                      out. You understand and agree that the foregoing options
                      are the only reasonable methods of opting out. You also
                      understand and agree that any other method of opting out,
                      including, but not limited to, texting words other than
                      those set forth above or verbally requesting one of our
                      employees to remove you from our list, is not a reasonable
                      means of opting out.
                    </span>
                  </span>
                </li>
                <li>
                  <span>
                    <strong> Cost and Frequency</strong>
                    <span>
                      :&nbsp;Message and data rates may apply. The Program
                      involves recurring mobile messages, and additional mobile
                      messages may be sent periodically based on your
                      interaction with us.
                    </span>
                  </span>
                </li>
                <li className="my-2">
                  <span>
                    <strong>Support Instructions</strong>
                    <span>
                      :&nbsp;For support regarding the Program, text
                      &ldquo;HELP&rdquo; to the number you received messages
                      from or email us at&nbsp;support@carvonix.com. Please note
                      that the use of this email address is not an acceptable
                      method of opting out of the program. Opt-outs must be
                      submitted in accordance with the procedures set forth
                      above.
                    </span>
                  </span>
                </li>
                <li>
                  <span>
                    <strong>
                      Our Disclaimer of Warranty
                      <span>:</span>{" "}
                    </strong>
                    <span>
                      The Program is offered on an &ldquo;as-is&rdquo; basis and
                      may not be available in all areas at all times and may not
                      continue to work in the event of product, software,
                      coverage or other changes made by your wireless carrier.
                      We will not be liable for any delays or failures in the
                      receipt of any mobile messages connected with this
                      Program. Delivery of mobile messages is subject to
                      effective transmission from your wireless service
                      provider/network operator and is outside of Our control.
                      Carriers are not liable for delayed or undelivered mobile
                      messages.
                    </span>
                  </span>
                </li>
                <li className="mt-2">
                  <span>
                    <strong>Cell Phone Carrier Requirements</strong>
                    <span>
                      : You must have a wireless device of your own, capable of
                      two-way messaging, be using a participating wireless
                      carrier, and be a wireless service subscriber with text
                      messaging service. Not all cellular phone providers carry
                      the necessary service to participate. Check your phone
                      capabilities for specific text messaging instructions.
                    </span>
                  </span>
                </li>
              </ol>
              <p>
                <br />
                &nbsp;
              </p>
              <ol start="11">
                <li>
                  <h2>11. PROHIBITED CONTENT</h2>
                </li>
              </ol>
              <p>
                You acknowledge and agree to not send any prohibited content.
                Prohibited content includes:
              </p>
              <ul>
                <li>
                  <p>
                    Content that does not concern the automotive industry or not
                    reasonably likely to be of interest to automotive
                    enthusiasts;
                  </p>
                </li>
                <li>
                  <p>
                    Any fraudulent, libelous, defamatory, scandalous,
                    threatening, harassing, or stalking activity any other
                    activity deemed to be inappropriate by Carvonix in its sole
                    discretion;
                  </p>
                </li>
                <li>
                  <p>
                    Objectionable content, including profanity, obscenity,
                    lasciviousness, violence, bigotry, hatred, and
                    discrimination on the basis of race, sex, religion,
                    nationality, disability, sexual orientation, or age;
                  </p>
                </li>
                <li>
                  <p>
                    Pirated computer programs, viruses, worms, Trojan horses, or
                    other harmful code;
                  </p>
                </li>
                <li>
                  <p>
                    Any product, service, or promotion that is unlawful where
                    such product, service, or promotion thereof is received;
                  </p>
                </li>
                <li>
                  <p>
                    Any content that implicates and/or references medical,
                    genetic, biometric, or health information protected by any
                    federal, state, or local law concerning the regulation of
                    such health information, including but not limited to the
                    Health Insurance Portability and Accountability Act&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    Any other content that is prohibited by applicable law in
                    the jurisdiction from which the message is sent.
                  </p>
                </li>
              </ul>
              <ol start="12">
                <li>
                  <h2>12. DISPUTE RESOLUTION</h2>
                </li>
              </ol>
              <p>
                <span size="2">
                  PLEASE READ THE FOLLOWING SECTION CAREFULLY BECAUSE IT LIMITS
                  CERTAIN LEGAL RIGHTS, INCLUDING THE RIGHT TO A JURY TRIAL, THE
                  RIGHT TO PARTICIPATE IN ANY FORM OF CLASS, COLLECTIVE, OR
                  REPRESENTATIVE CLAIM, DISPUTE OR ACTION, AND THE RIGHT TO
                  CERTAIN REMEDIES AND FORMS OF RELIEF. Other rights that we or
                  you would have in court, such as an appellate review, also may
                  not be available in the arbitration process described within
                  this section.&nbsp;
                </span>
              </p>
              <p>
                <span size="2">
                  In the event that there is a dispute, claim, or controversy
                  between you and Us, or between you and Carvonix or any other
                  third-party service provider acting on Our behalf, arising out
                  of or relating to federal or state statutory claims, common
                  law claims, this Agreement, or the breach, termination,
                  enforcement, interpretation or validity thereof, including the
                  determination of the scope or applicability of this agreement
                  to arbitrate, such dispute, claim, or controversy will be, to
                  the fullest extent permitted by law, determined by arbitration
                  in
                </span>
                <span>
                  <span size="2">&nbsp;</span>
                </span>
                <span size="2">Denver, Colorado</span>
                <span>
                  <span size="2">&nbsp;</span>
                </span>
                before one arbitrator.
              </p>
              <p>
                <span size="2">
                  The parties agree to submit the dispute to binding arbitration
                  in accordance with the Commercial Arbitration Rules of the
                  American Arbitration Association (&ldquo;
                </span>
                <span size="2">
                  <strong>AAA</strong>
                </span>
                <span size="2">
                  &rdquo;) then in effect. Except as otherwise provided herein,
                  the arbitrator shall apply the substantive laws of the state
                  of Colorado, without regard to its conflict of laws rules.
                  Within ten (10) calendar days after the arbitration demand is
                  served upon a party, the parties must jointly select an
                  arbitrator with at least five years&rsquo; experience in that
                  capacity and who has knowledge of and experience with the
                  subject matter of the dispute. If the parties do not agree on
                  an arbitrator within ten (10) calendar days, a party may
                  petition the AAA to appoint an arbitrator, who must satisfy
                  the same experience requirement. In the event of a dispute,
                  the arbitrator shall decide the enforceability and
                  interpretation of this arbitration agreement in accordance
                  with the Federal Arbitration Act (&ldquo;
                </span>
                <span size="2">
                  <strong>FAA</strong>
                </span>
                <span size="2">
                  &rdquo;). The parties also agree that the AAA&rsquo;s rules
                  governing Emergency Measures of Protection shall apply in lieu
                  of seeking emergency injunctive relief from a court. The
                  decision of the arbitrator shall be final and binding, and no
                  party shall have rights of appeal except for those provided in
                  the FAA. Each party shall bear half of the fees paid for the
                  arbitrator and the administration of the arbitration; however,
                  the arbitrator shall have the power to order one party to pay
                  all or any portion of such fees as part of a well-reasoned
                  decision. The parties agree that the arbitrator shall have the
                  authority to award attorneys&rsquo; fees only to the extent
                  expressly authorized by statute or contract. The arbitrator
                  shall have no authority to award punitive damages and each
                  party hereby waives any right to seek or recover punitive
                  damages with respect to any dispute resolved by
                  arbitration.&nbsp;
                </span>
              </p>
              <p>
                The parties agree to arbitrate solely on an individual basis,
                and these Terms do not permit class arbitration or any claims
                brought as a plaintiff or class member in any class or
                representative arbitration proceeding. Except as may be required
                by law, neither a party nor the arbitrator may disclose the
                existence, content, or results of any arbitration without the
                prior written consent of both parties, unless to protect or
                pursue a legal right. If any term or provision of this Section
                is invalid, illegal, or unenforceable in any jurisdiction, such
                invalidity, illegality, or unenforceability shall not affect any
                other term or provision of this Section or invalidate or render
                unenforceable such term or provision in any other jurisdiction.
                If for any reason a dispute proceeds in court rather than in
                arbitration, the parties hereby waive any right to a jury trial.
                This arbitration provision shall survive any cancellation or
                termination of your agreement.
              </p>
              <p>
                You have the right to opt out of binding arbitration within 30
                days of the date you first accepted the terms of this Section by
                sending an email to support@carvonix.com. In order to be
                effective, the opt-out notice must include your full name and
                clearly indicate your intent to opt out of binding arbitration.
                By opting out of binding arbitration, you are agreeing to
                resolve disputes in accordance with the law of the State of
                Colorado and in the state and federal courts in and for the City
                and County of Denver, Colorado.
              </p>
              <ol start="13">
                <li>
                  <h2>13. CLASS ACTION WAIVER.</h2>
                </li>
              </ol>
              <p>
                ANY PROCEEDINGS TO RESOLVE OR LITIGATE ANY DISPUTE IN ANY FORUM
                WILL BE CONDUCTED SOLELY ON AN INDIVIDUAL BASIS. CLASS
                ARBITRATIONS, CLASS ACTIONS, PRIVATE ATTORNEY GENERAL ACTIONS,
                CONSOLIDATION OF YOUR DISPUTE WITH OTHER ARBITRATIONS, OR ANY
                OTHER PROCEEDING IN WHICH EITHER PARTY ACTS OR PROPOSES TO ACT
                IN A REPRESENTATIVE CAPACITY OR AS A PRIVATE ATTORNEY GENERAL
                ARE NOT PERMITTED AND ARE WAIVED BY YOU, AND AN ARBITRATOR WILL
                HAVE NO JURISDICTION TO HEAR SUCH CLAIMS. IF A COURT OR
                ARBITRATOR FINDS THAT THE CLASS ACTION WAIVER IN THIS SECTION IS
                UNENFORCEABLE AS TO ALL OR SOME PARTS OF A DISPUTE, THEN THE
                CLASS ACTION WAIVER WILL NOT APPLY TO THOSE PARTS. INSTEAD,
                THOSE PARTS WILL BE SEVERED AND PROCEED IN A COURT OF LAW, WITH
                THE REMAINING PARTS PROCEEDING IN ARBITRATION. IF ANY OTHER
                PROVISION OF THIS DISPUTE RESOLUTION SECTION IS FOUND TO BE
                ILLEGAL OR UNENFORCEABLE, THAT PROVISION WILL BE SEVERED WITH
                THE REMAINDER OF THIS SECTION REMAINING IN FULL FORCE AND
                EFFECT.
              </p>
              <ol start="14">
                <li>
                  <h2>14. MISCELLANEOUS</h2>
                </li>
              </ol>
              <p>
                You warrant and represent to Us that you have all necessary
                rights, power, and authority to agree to these Terms and perform
                your obligations hereunder, and nothing contained in this
                Agreement or in the performance of such obligations will place
                you in breach of any other contract or obligation. The failure
                of either party to exercise in any respect any right provided
                for herein will not be deemed a waiver of any further rights
                hereunder. If any provision of this Agreement is found to be
                unenforceable or invalid, that provision will be limited or
                eliminated to the minimum extent necessary so that this
                Agreement will otherwise remain in full force and effect and
                enforceable. Any new features, changes, updates or improvements
                of the Services shall be subject to this Agreement unless
                explicitly stated otherwise in writing. We reserve the right to
                change this Agreement from time to time. Any updates to this
                Agreement shall be communicated to you. You acknowledge your
                responsibility to review this Agreement from time to time and to
                be aware of any such changes. By continuing to use the Services
                after any such changes, you accept this Agreement, as modified.
              </p>
              <ol start="15">
                <li>
                  <h2>15. ACCOUNT SUSPENSION AND TERMINATION POLICY</h2>
                </li>
              </ol>
              <p>
                <span>
                  <span size="2">
                    You may temporarily suspend your account at any time. In
                    addition, Carvonix may suspend access to the Services if
                    Carvonix determines you violated these Terms or
                    Carvonix&rsquo;s Privacy Policy. When an account is
                    suspended by you or by Carvonix, your profile and User
                    Submissions may still be visible to other users but your
                    login functionality may be disabled.&nbsp;
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    You may delete your account at any time. In addition,
                    Carvonix may terminate a user&rsquo;s account if Carvonix
                    determines you violated these Terms or Carvonix&rsquo;s
                    Privacy Policy. If a user profile is terminated, your
                    profile and User Submissions will be deleted from the
                    Services in accordance with the Carvonix data retention
                    policy or rendered anonymous. Upon termination, all licenses
                    and other rights granted to you in these Terms will
                    immediately cease. However, termination does not alter your
                    agreement to these Terms.&nbsp;
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    Carvonix reserves the right to decide whether User
                    Submissions violate these Terms for reasons such as, but not
                    limited to violations of law, or under contractual or
                    fiduciary relationships, fraud, pornography, obscenity,
                    illegal or offensive content, or because the submissions are
                    not of interest to the Carvonix automotive community as
                    determined in the sole discretion of Carvonix. Carvonix may
                    at any time, without prior notice and in its sole
                    discretion, remove such User Submissions and/or deactivate
                    or delete a user&rsquo;s account for submitting such
                    material in violation of these Terms may and/or bar any
                    further access to such files or the Services. Any suspected
                    fraudulent, abusive or illegal activity that may be grounds
                    for termination of your use of Services, may be referred to
                    appropriate law enforcement authorities.
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    In accordance with the DMCA and other applicable laws,
                    Carvonix has adopted a policy of terminating, at
                    Carvonix&rsquo;s sole discretion, accounts of users who are
                    deemed to be repeat infringers. Carvonix may also at its
                    sole discretion limit access
                  </span>
                </span>
                <span size="2">&nbsp;</span>
                <span>
                  <span size="2">
                    to the Services and/or terminate the accounts of any users
                    who infringe any IP Rights of others, whether or not there
                    is any repeat infringement.
                  </span>
                </span>
              </p>
              <ol start="16">
                <li>
                  <h2>16. WAIVER OF LIABILITY FOR IN-PERSON INTERACTIONS</h2>
                </li>
              </ol>
              <p>
                Meeting in person carries certain inherent risks. If you decide
                to meet with another user offline, it&rsquo;s important to take
                the same safety precautions you would in any real-world
                encounter. Carvonix accepts no liability for any in-person
                interactions of its users. When meeting someone for the first
                time, keep the following tips in mind:
              </p>
              <ul>
                <li>
                  <p>
                    Choose a public, well-populated location such as a cafe,
                    bank, or shopping center.
                  </p>
                </li>
                <li>
                  <p>
                    Avoid meeting in isolated areas or inviting strangers into
                    your home.
                  </p>
                </li>
                <li>
                  <p>
                    Inform a friend or family member about your plans, including
                    where you&rsquo;re going and who you&rsquo;re meeting.
                  </p>
                </li>
                <li>
                  <p>Bring your phone with you, if available.</p>
                </li>
                <li>
                  <p>If possible, consider bringing a friend along.</p>
                </li>
                <li>
                  <p>
                    Trust your instincts&mdash;if something feels off, it
                    probably is.
                  </p>
                </li>
                <li>
                  <p>
                    By choosing to meet another user in person, you acknowledge
                    and agree to the following:
                  </p>
                </li>
                <li>
                  <p>
                    Carvonix does not support or promote any in-person meetings
                    that are illegal or that encourage unlawful activities.
                  </p>
                </li>
                <li>
                  <p>
                    In-person meetings come with inherent risks, including, but
                    not limited to, potential exposure to illnesses like
                    COVID-19. While precautions and personal responsibility may
                    help reduce the risk, serious illness or even death remains
                    a possibility.
                  </p>
                </li>
              </ul>
              <p>
                You voluntarily assume all risks associated with any in-person
                meeting arranged through the Services by any person, including
                any injuries, harm, damages, or losses, even those caused by
                negligence or misconduct of any kind by the. If you have any
                concerns about safety, send a message to us via email at
                support@carvonix.com.
              </p>
              <ol start="17">
                <li>
                  <h2>17. BETA SERVICES</h2>
                </li>
              </ol>
              <p>
                &ldquo;
                <span size="2">
                  <strong>Beta Services</strong>
                </span>
                <span size="2">
                  &rdquo; means a Service, or a feature of the Services, that is
                  designated or presented to the user as alpha, beta,
                  experimental, pilot, limited release, in development,
                  developer preview, early access, non-production, evaluation,
                  such that it is provided prior to general commercial release
                  (and can have the designation &ldquo;
                </span>
                <span size="2">
                  <strong>Beta</strong>
                </span>
                &rdquo;). These may be a separate, stand-alone service or
                microservice which is accessible independent of the Services or
                integrated into core Services.
              </p>
              <p>
                By accepting these Terms or using the Beta Services, you
                understand and acknowledge that the Beta Services are being
                provided on an &ldquo;As Is&rdquo; or &ldquo;As Available&rdquo;
                basis. The Beta Services may contain bugs, errors, and other
                problems. Any consideration described such as rewards offered in
                connection with use of the Beta Services is not redeemable for
                any value. YOU ASSUME ALL RISKS AND ALL COSTS ASSOCIATED WITH
                YOUR USE OF THE BETA SERVICES, INCLUDING, WITHOUT LIMITATION,
                ANY INTERNET ACCESS FEES, BACK-UP EXPENSES, COSTS INCURRED FOR
                THE USE OF YOUR DEVICE AND PERIPHERALS, AND ANY DAMAGE TO ANY
                EQUIPMENT, SOFTWARE, INFORMATION OR DATA. In addition, we are
                not obligated to provide any maintenance, technical, or other
                support for the Beta Services.
              </p>
              <ol start="18">
                <li>
                  <h2>18. CARVONIX POINTS SYSTEM</h2>
                </li>
              </ol>
              <p>
                <span size="2">
                  Carvonix may offer points or &ldquo;entries&rdquo; in the
                  application on a promotional basis; these points and/or
                  entries have no value at this time but Carvonix reserves the
                  right to change the terms of this program at any time and
                  terms of any such program will be posted within the Services.
                  Carvonix is under no obligation to provide compensation of any
                  kind to any user for any reason.&nbsp;
                </span>
              </p>
              <ol start="19">
                <li>
                  <h2>19. DISCLAIMER OF WARRANTIES</h2>
                </li>
              </ol>
              <p>
                <span>
                  <span size="2">
                    Your use of the services is at your own risk. The services
                    and all the materials, information, software, facilities,
                    services and other content made available on or through the
                    services are provided “as is” and “as available” without
                    warranties of any kind, either express or implied, in
                    connection with the services and your use thereof. To the
                    fullest extent permissible pursuant to applicable law,
                    Carvonix disclaims all warranties, express or implied, in
                    connection with the services and your use thereof including,
                    but not limited to, implied warranties of merchantability,
                    fitness for a particular purpose, non-infringement, title,
                    custom, trade, quiet enjoyment, system integration and
                    freedom from computer virus. Carvonix does not warrant that
                    the functions contained in the services will be available,
                    uninterrupted or error-free, that defects will be corrected,
                    or that the services or the servers that make them available
                    are free of viruses or other harmful components. Carvonix
                    does not warrant or make any representations regarding the
                    use of the services or the results of the use of the
                    material, information, software, facilities, services or
                    other content made available on or through the services or
                    any sites linked to the services in terms of their
                    correctness, accuracy, reliability, or otherwise. Carvonix
                    makes no warranties that your use of the services or the
                    materials, information, software, facilities, service or
                    other content made available on or through the services will
                    not infringe the rights of others and Carvonix assumes no
                    liability or responsibility for errors or omissions in such
                    materials, information, software, facilities, service or
                    other content of Carvonix. If applicable law does not allow
                    the exclusion of some or all of the above implied warranties
                    to apply to you, the above exclusions will apply to you only
                    to the extent permitted by applicable law. Carvonix assumes
                    no liability or responsibility for any (i) errors, mistakes,
                    or inaccuracies of user submissions, (ii) personal injury or
                    property damage, of any nature whatsoever, resulting from
                    your access to and use of the services, (iii) any
                    unauthorized access to or use of our secure servers and/or
                    any and all personal information stored therein, (iv) any
                    interruption or cessation of transmission to or from the
                    services, (v) any bugs, viruses, trojan horses, or the like
                    which may be transmitted to or through our services by any
                    third party, (vi) any errors or omissions in any user
                    submissions or for any loss or damage of any kind incurred
                    as a result of the use of any user submissions posted,
                    emailed, transmitted, or otherwise made available via the
                    services, and/or (vii) any damage to any user’s computer,
                    mobile device, or other equipment or technology including,
                    without limitation, damage from any security breach or from
                    any virus, bugs, tampering, fraud, error, omission,
                    interruption, defect, delay in operation or transmission,
                    computer line or network failure or any other technical or
                    other malfunction.
                  </span>
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    The materials, information and opinions included and/or
                    expressed in or on bulletin boards, chat rooms, community
                    pages or other forums on Carvonix websites (&ldquo;
                  </span>
                  <span size="2">
                    <strong>FORUMS</strong>
                  </span>
                  <span size="2">
                    &rdquo;) are not necessarily those of Carvonix or its
                    affiliated or related entities or content providers.
                    Carvonix does not undertake to monitor or review forums, and
                    the content of forums is not the responsibility of Carvonix.
                    Carvonix may remove or modify any content without notice or
                    liability at any time in its sole discretion. Any use of the
                    forums will be at your own risk and will be subject to the
                    disclaimers and limitations on liability set out above.
                  </span>
                </span>
              </p>
              <ol start="20">
                <li>
                  <h2>20. LIMITATION OF LIABILITY</h2>
                </li>
              </ol>
              <p>
                {" "}
                <span>
                  {" "}
                  <span size="2">
                    {" "}
                    In no event shall Carvonix, its officers, directors,
                    members, employees, affiliates or agents, be liable for any
                    loss or damage (direct, indirect, punitive, actual,
                    consequential, incidental, special, exemplary or otherwise)
                    resulting from any use of, or inability to use, the services
                    or the material, information, software, facilities, services
                    or other content on the services, regardless of the basis
                    upon which liability is claimed (i.e., warranty, contract,
                    or tort), and even if Carvonix has been advised of the
                    possibility of such loss or damage. Without limitation, you
                    (and not Carvonix) assume the entire cost of all necessary
                    servicing, repair or correction in the event of any such
                    loss or damage arising. If applicable law does not allow all
                    or any part of the above limitation of liability to apply to
                    you, the limitations will apply to you only to the extent
                    permitted by applicable law.{" "}
                  </span>{" "}
                </span>{" "}
              </p>{" "}
              <p>
                {" "}
                <span>
                  {" "}
                  <span size="2">
                    {" "}
                    You specifically acknowledge that Carvonix shall not be
                    liable for user submissions, materials, or the defamatory,
                    offensive, or illegal conduct of any user or third party and
                    that the risk of harm or damage from the foregoing rests
                    entirely with you.{" "}
                  </span>{" "}
                </span>{" "}
              </p>{" "}
              <p>
                {" "}
                <span>
                  {" "}
                  <span size="2">
                    {" "}
                    You agree that in the event you incur any damages, losses or
                    injuries that arise out of Carvonix’s acts or omissions, the
                    damages, if any, caused to you are not irreparable or
                    sufficient to entitle you to an injunction preventing any
                    exploitation of any website, service, property, product or
                    other content owned or controlled by Carvonix, and you will
                    have no rights to enjoin or restrain the development,
                    production, distribution, advertising, exhibition or
                    exploitation of any web site, property, product, service, or
                    other content owned or controlled by Carvonix.{" "}
                  </span>{" "}
                </span>{" "}
              </p>
              <ol start="21">
                <li>
                  <h2>21. INDEMNITY</h2>
                </li>
              </ol>
              <p>
                <span>
                  <span size="2">
                    You acknowledge and agree that, to the maximum extent
                    permitted by law, the entire risk arising out of your access
                    to and use of the site, application, services and site
                    content remains with you. You agree to defend, indemnify,
                    and hold Carvonix, its officers, directors, members,
                    employees, affiliates, and agents, harmless from and against
                    any claims, liabilities, damages losses, and expenses,
                    including, without limitation, reasonable legal and
                    accounting fees, arising out of or in any way connected with
                    (i) your access to or use the Services, User Submissions, or
                    Material, (ii) your User Submissions, (iii) your violation
                    of these Terms or any laws, (iv) your violation of any third
                    party right, including without limitation any IP rights or
                    privacy right; or (v) any claim that your User Submissions
                    caused damage to a third party. This defense and
                    indemnification obligation will survive these Terms and your
                    use of the Services.
                  </span>
                </span>
              </p>
              <ol start="22">
                <li>
                  <h2>22. WHO YOU ARE CONTRACTING WITH, NOTICES</h2>
                </li>
              </ol>
              <p>
                You are contracting with Carvonix, LLC, a Colorado limited
                liability company whose address is:
              </p>
              <p>Carvonix, LLC</p>
              <p>
                <span size="2">Attn: Legal&nbsp;</span>
              </p>
              <p>1400 East 69th Avenue</p>
              <p>Denver, CO 80229</p>
              {/* <p>
                <br />
              </p> */}
              <p>
                <span size="2">
                  This is the street address that you must use for any notices
                  to us.&nbsp;
                </span>
              </p>
              {/* <p>
                <br />
                &nbsp;
              </p> */}
              <ol start="23">
                <li>
                  <h2>23. EXPORT CONTROL/ PROHIBITED REGIONS</h2>
                </li>
              </ol>
              <p>
                <span>
                  <span size="2">
                    You represent and warrant that You and any entity or person
                    that directly or indirectly controls You,&nbsp;or is under
                    common control with You, are not: (a) on any sanctions lists
                    in the United States, (b) doing business in any of the US
                    embargoed countries or regions, and (c) a military end user
                    as defined and scoped in 15 C.F.R &sect; 744. As used in
                    these Terms, &ldquo;control&rdquo; means that an entity or
                    person possesses, directly or indirectly, the power to
                    direct or cause the direction of the management policies of
                    the other entity, whether through ownership of voting
                    securities, an interest in registered capital, by contract,
                    or otherwise.
                  </span>
                </span>
              </p>
              <ol start="24">
                <li>
                  <h2>24. MOBILE DEVICE AND APP STORE TERMS.</h2>
                </li>
              </ol>
              <p>
                <span size="2">
                  If you access our Services on mobile devices or in mobile
                  application (&ldquo;
                </span>
                <span size="2">
                  <strong>Mobile App</strong>
                </span>
                &rdquo;), you understand that your mobile carrier&rsquo;s
                standard charges will apply. When you download our Mobile App,
                you will also be subject to any terms imposed by the
                &ldquo;store&rdquo; through which you obtain the Mobile App, if
                any (e.g. the Apple App Store or Google Play Store, or others).
              </p>
              <p>
                <span size="2">
                  Any Mobile App installed from the Apple App Store, Google Play
                  Store, or other app &ldquo;store&rdquo; must be installed on a
                  device you control or own, and must be used in accordance with
                  any applicable terms from the Apple App Store or other
                  store.&nbsp;
                </span>
              </p>
              <p>
                <span>
                  <span size="2">
                    To the extent you access the Services through a mobile
                    device, your wireless service carrier&rsquo;s standard
                    charges, data rates and other fees may apply. In addition,
                    downloading, installing, or using certain Services may be
                    prohibited or restricted by your carrier, and not all
                    Services may work with all carriers or devices. By using the
                    Services, you understand that certain information about your
                    usage of wireless services may be communicated to Carvonix.
                  </span>
                </span>
              </p>
              <p>
                The following terms are required by Apple and are applicable if
                you install our Mobile App from the Apple App Store:
              </p>
              <p>
                Your license to the Services is a non-transferable license to
                use the Mobile App on any products that you own or control and
                as permitted by the Usage Rules set forth in the Apple Media
                Services Terms and Conditions (for Apple products) except that
                the Mobile App may be accessed and used by other accounts
                associated with the purchaser via Family Sharing or volume
                purchasing.
              </p>
              <p>
                Further, as between Apple and Carvonix, Carvonix&mdash;and not
                Apple&mdash;is responsible for:
              </p>
              <ul>
                <li>
                  <p>The content in the Mobile App.</p>
                </li>
                <li>
                  <p>Maintenance or support with respect to the Mobile App.</p>
                </li>
                <li>
                  <p>
                    Any product warranties, whether express or implied, provided
                    that, in the event of any failure of the Mobile App to
                    conform to any applicable warranty, you may notify Apple,
                    and Apple will refund the purchase price for the Mobile App
                    (if any); and, to the maximum extent permitted by applicable
                    law, Apple will have no other warranty obligation whatsoever
                    with respect to the Mobile App, and any other claims,
                    losses, liabilities, damages, costs or expenses attributable
                    to any failure to conform to any warranty will be
                    Carvonix&rsquo;s sole responsibility.
                  </p>
                </li>
                <li>
                  <p>
                    Addressing any claims of the end-user or any third party
                    relating to the Mobile App or the end-user&rsquo;s
                    possession and/or use of that Mobile App, including, but not
                    limited to: (a) product liability claims; (b) any claim that
                    the Mobile App fails to conform to any applicable legal or
                    regulatory requirement; and (c) claims arising under
                    consumer protection or similar legislation.
                  </p>
                </li>
                <li>
                  <p>
                    The investigation, defense, settlement and discharge of any
                    third party claim that the Mobile App or your possession and
                    use of that Mobile App infringes a third party&rsquo;s
                    intellectual property rights.
                  </p>
                </li>
              </ul>
              <p>
                Further, if you install our Mobile App from the Apple App Store,
                you acknowledge that Apple, and Apple&rsquo;s subsidiaries, are
                third party beneficiaries of these Terms, and that, upon your
                acceptance of these Terms, Apple will have the right (and you
                will be deemed to have accepted the right) to enforce these
                Terms against you as a third party beneficiary there of.
              </p>
              <p>
                <span size="2">
                  In the event of any conflict between this Agreement and the
                  Google Play Developer Distribution Agreement, the Google Play
                  Developer Distribution Agreement will govern.&nbsp;
                </span>
              </p>
              <ol start="25">
                <li>
                  <h2>25. GENERAL</h2>
                </li>
              </ol>
              <p className="mb-3">
                <span>
                  <span size="2">
                    You agree that: (i) the Services shall be deemed solely
                    based in Colorado; and (ii) the Services shall be deemed a
                    passive website that do not give rise to find either
                    specific or general personal jurisdiction over Carvonix,
                    LLC, in jurisdictions other than Colorado. These Terms shall
                    be governed by the internal substantive laws of the State of
                    Colorado, without respect to its conflict of laws
                    principles. Any and all disputes, claims and causes of
                    action arising out of or connected with the Services shall
                    be resolved individually, without resort to any form of
                    class action, and exclusively by a court of competent
                    jurisdiction located in the City and County of Denver,
                    Colorado and the parties hereby consent to such venue and to
                    the jurisdiction of such courts over such proceeding and
                    themselves. These Terms, together with the Privacy Policy{" "}
                    <Link to="/privacy-policy">here</Link> the DMCA policy above and any other
                    legal notices published by Carvonix on the Services, shall
                    constitute the entire agreement between you and Carvonix
                    concerning the Services. If any provision of these Terms is
                    deemed invalid by a court of competent jurisdiction, the
                    invalidity of such provision shall not affect the validity
                    of the remaining provisions of these Terms, which shall
                    remain in full force and effect. No waiver of any term of
                    this these Terms shall be deemed a further or continuing
                    waiver of such term or any other term, and Carvonix&rsquo;s
                    failure to assert any right or provision under these Terms
                    shall not constitute a waiver of such right or provision.
                    These Terms, and any rights and licenses granted hereunder,
                    may not be transferred or assigned by you, but may be
                    assigned by Carvonix without restriction. YOU AND Carvonix
                    AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO
                    THE SERVICE MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE
                    CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS
                    PERMANENTLY BARRED.
                  </span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <LandingFooter />
    </>
  );
};

export default TermsCondition;
