import React, { useCallback, useEffect, useState } from "react";
import { ArrowDown, CarPart } from "@utils/defaultImages";
import { dateTimeFromNow } from "@utils/DateFormatter";
import PostsHeaderLoader from "@components/Skeleton/PostsHeaderLoader";
import { Link } from "react-router-dom";
import { useLazyGetModsByCategoriesIdQuery } from "@redux/services/userProfile/UserProfileService";

import InfiniteScroll from "react-infinite-scroll-component";

const SubCategories = ({
  categoriesValues = {},
  vehicleId,
  categories,
  activeIndex,
  page,
  setPage,
  typeBuild,
}) => {
  /**
   * USESTATES
   */
  const [showListCategories, setShowListCategories] = useState([]);
  /**
   * API CALLS
   */
  const [
    getModsByCategoriesId,
    { data: { data } = {}, isLoading, isFetching },
  ] = useLazyGetModsByCategoriesIdQuery();
  /**
   * FUNCTIONS
   */
  const loadMore = useCallback(() => {
    if (!isFetching && data?.nextPage) {
      setPage(data?.nextPage);
    }
  }, [isFetching, data?.nextPage]);

  const getSubCategoriesList = async () => {
    const body = {
      buildId: vehicleId,
      CategoryId: categoriesValues?._id || categories[0]?._id,
      page: page,
    };
    try {
      const response = await getModsByCategoriesId(body).unwrap();
      setShowListCategories((prev) =>
        page === 1 ? response?.data?.docs : [...prev, ...response?.data?.docs]
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getSubCategoriesList();
  }, [page, categoriesValues?._id]);

  return (
    <React.Fragment>
      {showListCategories?.length > 0 && (
        <div className="select-mods">
          {isLoading ? (
            Array.from({ length: 1 }).map((_, index) => (
              <PostsHeaderLoader key={index} />
            ))
          ) : (
            <>
              <div className="main-heading">
                <div className="icon">
                  <img
                    src={require("@assets/categories/" +
                      (categoriesValues?.name || categories[0]?.name) +
                      ".svg")}
                    alt="img78"
                  />
                </div>
                <div className="heading">
                  <h4>
                    {activeIndex === 0
                      ? categories[0]?.name
                      : categoriesValues?.name}{" "}
                    mods
                  </h4>
                </div>
              </div>

              <div className="wrap-categories" id="scrollableDiv">
                <InfiniteScroll
                  // style={{ overflow: "hidden" }}
                  dataLength={showListCategories?.length}
                  next={loadMore}
                  hasMore={!!data?.nextPage}
                  scrollableTarget="scrollableDiv"
                >
                  {showListCategories?.map((list) => (
                    <Link
                      className="inner-section"
                      key={list?._id}
                      to={`/component-page/${list?._id}`}
                    >
                      <div className="wrap-mods">
                        <div className="image-mods">
                          {list?.post?.media[0]?.path.includes("mp4") ? (
                            <video
                              src={list?.post?.media[0]?.path}
                              alt="EventVideo"
                            />
                          ) : list?.post == null ? (
                            <img src={CarPart} alt={list?.name} />
                          ) : (
                            <img
                              src={list?.post?.media[0]?.path}
                              alt={list?.name}
                            />
                          )}
                          <div className="engine">
                            <p
                              style={{
                                color: typeBuild ? "#387AFF" : "#E56424",
                              }}
                            >
                              {list?.name}
                            </p>
                            <h6>{list?.sub?.name}</h6>
                            <span>{dateTimeFromNow(list?.createdAt)}</span>
                          </div>
                        </div>
                      </div>
                      <div className="icon">
                        <ArrowDown />
                      </div>
                    </Link>
                  ))}
                </InfiniteScroll>
              </div>
            </>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default SubCategories;
