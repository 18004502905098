import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "../baseQuery";

export const exploreApi = createApi({
  reducerPath: "exploreApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["joinCrew", "followAccount"],
  endpoints: (builder) => ({
    exploreBuild: builder.query({
      query: (page) => ({
        url: `/search/build`,
        method: "GET",
        params: {
          limit: 10,
        },
      }),
    }),
    exploreCrew: builder.query({
      query: (page) => ({
        url: `/search/crew`,
        method: "GET",
        params: {
          limit: 10,
        },
      }),
      providesTags: ["joinCrew"],
    }),
    exploreProfile: builder.query({
      query: (page) => ({
        url: `/search/profile`,
        method: "GET",
        params: {
          limit: 10,
        },
      }),
      providesTags: ["followAccount"],
    }),
    explorePost: builder.query({
      query: (page) => ({
        url: `/search/post`,
        method: "GET",
        params: {
          limit: 10,
        },
      }),
    }),
    joinExploreCrew: builder.mutation({
      query: ({ crewId, value }) => ({
        url: `/crew/${crewId}/join`,
        method: "POST",
        body: value,
      }),
      invalidatesTags: ["joinCrew"],
    }),
    disJoinExploreCrew: builder.mutation({
      query: (crewId) => ({
        url: `/crew/${crewId}/disjoin`,
        method: "DELETE",
      }),
      invalidatesTags: ["joinCrew"],
    }),
    followProfileExplore: builder.mutation({
      query: (value) => ({
        url: `/users/follow/user`,
        method: "POST",
        body: value,
      }),
      invalidatesTags: ["followAccount"],
    }),
    removefollowProfileExplore: builder.mutation({
      query: (removeId) => ({
        url: `/users/remove-following/${removeId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["followAccount"],
    }),
    searchExploreBuild: builder.query({
      query: (value) => ({
        url: `/search/build`,
        method: "GET",
        params: {
          searchBy: value?.search,
          page: value.page,
          limit: 10,
        },
      }),
    }),
    SearchExploreCrew: builder.query({
      query: (value) => ({
        url: `/search/crew`,
        method: "GET",
        params: {
          searchBy: value?.search,
          page: value?.page,
          limit: 10,
        },
      }),
    }),
    SearchExploreProfile: builder.query({
      query: (value) => ({
        url: `/search/profile`,
        method: "GET",
        params: {
          searchBy: value?.search,
          page: value?.page,
          limit: value?.limit,
        },
      }),
    }),
    SearchExplorePosts: builder.query({
      query: (value) => ({
        url: `/search/post`,
        method: "GET",
        params: {
          searchBy: value?.search,
          page: value?.page,
          limit: value?.limit,
        },
      }),
    }),
    allSearchExplore: builder.query({
      query: (value) => ({
        url: `/search/all`,
        method: "GET",
        params: {
          limit: 10,
          searchBy: value?.search,
        },
      }),
    }),
    categoriesBasedOnSearch: builder.query({
      query: (value) => ({
        url: `/search/carvonix-explore`,
        method: "GET",
        params: {
          searchBy: value?.searchCategory || "",
          limit: 10,
          page: 1,
        },
      }),
    }),
  }),
});

export const {
  useExploreBuildQuery,
  useExploreCrewQuery,
  useExploreProfileQuery,
  useExplorePostQuery,
  useJoinExploreCrewMutation,
  useDisJoinExploreCrewMutation,
  useFollowProfileExploreMutation,
  useRemovefollowProfileExploreMutation,
  useLazySearchExploreBuildQuery,
  useLazySearchExploreCrewQuery,
  useLazySearchExploreProfileQuery,
  useLazySearchExplorePostsQuery,
  useLazyAllSearchExploreQuery,
  useLazyCategoriesBasedOnSearchQuery,
} = exploreApi;
