import React from 'react'
import CookieConsent, { Cookies } from "react-cookie-consent";

const CookiesWeb = () => {
  return (
    <>
     <CookieConsent
          location="bottom"
          buttonText="Accept all"
          cookieName="Carvonix"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#fff", fontSize: "13px", backgroundColor:"#008000" }}
          expires={365}
          declineCookieValue="No"
          enableDeclineButton
          declineButtonText="Decline"
          declineButtonStyle={{fontSize: "14px"}}
          cookieValue={true}
          cookieSecurity={true}
        >
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
    </>
  )
}

export default CookiesWeb