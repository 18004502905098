import React from "react";
import DefaultImgUser from "@assets/defaultUser.png";
import { isValidUrl } from "@utils/validUrl";
import { useSelector } from "react-redux";

const LikedImages = ({ reactUser, isLiked }) => {
  const userInfo = useSelector((state) => state?.auth?.loginUserData);
  const userAlreadyLiked = reactUser?.some(
    (item) => item?.userId === userInfo?.id
  );

  // Filter reactUser list based on isLiked flag
  const filteredReactUser = isLiked
    ? reactUser
    : reactUser?.filter((item) => item?.userId !== userInfo?.id);

  return (
    <>
      {filteredReactUser?.map((item, index) => (
        <div key={index} className="profile-link-image">
          <div className="car-image">
            <img
              src={
                isValidUrl(item?.profileImage)
                  ? item?.profileImage
                  : DefaultImgUser
              }
              alt={item?.username}
              className="liked-img"
              style={{ borderRadius: "22px" }}
            />
          </div>
        </div>
      ))}
      {isLiked && !userAlreadyLiked && (
        <div className="profile-link-image">
          <div className="car-image">
            <img
              src={
                isValidUrl(userInfo?.profileImage)
                  ? userInfo?.profileImage
                  : DefaultImgUser
              }
              alt={userInfo?.username}
              className="liked-img"
              style={{ borderRadius: "22px" }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default LikedImages;
