import React from "react";

const Tabs = ({ tabOpen, setTabOpen }) => {
  const tabs = [
    { id: 1, label: "Events" },
    { id: 2, label: "Forum" },
    { id: 3, label: "About" },
    // { id: 4, label: "Saved" }
  ];
  return (
    <ul class="nav nav-pills mb-3">
      {tabs?.map((item) => (
        <li class="nav-item" key={item?.id}>
          <button
            class={`nav-link ${item?.id === tabOpen ? "active" : ""}`}
            onClick={() => setTabOpen(item?.id)}
          >
            {item?.label}
          </button>
        </li>
      ))}
      {/* <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="pills-posts-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-posts"
          type="button"
          role="tab"
          aria-controls="pills-home"
          aria-selected="true"
        >
          Posts
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="pills-events-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-events"
          type="button"
          role="tab"
          aria-controls="pills-profile"
          aria-selected="false"
        >
          Events
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="pills-about-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-about"
          type="button"
          role="tab"
          aria-controls="pills-contact"
          aria-selected="false"
        >
          About
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="pills-saved-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-saved"
          type="button"
          role="tab"
          aria-controls="pills-contact"
          aria-selected="false"
        >
          Saved
        </button>
      </li> */}
    </ul>
  );
};

export default Tabs;
