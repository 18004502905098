import React, { useState } from "react";
import { BackArrow, ThreeDots } from "@utils/defaultImages";
import { useNavigate } from "react-router";
import NewMessage from "@assets/new.svg";
import NewMessageModal from "@components/Modal/NewMessageModal";
import { isValidUrl } from "@utils/validUrl";
import UserImg from "@assets/defaultUser.png";
export default function MessengerHeader({ messengerUser }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const closeModal = () => {
    setOpen(!open);
  };
  return (
    <>
      <div className="container p-0">
        <div className="messnger-header">
          <div className="messnger-header-items">
            <div className="back-button-mess" onClick={() => navigate(-1)}>
              <BackArrow style={{ cursor: "pointer" }} />
            </div>{" "}
            <div style={{ display: "flex", gap: "10px" }}>
              {messengerUser?.user && (
                <img
                  src={
                    isValidUrl(messengerUser?.user?.profileImage)
                      ? messengerUser?.user?.profileImage
                      : UserImg
                  }
                  width={32}
                  height={32}
                  style={{ borderRadius: "32px", objectFit: "cover" }}
                />
              )}
              <h1>
                {messengerUser?.user?.username
                  ? messengerUser?.user.username
                  : "Messenger"}
              </h1>
            </div>
            <div className="dott">
              {messengerUser?.user ? (
                <ThreeDots onClick={""} />
              ) : (
                <img
                  src={NewMessage}
                  alt="image"
                  // onClick={handleClick}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {open && <NewMessageModal open={open} closeModal={closeModal} />}
    </>
  );
}
